import {IonAvatar, IonBadge, IonButton, IonButtons, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRouterLink, IonRow, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar, useIonRouter, useIonToast} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {Pg_B_1_Store, push_pg_B_1, push_pg_B_1_emailverify, push_pg_B_1_sendemailverify} from "../store/Pg_B_Store";
//import {fetch_pg_1_2} from "../store/Pg_1_Store";

import {getPageData, getQuotes} from "../store/Selectors";
import {useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
import {useAppUtil, useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import {personCircle} from "ionicons/icons";
import CustomField from "../components/CustomField";
import {useParams} from "react-router";
import {useFormInput, validateForm} from "../_/useForms";
import {Action} from "../components/Action";
import {IconWave} from "../components/IconWave";

import styles from './Pg_A_0.module.scss';
import indexBy from 'underscore/modules/indexBy.js';
import {navToPg_1_1, navToPg_A_1} from "./Utils";
import styles_Pg_C_1 from "./Pg_C_1.module.scss";

export const useRegisterFields = () => {
    return [
        {
            id: "scodeEmail",
            nodisplay: true,
            label: "scodeEmail",
            required: false,
            value: null,
        },
        {
            id: "vcodeEmail",
            nodisplay: false,
            label: "vcodeEmail",
            required: false,
            input: {
                props: {
                    type: "email",
                    placeholder: "",
                },
                state: useFormInput("")
            }
        },
        {
            id: "p1",
            label: "電子郵件",
            required: true,
            input: {
                props: {
                    type: "email",
                    placeholder: "電子郵件",
                    autocomplete: "username",
                },
                state: useFormInput("")
            }
        },
        {
            id: "p2",
            label: "新密碼",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "新密碼",
                    autocomplete: "new-password"
                },
                state: useFormInput("")
            }
        },
        {
            id: "p2_2",
            label: "再一次輸入新密碼",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "再一次輸入新密碼",
                },
                state: useFormInput("")
            }
        },
    ];
}

const Pg_B_1 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_B_1";
    const pageId = "Pg_B_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    const params = useParams();
    const [areaCode, setAreaCode] = useState('');
    const fields = useRegisterFields();
    let keyedFields = indexBy(fields, 'id');

    const [errors, setErrors] = useState(false);
    const [emailVerifyStage, setEmailVerifyStage] = useState<number>(1);
    const [phoneVerifyStage, setPhoneVerifyStage] = useState<number>(1);

    //#region +Event

    //#region push register
    const push_register = async () => {
        const errors = validateForm(fields);
        setErrors(errors);

        if (errors.length > 1) {
            alert('欄位沒寫完成，請檢視')
        }

        if (!errors.length) {
            //  Submit your form here
            let keyedFields = indexBy(fields, 'id');
            //const username = fields && (fields.filter(e => e.id === 'email')[0] as any).input.state.value;
            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;

            //const scodeEmail = fields && (keyedFields.scodeEmail as any).value;
            //const vcodeEmail = fields && (keyedFields.vcodeEmail as any).value;
            //const scodePhone = fields && (keyedFields.scodePhone as any).value;
            //const vcodePhone = fields && (keyedFields.vcodePhone as any).value;
            const p2 = fields && (keyedFields.p2 as any).input.state.value;
            const p2_2 = fields && (keyedFields.p2_2 as any).input.state.value;
            if (p2 !== p2_2) {
                alert('前後輸入的密碼不一致');
                return;
            }


            let {email, scodeEmail, vcodeEmail} = Pg_B_1_Store.getRawState() as any;

            if (emailVerifyStage !== 4) {
                alert("請先完成Email驗證流程，再按下註冊按鈕");
                return;
            }
            const p1 = fields && (keyedFields.p1 as any).input.state.value;
            // if (p1 !== email) {
            //     alert("填寫的Email與驗證的Email不同，請重新填寫");
            //     return;
            // }

            try {
                if (await push_pg_B_1({
                    scodeEmail,
                    vcodeEmail,
                    p1,
                    p2,
                })) {
                    alert('密碼已修改，現在請輸入剛才的帳密登入');
                    navToPg_A_1(router);
                }
            } catch (err) {

            }
        }
    }

    const _push_Pg_B_1__SendEmailVerify = async () => {
        const p1 = fields && (keyedFields.p1 as any).input.state.value;
        setEmailVerifyStage(2);
        try {
            if (await push_pg_B_1_sendemailverify({
                p1: `${p1}`,
            })) {
                let {email, scodeEmail} = Pg_B_1_Store.getRawState() as any;
                setEmailVerifyStage(3);
                //navToPg_1_1();
            }
        } catch (err) {
            setEmailVerifyStage(1);
        }
    }

    const _push_Pg_B_1__EmailVerify = async () => {
        const p1 = fields && (keyedFields.p1 as any).input.state.value;
        let {email, scodeEmail} = Pg_B_1_Store.getRawState() as any;
        const vcodeEmail = fields && (keyedFields.vcodeEmail as any).input.state.value;

        if (!vcodeEmail) {
            alert('請填入驗證碼');
            return;
        }
        if (email !== p1) {
            alert('信箱已被修改，請重新驗證');
            setEmailVerifyStage(1);
            return;
        }
        //setPhoneVerifyStage(3);
        // setPhoneVerifyStage(2);
        if (await push_pg_B_1_emailverify({
            p1: email,
            scodeEmail: scodeEmail,
            vcodeEmail: vcodeEmail,
        })) {
            setEmailVerifyStage(4);
            //navToPg_1_1();
        }
    }
    //#endregion

    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} showLargeHeader={false} sideMenu={false} backButton={true}>
                {/*<PageHeader count={sideMenuOptions.length} pageName={pageName}/>*/}

                <IonGrid className="ion-padding ion-margin-top ion-padding-top">
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p1} errors={errors} disabled={emailVerifyStage !== 1}/>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                        <IonCol>
                            {emailVerifyStage === 1 &&
                                <>
                                    <IonButton fill={"solid"} onClick={() => _push_Pg_B_1__SendEmailVerify()} disabled={emailVerifyStage !== 1}>
                                        驗證電子郵件
                                    </IonButton>
                                </>
                            }
                            {emailVerifyStage === 2 &&
                                <>
                                    寄件中，請稍後...
                                </>
                            }
                            {emailVerifyStage === 3 &&
                                <>
                                    <CustomField alternateLabel={<>
                                        <IonLabel>
                                            請檢查郵件，並填入驗證碼
                                        </IonLabel>
                                    </>} alternateRightZone={<>
                                        <IonButton fill={"solid"} onClick={() => _push_Pg_B_1__EmailVerify()} className={styles_Pg_C_1.sideRight}>
                                            驗證
                                        </IonButton>
                                    </>
                                    } field={keyedFields.vcodeEmail} errors={errors} className={styles_Pg_C_1.highlight} inputClassName={styles_Pg_C_1.hightlightColor}/>
                                </>
                            }
                            {emailVerifyStage === 4 &&
                                <>
                                    驗證已完成，請設定新密碼！
                                </>
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p2} errors={errors}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p2_2} errors={errors}/>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top ion-padding-top">
                        <IonCol size="12">
                            <IonButton className="custom-button" expand="block" onClick={() => push_register()}>確認</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </CustomPage>

            {/*<IonFooter>*/}
            {/*    <IonGrid className="ion-no-margin ion-no-padding">*/}
            {/*        <Action message="Don't have an account?" text="Sign up" link="/C_1"/>*/}
            {/*        <IconWave/>*/}
            {/*    </IonGrid>*/}
            {/*</IonFooter>*/}
        </IonPage>
    );
};

export default Pg_B_1;
