import {IonBadge, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonNote, IonRouterLink, IonRow} from "@ionic/react";
import styles from "./TokenItem.module.scss";
import {star} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import styles_1_5 from "../pages/Pg_1_5.module.scss";
import moment from "moment";

export const TokenDetailItem = ({Item_Token, fromHome = false}) => {
    let Item_合約: any = Item_Token?._合約;
    if (!Item_合約) {
        return <>(ERROR:Item_合約)</>;
    }

    return (
        <IonRow>
            <IonList className={styles_1_5.list}>
                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>品牌</IonLabel></IonCol>
                            <IonCol>{Item_合約.Name}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>

                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>系列</IonLabel></IonCol>
                            <IonCol>{Item_Token.Series}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>

                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>名稱</IonLabel></IonCol>
                            <IonCol>{Item_Token.名稱}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>

                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>發行數量</IonLabel></IonCol>
                            <IonCol>{Item_Token.數量}</IonCol>
                        </IonRow>
                    </IonGrid>

                </IonItem>

                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>稀有度</IonLabel></IonCol>
                            <IonCol>{Item_Token.稀有度}</IonCol>
                        </IonRow>
                    </IonGrid>

                </IonItem>

                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>Ar型態</IonLabel></IonCol>
                            <IonCol>{Item_Token.ArType}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>

                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>發行價</IonLabel></IonCol>
                            <IonCol>{Item_Token.首次發行價格}</IonCol>
                        </IonRow>
                    </IonGrid>
                </IonItem>

                <IonItem>
                    <IonGrid>
                        <IonRow>
                            <IonCol><IonLabel>發行時間</IonLabel></IonCol>
                            <IonCol>{moment(Item_Token.首次發行時間).format('YYYY/MM/DD hh:mm:ss A')}</IonCol>
                        </IonRow>
                    </IonGrid>

                </IonItem>
            </IonList>

            {/*<div className={styles.productContainer}>*/}
            {/*    <div className={styles.coverImage}>*/}
            {/*        {Ln_TokenPictureSetCoverImg({*/}
            {/*            圖片組: Item_Token.圖片組,*/}
            {/*            名稱: 'cover',*/}
            {/*        })}*/}
            {/*    </div>*/}
            {/*    <h1 className={`${styles.productTitle} truncate`}>{Item_Token.名稱}</h1>*/}

            {/*    <div className={styles.productInfo}>*/}
            {/*        <div>*/}
            {/*            <IonBadge color="primary">£{Item_Token.首次發行價格.toFixed(2)}</IonBadge>*/}
            {/*        </div>*/}

            {/*        <div className={styles.productRating}>*/}
            {/*            <IonNote>*/}
            {/*                <IonIcon icon={star}/>&nbsp;*/}
            {/*            </IonNote>*/}
            {/*            <IonNote>*/}
            {/*                {5}*/}
            {/*            </IonNote>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </IonRow>
    );
}
