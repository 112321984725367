// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_3_1_WriteArticle_h50px__I4JSv {\n  overflow: hidden;\n  object-fit: cover;\n  height: 50px;\n  width: auto; }\n  .Modal_3_1_WriteArticle_h50px__I4JSv img {\n    height: 100%;\n    width: 100%; }\n", "",{"version":3,"sources":["webpack://./src/pages/Modal_3_1_WriteArticle.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,WAAW,EAAA;EAJb;IAQI,YAAY;IACZ,WAAW,EAAA","sourcesContent":[".h50px {\r\n  overflow: hidden;\r\n  object-fit: cover;\r\n  height: 50px;\r\n  width: auto;\r\n\r\n\r\n  :global(img) {\r\n    height: 100%;\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h50px": "Modal_3_1_WriteArticle_h50px__I4JSv"
};
export default ___CSS_LOADER_EXPORT___;
