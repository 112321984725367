import {IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_1_12, fetch_pg_1_5, fetch_pg_1_6, Pg_1_12_Store, Pg_1_6_Store, push_pg_1_12__Buy, push_pg_1_6__Buy} from "../store/Pg_1_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {checkmarkOutline, chevronDownCircleOutline, copyOutline} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import {Simulate} from "react-dom/test-utils";
import select = Simulate.select;
import Pg_1_6 from "./Pg_1_6";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import CustomPage from "./CustomPage";
import styles_TokenItem from "../components/TokenItem.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import {TokenDetailItem} from "../components/TokenDetailItem";
import styles_1_12 from './Pg_1_12.module.scss';
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleFetchCommonErrors} from "./Utils";

const Pg_1_12 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_1_12";
    const pageId = "Pg_1_12";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    const {tokenId}: any = useParams();

    const router = useIonRouter();

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_1_12({
                p1: tokenId,
            });
            handleFetchCommonErrors(router, errorObj);
        })();
    }, [tokenId]);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_1_12({
            p1: tokenId,
        });
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_1_12_Store, getPageData);


    const hasLoaded = pgData?.Root ?? false;
    const Item_Token = hasLoaded ? pgData.Root.Item_Token : undefined;
    //#endregion

    const _push_pg_1_12__Buy = async (tokenId) => {
        let errorObj = (await push_pg_1_12__Buy({
                p1: tokenId,
                p4: window.location.href,
            }, (result) => {
                window.location.href = result.RedirectTo;
                //router.push(result.RedirectTo);
            })
        );
        handleFetchCommonErrors(router, errorObj);
        if (!errorObj) {
            //alert('購買成功');
            //navToPg_A_1(router);
        } else {
        }
    }

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>
                {/*<PageHeader count={ sideMenuOptions.length } pageName={ pageName } />*/}
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        <IonGrid>
                            <IonRow className="" key={Item_Token.TokenId}>
                                <IonCol>
                                    <div className={styles_TokenItem.coverImg}>
                                        {Ln_TokenPictureSetCoverImg({
                                            圖片組: Item_Token.圖片組,
                                            名稱: 'cover',
                                        })}
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <IonGrid className="ion-justify-content-center ion-text-center w100">
                            <IonRow>
                                <IonCol>

                                <IonButton fill={"solid"} className={styles_1_12.buyButton} expand="block" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    _push_pg_1_12__Buy(Item_Token.TokenId);

                                    return false;
                                }}>
                                    <IonGrid>
                                        <IonRow className="ion-justify-content-center ion-text-center w100 ">
                                            <IonCol>
                                                確認購買
                                                <div className={styles_1_12.right}>{Item_Token.首次發行價格} 寶石</div>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </>
                }


            </CustomPage>


        </IonPage>
    );
};

export default Pg_1_12;
