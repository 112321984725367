import {IonGrid, IonRow, IonCol, IonModal, IonButtons, IonButton, IonIcon, IonContent, IonHeader, IonToolbar, IonTitle, useIonRouter, RefresherEventDetail} from "@ionic/react";
import {checkmarkOutline, chevronBack, scan} from "ionicons/icons";
import {QrReader} from "react-qr-reader";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {OnResultFunction} from "react-qr-reader/src/types";
import CustomField from "../components/CustomField";
import {useFormInput, validateForm} from "../_/useForms";
import {useEffect, useState} from "react";
import indexBy from "underscore/modules/indexBy";
import styles_Modal_2_3 from './Modal_2_3_Entrust.module.scss';
import {Pg_B_1_Store} from "../store/Pg_B_Store";
import {Pg_C_4_Store, push_pg_C_1} from "../store/Pg_C_Store";
import {handleFetchCommonErrors, navToPg_A_1} from "./Utils";
import {push_pg_3_11__Comment} from "../store/Pg_3_Store";
import {useParams} from "react-router";
import {fetch_pg_2_3, fetch_pg_2_3_entrust, Pg_2_3_Entrust_Store, Pg_2_3_Store, push_pg_2_3__Entrust} from "../store/Pg_2_Store";
import {useStoreState} from "pullstate";
import {getPageData} from "../store/Selectors";
import styles from "../components/TokenEnwItem.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import {fetch_pg_0_19} from "../store/Pg_0_Store";


export const useLoginFields = () => {
    return [
        {
            id: "commentText",
            label: "委託價",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "輸入要委託兌換的寶石數量",
                    autoGrow: false,
                },
                state: useFormInput("")
            }
        }

    ];
}

export const Modal_2_3_Entrust = ({tokenEnwId, dismiss, onResult}) => {
    const router = useIonRouter();

    //#region Data Loading

    // useEffect(() => {
    //     setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    // }, []);

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_2_3_entrust({
                p1: tokenEnwId,
            });
            handleFetchCommonErrors(router, errorObj);
        })();
    }, []);


    let reload = async (event: CustomEvent<RefresherEventDetail> | null) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_2_3_entrust({
            p1: tokenEnwId,
        });
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_2_3_Entrust_Store, getPageData);
    const [amountLoaded, setAmountLoaded] = useState(20);
    const [selectedProduct, setSelectedProduct] = useState(false);

    const hasLoaded = pgData?.Root ?? false;
    const Item_TokenEnw = hasLoaded ? pgData.Root.Item_TokenEnw : undefined;
    const Item_Token: any = hasLoaded ? pgData.Root.Item_TokenEnw._Token : undefined;
    const Value_委託總數: any = hasLoaded ? pgData.Root.委託總數 : undefined;
    const Value_委託最低價: any = hasLoaded ? pgData.Root.委託最低價 : undefined;

    //#endregion

    //#region Back button
    useEffect(() => {
        let callback: any = (ev) => {
            // ev.detail.register(10, () => {
            //     console.log('Handler was called!');
            // });

            dismiss();
        };

        document.addEventListener('ionBackButton', callback);
        // Remove listener on unmount
        return () => document.removeEventListener("ionBackButton", callback);
    }, []);
    //#endregion


    const fields = useLoginFields();
    let keyedFields = indexBy(fields, 'id');


    const [errors, setErrors] = useState(false);

    const handleScan = result => {
        if (result) {
            //setQRData(result);
            //play();
            //handleSuccess(result);
            alert(`代碼: ${result.text}`);
            keyedFields.code.input.state.reset(result.text);
            //onResult(result);
        }
    }

    const push_entrust = async () => {
        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {
            const commentText = fields && (keyedFields.commentText as any).input.state.value;

            let {errorObj, payload} = (await push_pg_2_3__Entrust({
                p1: tokenEnwId,
                p2: commentText,
            }));
            handleFetchCommonErrors(router, errorObj);
            if (!errorObj) {
                alert('委託成功');
                //navToPg_A_1(router);
                dismiss();
                if (onResult) {
                    onResult(true);
                }
            } else {
            }
        } else {
        }
    }

    return <>
        <IonHeader>
            <IonToolbar>
                <IonTitle>委託市集交易</IonTitle>
                <IonButtons slot="start">
                    <IonButton onClick={dismiss}>
                        <IonIcon size="large" icon={chevronBack} style={{marginLeft: "-0.7rem"}}/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
            <IonGrid>
                {hasLoaded &&
                    <>
                        <IonRow className="ion-justify-content-center">
                            <IonCol>
                                <div className={styles.container}>
                                    <div className={styles.coverImg}>
                                        {Ln_TokenPictureSetCoverImg({
                                            圖片組: Item_Token.圖片組,
                                            名稱: 'cover',
                                        })}
                                    </div>
                                </div>
                            </IonCol>
                        </IonRow>

                        {/*<IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">*/}
                        {/*    <IonCol>*/}
                        {/*        <IonButton fill={"solid"}>*/}
                        {/*            兌換*/}
                        {/*        </IonButton>*/}
                        {/*    </IonCol>*/}
                        {/*</IonRow>*/}

                        <IonRow>
                            <IonCol>
                                <CustomField key={keyedFields.commentText.id} field={keyedFields.commentText} errors={errors} noLabel={false}/>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                            <IonCol>
                                目前市集有 {Value_委託總數} 件委託待交易，最低價格為 {Value_委託最低價 < 0 ? '無最低 ' : Value_委託最低價}寶石，成交後會向您收手續費 (成交價格 5% 的寶石數量)
                            </IonCol>
                        </IonRow>

                        <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                            <IonCol>
                                <IonButton fill={"solid"} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    //_push_pg_4_2__AddCouponBookmark(Item_合約獎勵.合約獎勵Id);
                                    // alert('代碼為空，無法兌換')
                                    push_entrust();

                                    return false;
                                }}>
                                    委託
                                </IonButton>
                            </IonCol>
                        </IonRow>


                    </>
                }
            </IonGrid>
        </IonContent>
    </>;
};
