import {IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonSegment, IonSegmentButton, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_Pg_5_1, Pg_5_1_Store} from "../store/Pg_5_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
import {fetch_pg_1_2} from "../store/Pg_1_Store";
import {checkmarkOutline, chevronDownCircleOutline} from "ionicons/icons";
import {TokenStackItem} from "../components/TokenStackItem";
import {useAppUtil, useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import CustomPage from "./CustomPage";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {TokenEnwEntrustListItem} from "../components/TokenEnwEntrustListItem";
import {useParams} from "react-router";
import {useLoginFields} from "./Pg_A_1";
import {validateForm} from "../_/useForms";
import indexBy from "underscore/modules/indexBy";
import styles_TokenItem from "../components/TokenItem.module.scss";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleFetchCommonErrors} from "./Utils";

const Pg_5_1 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_5_3";
    const pageId = "Pg_5_3";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    useEffect(() => {
        (async () => {
            let errorObj = await fetch_Pg_5_1();
            handleFetchCommonErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_Pg_5_1();
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_5_1_Store, getPageData);
    const [amountLoaded, setAmountLoaded] = useState(20);
    const [selectedProduct, setSelectedProduct] = useState(false);

    const hasLoaded = pgData?.Root ?? false;
    const List_Token = hasLoaded ? pgData.Root.List_Token : undefined;
    const RegionLockMessage = hasLoaded ? pgData.Root.RegionLockMessage : undefined;
    //#endregion


    //#region +Event

    //#region click_全部委託
    const click_全部委託 = async () => {
        const navToPg_5_1 = () => {
            router.push("/tabs/5_1", "forward", "push");
        }
        navToPg_5_1();
        return false;
    }
    //#endregion

    //#region click_我的委託
    const click_我的委託 = async () => {
        const navToPg_5_5 = () => {
            router.push("/tabs/5_5", "forward", "push");
        }
        navToPg_5_5();
        return false;
    }
    //#endregion

    //#region click_標記委託
    const click_標記委託 = async () => {
        const navToPg_5_7 = () => {
            router.push("/tabs/5_7", "forward", "push");
        }
        navToPg_5_7();
        return false;
    }
    //#endregion

    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded && <Fragment>
                    {RegionLockMessage !== null ?
                        <>
                            <IonGrid>
                                <IonRow>
                                    <IonCol className="ion-justify-content-center ion-text-center w100 ion-padding">
                                        {RegionLockMessage}
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </> : <>
                            <IonGrid>
                                <IonRow>
                                    <IonCol>
                                        <IonSegment value="全部委託">
                                            <IonSegmentButton value="全部委託">
                                                <IonLabel>全部委託</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value="我的委託" onClick={click_我的委託}>
                                                <IonLabel>我的委託</IonLabel>
                                            </IonSegmentButton>
                                            <IonSegmentButton value="標記委託" onClick={click_標記委託}>
                                                <IonLabel>標記委託</IonLabel>
                                            </IonSegmentButton>
                                        </IonSegment>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>

                            {/*<IonRouterLink routerLink={`/5_1`}>*/}
                            {/*    <IonButton fill={"solid"}>*/}
                            {/*        <IonIcon icon={checkmarkOutline}/>*/}
                            {/*        &nbsp;全部委託 跳到5_1*/}
                            {/*    </IonButton>*/}
                            {/*</IonRouterLink>*/}

                            {/*<IonRouterLink routerLink={`/5_5`}>*/}
                            {/*    <IonButton fill={"solid"}>*/}
                            {/*        <IonIcon icon={checkmarkOutline}/>*/}
                            {/*        &nbsp;我的委託 跳到5_5*/}
                            {/*    </IonButton>*/}
                            {/*</IonRouterLink>*/}

                            {/*<IonRouterLink routerLink={`/5_7`}>*/}
                            {/*    <IonButton fill={"solid"}>*/}
                            {/*        <IonIcon icon={checkmarkOutline}/>*/}
                            {/*        &nbsp;標記委託 跳到5_7*/}
                            {/*    </IonButton>*/}
                            {/*</IonRouterLink>*/}

                            <IonGrid className="ion-padding">
                                {/*<IonRow className={ styles.searchContainer }>*/}
                                {/*    <IonCol size="12">*/}
                                {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                {/*    </IonCol>*/}
                                {/*</IonRow>*/}


                                {/*click={() => handleShowModal(item)}*/}
                                <div className={styles_TokenItem.coverImageContainer}>
                                    {List_Token.map((item, index) => {
                                        return <Fragment key={item.TokenId}>
                                            <TokenStackItem Item_Token={item}/>
                                        </Fragment>
                                    })}
                                </div>
                            </IonGrid>
                        </>}
                </Fragment>}
            </CustomPage>
        </IonPage>
    );
};

export default Pg_5_1;
