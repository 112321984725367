import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonIcon, IonModal, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import Home from './pages/Home';
import BlogPost from './pages/BlogPost';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import 'animate.css';

import {pages} from './pages';
import {useEffect, useState} from 'react';
import {cartOutline} from 'ionicons/icons';
import {useRef} from 'react';
import {useStoreState} from 'pullstate';
import {CartStore} from './store';
import {getCartCount} from './store/Selectors';
import {CartModal} from './components/CartModal';
import Pg_1_1 from "./pages/Pg_1_1";
import Pg_2_1 from "./pages/Pg_2_1";
import Pg_3_1 from "./pages/Pg_3_1";
import Pg_4_2 from "./pages/Pg_4_2";
import Pg_5_1 from "./pages/Pg_5_1";
import Pg_1_2 from "./pages/Pg_1_2";
import Pg_1_5 from "./pages/Pg_1_5";
import Pg_1_6 from "./pages/Pg_1_6";
import Pg_1_12 from "./pages/Pg_1_12";
import Pg_2_13 from "./pages/Pg_2_13";
import Pg_2_3 from "./pages/Pg_2_3";
import Pg_5_2 from "./pages/Pg_5_2";
import Pg_5_3 from "./pages/Pg_5_3";
import Pg_5_5 from "./pages/Pg_5_5";
import Pg_5_7 from "./pages/Pg_5_7";
import {SideMenuProvider} from "./components/sideMenu/SideMenuProvider";
import NavRoutes from "./components/nav/NavRoutes";

import smoothscroll from 'smoothscroll-polyfill';
import {initAuth} from "./store/Common_Store";
import Pace from "./_/Pace";

setupIonicReact();

// kick off the polyfill!
smoothscroll.polyfill();

Pace.start({
     ajax: {
         trackMethods: ['GET','POST'],
         trackWebSockets: false,
         ignoreURLs: []
     }
    // document: false, // disabled
    // eventLag: false, // disabled
    // websocket: false,
    // elements: {
    //     selectors: ['.my-page']
    // }
});

initAuth();

const App = () => {
    // useEffect(() => {
    //     initAuth();
    // }, [])
    return (
        <IonApp>
            <SideMenuProvider>
                <NavRoutes />
            </SideMenuProvider>
        </IonApp>
    );
}
export default App;
