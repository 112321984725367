import React, {useEffect, useRef} from "react";
import {CallMe} from "../my-lib.mjs";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import queryString from "query-string";

declare var window: any;

let rawMarkup = `
  <style>
  </style>
  
  
    <div id="map"></div>
    <div id="msg"></div>
    <script>
// Initialize and add the map
var map;
function initMap() {
  // The map, centered on Central Park
  const center = {lat: 40.774102, lng: -73.971734};
  const options = {zoom: 15, scaleControl: true, center: center};
  map = new google.maps.Map(
      document.getElementById('map'), options);
  // Locations of landmarks
  const dakota = {lat: 40.7767644, lng: -73.9761399};
  const frick = {lat: 40.771209, lng: -73.9673991};
  // The markers for The Dakota and The Frick Collection
  var mk1 = new google.maps.Marker({position: dakota, map: map});
  var mk2 = new google.maps.Marker({position: frick, map: map});
}
    </script>
    <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB9VCxU_nMkvOWoBC8YwKlVR13iPAR7qEs&callback=initMap"/>

    
    
    `;

const areEqual = (prevProps, nextProps) => {
    if (!nextProps.AnimationUrl) {
        return false
    } else {
        if (prevProps.AnimationUrl === nextProps.AnimationUrl) {
            return true;
        }
    }
    return false;
}

const _Test = (props) => {
    const pageRef = useRef();
    const pageName = "Test";
    const pageId = "Test";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    let {url = null}: {
        url: string | null,
    } = queryString.parse(window.location.search) as any;

    let {AnimationUrl = url} = props;

    let firstEnter = useRef<boolean>(true);

    const viewerZone = useRef(null);

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId, disableSideMenu: true});
    }, []);

    useEffect(() => {
        if (viewerZone.current !== null) {
            if (firstEnter.current === true) {
                // window.requestAnimationFrame(() => {
                //     firstEnter.current = false;
                //     window.VIEWER = {};
                //     CallMe();
                //     if (!window?.VIEWER?.app?.viewer) {
                //
                //     }
                //     window.VIEWER.app.view(`https://www.labie.io/${AnimationUrl}`, '', new Map());
                // })
            } else {

                // const cleanMaterial = material => {
                //     console.log('dispose material!')
                //     material.dispose()
                //
                //     // dispose textures
                //     for (const key of Object.keys(material)) {
                //         const value = material[key]
                //         if (value && typeof value === 'object' && 'minFilter' in value) {
                //             console.log('dispose texture!')
                //             value.dispose()
                //         }
                //     }
                // }
                //
                // if (window.VIEWER.scene) {
                //     window.VIEWER.scene.traverse(object => {
                //         if (!object.isMesh) return
                //
                //         console.log('dispose geometry!')
                //         object.geometry.dispose()
                //
                //         if (object.material.isMaterial) {
                //             cleanMaterial(object.material)
                //         } else {
                //             // an array of materials
                //             for (const material of object.material) cleanMaterial(material)
                //         }
                //     });
                // }

                //window.VIEWER.app.view(`https://www.labie.io/${AnimationUrl}`, '', new Map());
            }
            ;
        }
    }, [viewerZone.current]);


    useEffect(() => {
        return () => {
            //if (viewerZone.current !== null) {


            //window.VIEWER = null;
            // }
        }
    }, []);

    return (
        <div dangerouslySetInnerHTML={{__html: rawMarkup}} ref={viewerZone}/>
    );
};

let RealityViewer3d: any = React.memo(_Test, areEqual);

export default RealityViewer3d;
