// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_2_1_readerContainer__S97Wr {\n  position: relative; }\n\n.Modal_2_1_reader__mzaKf {\n  z-index: 9999;\n  background-color: white;\n  border: 5px solid red; }\n\n.Modal_2_1_readerBg__r2k\\+Z {\n  display: inline-block;\n  text-align: center;\n  width: 100%; }\n\n.Modal_2_1_readerBgText__enc8Y {\n  max-width: 350px;\n  display: inline-block; }\n", "",{"version":3,"sources":["webpack://./src/pages/Modal_2_1.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAkB,EAAA;;AAGpB;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB,EAAA;;AAEvB;EAME,qBAAqB;EACrB,kBAAkB;EAClB,WAAW,EAAA;;AAGb;EACE,gBAAgB;EAChB,qBAAqB,EAAA","sourcesContent":["\r\n.readerContainer {\r\n  position: relative;\r\n}\r\n\r\n.reader {\r\n  z-index: 9999;\r\n  background-color: white;\r\n  border: 5px solid red;\r\n}\r\n.readerBg {\r\n  //position: absolute;\r\n  //top: 50%;\r\n  //text-align: center;\r\n  //width: 100%;\r\n  //z-index: 1;\r\n  display: inline-block;\r\n  text-align: center;\r\n  width: 100%;\r\n}\r\n\r\n.readerBgText {\r\n  max-width: 350px;\r\n  display: inline-block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"readerContainer": "Modal_2_1_readerContainer__S97Wr",
	"reader": "Modal_2_1_reader__mzaKf",
	"readerBg": "Modal_2_1_readerBg__r2k+Z",
	"readerBgText": "Modal_2_1_readerBgText__enc8Y"
};
export default ___CSS_LOADER_EXPORT___;
