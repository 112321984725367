// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pg_2_1_avatarContainer__vo7\\+2 {\n  text-align: center;\n  margin-top: 2em; }\n  .Pg_2_1_avatarContainer__vo7\\+2 .avatar {\n    display: inline-block;\n    aspect-ratio: 1;\n    width: 40%;\n    height: 40%;\n    max-width: 200px;\n    max-height: 200px; }\n  .Pg_2_1_avatarContainer__vo7\\+2 .name {\n    display: inline-block; }\n", "",{"version":3,"sources":["webpack://./src/pages/Pg_2_1.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAkB;EAClB,eAAe,EAAA;EAFjB;IAOI,qBAAqB;IACrB,eAAe;IAEf,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,iBAAiB,EAAA;EAbrB;IAiBI,qBAAoB,EAAA","sourcesContent":["\r\n.avatarContainer {\r\n  text-align: center;\r\n  margin-top: 2em;\r\n\r\n\r\n\r\n  :global(.avatar) {\r\n    display: inline-block;\r\n    aspect-ratio: 1;\r\n\r\n    width: 40%;\r\n    height: 40%;\r\n    max-width: 200px;\r\n    max-height: 200px;\r\n  }\r\n\r\n  :global(.name) {\r\n    display:inline-block;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarContainer": "Pg_2_1_avatarContainer__vo7+2"
};
export default ___CSS_LOADER_EXPORT___;
