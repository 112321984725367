import {Store} from "pullstate";
import _ from "../../src/_/index";
import update from "immutability-helper";
import {AuthStore, Logout, saveOfflineAuth} from "./Common_Store";

export const Pg_A_0_Store = new Store({});
export const Pg_A_1_Store = new Store({});


let fetchOption = (postParams: any) => {
    return {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(postParams) // body data type must match "Content-Type" header
    } as any;
};


export const push_pg_A_1__Login = async (postParams: {
    p1: string, //username
    p2: string, //password
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_A_1/login`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_A_1_Store.update(s => {
        //     let _s: any = s;
        //     Object.assign(_s, payload);
        //     return _s;
        // });
        AuthStore.update(s => {
            let _s: any = s;
            Object.assign(_s, payload);
            Object.assign(_s, {signBy: 'push_pg_A_1'})
            //o._pg_A_1 = payload;
            return _s;
        });
        await saveOfflineAuth();
        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}


export const push_pg_A_1__Logout = async (postParams: {
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_A_1/logout`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_A_1_Store.update(s => {
        //     let _s: any = s;
        //     Object.assign(_s, payload);
        //     return _s;
        // });
        // AuthStore.update(s => {
        //     let _s: any = s;
        //     Object.assign(_s, payload);
        //     Object.assign(_s, {signBy: 'push_pg_A_1'})
        //     //o._pg_A_1 = payload;
        //     return _s;
        // });
        // await saveOfflineAuth();
        await Logout();
        return result;
    } else {
        // if (error.code === 2) {
        //     return error;
        // }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

