import {IonBadge, IonButton, IonCol, IonIcon, IonNote, IonRouterLink, useIonRouter} from "@ionic/react";
import {bookmark, bookmarkOutline, star} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";
import styles from "./TokenStackItem.module.scss";
import {useStoreState} from "pullstate";
import {UserPostFavorStore, UserTokenEntrustStore} from "../store/Common_Store";
import {getPageData} from "../store/Selectors";
import styles_Common from "../pages/Common.module.scss";
import {push_pg_3_11__AddArticleBookmark, push_pg_3_11__RemoveArticleBookmark} from "../store/Pg_3_Store";
import {push_pg_5_7__AddEntrustBookmark, push_pg_5_7__RemoveEntrustBookmark} from "../store/Pg_5_Store";
import {push_pg_2_3__Entrust} from "../store/Pg_2_Store";
import {handleFetchCommonErrors} from "../pages/Utils";


const _push_pg_3_11__AddArticleBookmark = async (router, tokenId) => {
    let errorObj = (await push_pg_5_7__AddEntrustBookmark({
        p1: tokenId,
    }));
    handleFetchCommonErrors(router, errorObj);
    if (!errorObj) {
        //alert('委託成功');
        //navToPg_A_1(router);
    } else {

    }
}

const _push_pg_3_11__RemoveArticleBookmark = async (router, tokenId) => {
    let errorObj = (await push_pg_5_7__RemoveEntrustBookmark({
        p1: tokenId,
    }));
    handleFetchCommonErrors(router, errorObj);
    if (!errorObj) {
        //alert('委託成功');
        //navToPg_A_1(router);
    } else {

    }
}

export const TokenStackItem = ({Item_Token, fromHome = false}) => {
    const router = useIonRouter();
    const tokenEntrusts = useStoreState(UserTokenEntrustStore, getPageData);

    let Item_疊加狀態: any = Item_Token?._疊加狀態;
    if (!Item_疊加狀態) {
        return <>(ERROR:Item_疊加狀態)</>;
    }

    return (
        <ActionLink link={`/tabs/5_2/${Item_Token.TokenId}`}>
            <div className={styles.container}>
                <div className={styles.coverImg}>
                    {Ln_TokenPictureSetCoverImg({
                        圖片組: Item_Token.圖片組,
                        名稱: 'cover',
                    })}
                </div>
                {Item_疊加狀態 &&
                    <div className={`${styles.stackBadge}`}>{Item_疊加狀態.總層}</div>
                }
                <div className={`${styles.productTitleContainer}`}>
                    <div>
                        <h1 className={`${styles.productTitle} truncate`}>{Item_Token.名稱}</h1>
                    </div>

                    <div className="last">
                        <div className="line"/>
                        <div className="lowestPrice">
                            最低價格: {Item_疊加狀態.最低價格.toFixed(0)}
                        </div>
                        <div className="right">
                            <div className={styles.productRating}>
                                {/*<IonNote>*/}
                                {/*    <IonIcon icon={star}/>*/}
                                {/*</IonNote>*/}
                                {/*///bookmark*/}
                                {tokenEntrusts[Item_Token.TokenId] &&
                                    <>
                                        <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            _push_pg_3_11__RemoveArticleBookmark(router, Item_Token.TokenId);

                                            return false;
                                        }}><IonIcon icon={bookmark} className={styles_Common.actionButtonIcon}></IonIcon>
                                        </IonButton>
                                    </>
                                }
                                {!tokenEntrusts[Item_Token.TokenId] &&
                                    <>
                                        <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            _push_pg_3_11__AddArticleBookmark(router, Item_Token.TokenId);

                                            return false;
                                        }}><IonIcon icon={bookmarkOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                                        </IonButton>
                                    </>
                                }
                                {/*<IonNote>*/}
                                {/*    <IonIcon icon={ bookmarkOutline }></IonIcon>*/}
                                {/*</IonNote>*/}
                                {/*<IonButton fill="clear">*/}
                                {/*    <IonIcon icon={ bookmark }></IonIcon>*/}
                                {/*</IonButton>*/}

                                {/*<IonNote>*/}
                                {/*    {5}*/}
                                {/*</IonNote>*/}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </ActionLink>
    );
}
