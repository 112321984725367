import {Store} from "pullstate";
import _ from "../../src/_/index";
import {Pg_A_1_Store} from "./Pg_A_Store";
import {Pg_B_1_Store} from "./Pg_B_Store";

export const Pg_C_0_Store = new Store({});
export const Pg_C_1_Store = new Store({});
export const Pg_C_4_Store = new Store({});

let fetchOption = (postParams) => {
    return {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(postParams) // body data type must match "Content-Type" header
    } as any;
};


export const fetch_pg_C_1 = async (contractId) => {
    let postParams = {
        p1: contractId,
    };
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_C_1`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_C_1_Store.update(s => {
            return Object.assign(s, payload)
        });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}


export const push_pg_C_1 = async (postParams: {
    scodeEmail: string,
    vcodeEmail: string,
    scodePhone: string,
    vcodePhone: string,
    p1: string,
    p2: string,
    p3: string,
    p4: string,
    p5: string,
    p6: string,
}) => {
    // let postParams = {
    //     scodeEmail,
    //     vcodeEmail,
    //     scodePhone,
    //     vcodePhone,
    //     p1,
    //     p2,
    //     p3,
    //     p4,
    //     p5,
    // };
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_c_1`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_C_1_Store.update(s => {
            let o: any = s;
            Object.assign(o, payload);
            o._pg_C_1 = payload;
            return o;
        });

        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_C_4_sendphoneverify = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_c_4/sendphoneverify`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_C_4_Store.update(s => {
            let o: any = s;
            Object.assign(o, payload);
            return o;
        });

        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}


export const push_pg_C_4_phoneverify = async (postParams: {
    p1: string,
    scodePhone: string,
    vcodePhone: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_c_4/phoneverify`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_C_4_Store.update(s => {
            let o: any = s;
            Object.assign(o, payload);
            return o;
        });

        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}
