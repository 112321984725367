import {Store} from "pullstate";
import _ from "../../src/_/index";
import update from "immutability-helper";
import {Pg_C_4_Store} from "./Pg_C_Store";

export const Pg_B_1_Store = new Store({});


let fetchOption = (postParams: any) => {
    return {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(postParams) // body data type must match "Content-Type" header
    } as any;
};


export const push_pg_B_1 = async (postParams: {
    scodeEmail: string,
    vcodeEmail: string,
    p1: string, //username
    p2: string, //password
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_b_1`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_B_1_Store.update(s => {
            let o: any = s;
            Object.assign(o, payload);
            o._pg_B_1 = payload;
            return o;
        });

        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_B_1_sendemailverify = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_b_1/sendemailverify`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_B_1_Store.update(s => {
            let o: any = s;
            Object.assign(o, payload);
            return o;
        });

        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}



export const push_pg_B_1_emailverify = async (postParams: {
    p1: string,
    scodeEmail: string,
    vcodeEmail: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_b_1/emailverify`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_B_1_Store.update(s => {
            let o: any = s;
            Object.assign(o, payload);
            return o;
        });

        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}
