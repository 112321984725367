// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pg_1_12_buyButton__iYqkK {\n  width: 100%;\n  padding-left: 10px;\n  padding-right: 10px;\n  box-sizing: border-box; }\n\n.Pg_1_12_right__M64Dt {\n  position: absolute;\n  right: 0;\n  display: inline-block; }\n", "",{"version":3,"sources":["webpack://./src/pages/Pg_1_12.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB,EAAA;;AAKxB;EACE,kBAAkB;EAClB,QAAQ;EACR,qBAAqB,EAAA","sourcesContent":[".buyButton {\n  width: 100%;\n  padding-left: 10px;\n  padding-right: 10px;\n  box-sizing: border-box;\n\n\n}\n\n.right {\n  position: absolute;\n  right: 0;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buyButton": "Pg_1_12_buyButton__iYqkK",
	"right": "Pg_1_12_right__M64Dt"
};
export default ___CSS_LOADER_EXPORT___;
