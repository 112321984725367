import {IonAvatar, IonBadge, IonButton, IonCol, IonIcon, IonNote, IonRouterLink, IonText} from "@ionic/react";
import styles from "./ContractBonusDetailItem.module.scss";
import {bookmark, bookmarkOutline, chatboxEllipses, chatboxEllipsesOutline, heart, heartOutline, star} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";
import styles_Common from "../pages/Common.module.scss";
import moment from "moment";
import QRCode from "react-qr-code";

export const ContractBonusDetailItem = ({Item_合約獎勵, 已兌換次數, 總共可兌換次數, QrCode, fromHome = false, noLink = false}) => {
    let Item_合約: any = Item_合約獎勵?._合約;
    if (!Item_合約) {
        return <>(ERROR:Item_合約)</>;
    }

    return (
        <ActionLink link={`/tabs/4_4/${Item_合約獎勵.合約獎勵Id}`} className={styles.container} noLink={noLink}>
            <div className={styles.containerInner}>
                <div className={styles.postBar}>
                    {Ln_TokenPictureSetCoverImg({
                        圖片組: Item_合約獎勵.封面組,
                        名稱: 'cover',
                    })}
                </div>

                <div className={styles.titleBar}>
                        {Item_合約獎勵.標題}
                </div>

                <ul className={styles.contentBar}>
                    <li className="l">介紹</li>
                    <IonText className="c" dangerouslySetInnerHTML={{__html: Item_合約獎勵.內容}}/>
                </ul>

                <ul className={styles.timeRangeBar}>
                    <li className="l">使用期限</li>
                    <div className="c">
                        {moment(Item_合約獎勵.起始生效區間).format('YYYY/MM/DD')} - {moment(Item_合約獎勵.生效區間結束).format('YYYY/MM/DD')}
                    </div>
                </ul>

                <ul className={styles.contentBar}>
                    <li className="l">使用狀態</li>
                    <div className="c">
                        每個數位收藏，可兌換 {Item_合約獎勵.CanUseTimesPerTokenEnw} 次，共有 {總共可兌換次數} 次兌換。已兌換 {已兌換次數} 次，尚可兌換 {總共可兌換次數 - 已兌換次數} 次。
                    </div>
                </ul>



                {Item_合約獎勵.兌換時間 ? <>
                        <div className={styles.redeemBar}>
                            <div className="l">已兌換</div>
                        </div>
                    </> :
                    <>
                        <div className={styles.redeemBar}>
                            <div className="l">掃描 QR code 兌換</div>
                            <div className="c" style={{height: "auto", margin: "0 auto", maxWidth: 125, width: "100%"}}>
                                <QRCode
                                    size={256}
                                    style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                    value={QrCode}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <div className="f"></div>
                        </div>
                    </>
                }
            </div>
        </ActionLink>
    );
}
