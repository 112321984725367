import React from "react";
import {IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs, IonRouterOutlet, createAnimation} from "@ionic/react";
import { Redirect, Route } from "react-router-dom";
import styles from '../../pages/Common.module.scss';

const TabPane = (props) => {
    const animationBuilder = (baseEl, opts) => {
        const enteringAnimation = createAnimation()
            .addElement(opts.enteringEl)
             .fromTo('opacity', 0, 1)
             .duration(250);

        const leavingAnimation = createAnimation()
            .addElement(opts.leavingEl)
             .fromTo('opacity', 1, 0)
             .duration(100);

        const animation = createAnimation()
            // .addAnimation(enteringAnimation)
            // .addAnimation(leavingAnimation);

        return animation;
    };

    return (
        <IonTabs >
            <IonRouterOutlet animation={animationBuilder}>
                { props.tabs.map((tab, i) => {

                    const TabComponent = tab.component;

                    if (tab.isTab) {
                        return <Route key={ `tab_route_${ i }` } path={ tab.path } render={ (props) => <TabComponent { ...props } sideMenu={ tab.sideMenu ? true : false } sideMenuOptions={ tab.sideMenuOptions ? tab.sideMenuOptions : false } /> } exact={ true }/>;
                    } else {

                        return <Route key={ `child_tab_route_${ i }` } path={ tab.path } render={ (props) => <TabComponent {...props} sideMenu={ tab.sideMenu ? true : false } sideMenuOptions={ tab.sideMenuOptions ? tab.sideMenuOptions : false } /> } exact={ false } />;
                    }
                })}
            </IonRouterOutlet>

            <IonTabBar slot={ props.position } className={ styles.themeBorderTop}>
                { props.tabs.map((tab, i) => {
                    if (tab.isTab) {
                        return (
                            <IonTabButton key={ `tab_button_${ i + 1 }` } tab={ `tab_${ i + 1 }` } href={ tab.path }>
                                <IonIcon icon={ tab.icon } />
                                { tab.label && <IonLabel>{ tab.label }</IonLabel> }
                            </IonTabButton>
                        );
                    }
                })}
            </IonTabBar>
        </IonTabs>
    );
}

export default TabPane;
