import React, { useState } from "react";
import {
    useBatchAddListener,
    useAbortBatch,
    useChunkFinishListener,
    useBatchFinishListener
} from "@rpldy/chunked-uploady";
import LinearProgress from "@mui/material/LinearProgress";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineSharp from "@mui/icons-material/CheckCircleOutlineSharp";
import ClearSharp from "@mui/icons-material/ClearSharp";

const MAX_FILE_SIZE = 99000000;

const MyUploadProgress = () => {
    const abortBatch = useAbortBatch();
    const [batchId, set_batchId] = useState("");
    const [progress, setProgress] = useState(0);
    const [fileName, set_fileName] = useState("");
    const [uploadOpen, set_uploadOpen] = useState(false);
    useBatchAddListener((batch) => {
        if (batch?.items[0]?.file?.size <= MAX_FILE_SIZE) {
            setProgress(0);
            set_fileName(batch?.items[0]?.file?.name);
            set_batchId(batch.id);
            set_uploadOpen(true);
        }
    });
    useChunkFinishListener((data) => {
        if (data?.item?.completed < 100) {
            setProgress(data?.item?.completed);
        }
    });
    useBatchFinishListener(() => {
        setProgress(100);
        setTimeout(() => {
            //show completed for 2 seconds
            set_uploadOpen(false);
        }, 2000);
    });
    const handleCancel = () => {
        abortBatch(batchId);
        set_uploadOpen(false);
    };
    return (
        <Dialog fullWidth maxWidth="xs" open={uploadOpen}>
            <section className="p-5">
                <p>
                    <strong>Uploading...</strong>
                </p>
                <div className="flex">
                    <div>{fileName}</div>
                </div>
                <div className="flex">
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        color={progress < 100 ? "primary" : "secondary"}
                        className="flex-1"
                    />
                    <div>
                        {progress < 100 ? (
                            <IconButton onClick={handleCancel}>
                                <ClearSharp />
                            </IconButton>
                        ) : (
                            <CheckCircleOutlineSharp />
                        )}
                    </div>
                </div>
                <div>{Math.round(progress)}% completed</div>
            </section>
        </Dialog>
    );
};

export default MyUploadProgress;
