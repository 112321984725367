import {Store} from "pullstate";
import _ from "../../src/_/index";
import indexBy from "underscore/modules/indexBy";
import {UserPostBookmarkStore, UserPostFavorStore, UserTokenEntrustStore} from "./Common_Store";

export const Pg_5_1_Store = new Store({
});

export const Pg_5_2_Store = new Store({
});

export const Pg_5_3_Store = new Store({
});

export const Pg_5_5_Store = new Store({
});

export const Pg_5_7_Store = new Store({
});

let fetchOption = (postParams) => {
    return {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(postParams) // body data type must match "Content-Type" header
    } as any;
};

export const fetch_Pg_5_1 = async () => {
    let postParams = {
    }
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_5_1`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_5_1_Store.update(s => { return Object.assign(s, payload) });

        if (payload.Root.Attach_List_會員_Token__委託關注) {
            let keyed = indexBy(payload.Root.Attach_List_會員_Token__委託關注, 'TokenId');

            UserTokenEntrustStore.update(s => {
                return Object.assign(s, keyed)
            });
        }

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const fetch_Pg_5_2 = async (postParams: {
    p1: string //TokenId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_5_2`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_5_2_Store.update(s => { return Object.assign(s, payload) });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const fetch_Pg_5_3 = async (postParams: {
    p1: string //TokenEnw委託二手交易Id
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_5_3`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_5_3_Store.update(s => { return Object.assign(s, payload) });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}


export const fetch_Pg_5_5 = async () => {
    let postParams = {
        //p1: contractId,
    };
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_5_5`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_5_5_Store.update(s => { return Object.assign(s, payload) });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const fetch_Pg_5_7 = async () => {
    let postParams = {
        //p1: contractId,
    };
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_5_7`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_5_7_Store.update(s => { return Object.assign(s, payload) });

    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}



export const push_pg_5_3__RemoveCouponBookmark = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_5_3/removebookmark`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });

        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_5_5__CancelEntrust = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_5_5/cancelentrust`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }
}


export const push_pg_5_7__AddEntrustBookmark = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_5_7/addbookmark`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserTokenEntrustStore.update(s => {
            return Object.assign(s, payload)
        });
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }
}


export const push_pg_5_7__RemoveEntrustBookmark = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_5_7/removebookmark`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserTokenEntrustStore.update(s => {
            return Object.assign(s, payload)
        });
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }
}
