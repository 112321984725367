import {IonAvatar, IonBadge, IonButton, IonButtons, IonCardTitle, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRouterLink, IonRow, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles_Pg_C_1 from "./Pg_C_1.module.scss";
import {Pg_C_1_Store, Pg_C_4_Store, push_pg_C_4_phoneverify, push_pg_C_4_sendphoneverify} from "../store/Pg_C_Store";
import {useEffect, useRef, useState} from "react";
import {push_pg_C_1} from "../store/Pg_C_Store";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import {personCircle} from "ionicons/icons";
import CustomField from "../components/CustomField";
import {useParams} from "react-router";
import {useFormInput, validateForm} from "../_/useForms";
import indexBy from 'underscore/modules/indexBy.js';
import {Pg_B_1_Store, push_pg_B_1_emailverify, push_pg_B_1_sendemailverify} from "../store/Pg_B_Store";
import {navToPg_A_1} from "./Utils";


const customSelectContainerOptions = {
    header: '手機國際碼',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};

let areaCodeList: any = [
    {
        "countryCode": "TW",
        "countryName": "台灣",
        "phoneCode": "+886"
    },
    {
        "countryCode": "CN",
        "countryName": "中國",
        "phoneCode": "+86"
    },
    {
        "countryCode": "HK",
        "countryName": "香港",
        "phoneCode": "+852"
    },
    {
        "countryCode": "MO",
        "countryName": "澳門",
        "phoneCode": "+853"
    },
    // {
    //     "countryCode": "US",
    //     "countryName": "美國",
    //     "phoneCode": "+1"
    // },
    // {
    //     "countryCode": "CA",
    //     "countryName": "加拿大",
    //     "phoneCode": "+1"
    // },
    // {
    //     "countryCode": "UK",
    //     "countryName": "英國",
    //     "phoneCode": "+44"
    // },
    // {
    //     "countryCode": "SG",
    //     "countryName": "新加坡",
    //     "phoneCode": "+65"
    // },
    // {
    //     "countryCode": "NZ",
    //     "countryName": "紐西蘭",
    //     "phoneCode": "+64"
    // },
    // {
    //     "countryCode": "JP",
    //     "countryName": "日本",
    //     "phoneCode": "+81"
    // },
    // {
    //     "countryCode": "KP",
    //     "countryName": "韓國",
    //     "phoneCode": "+82"
    // },
    // {
    //     "countryCode": "AU",
    //     "countryName": "澳大利亞",
    //     "phoneCode": "+61"
    // },
    // {
    //     "countryCode": "FR",
    //     "countryName": "法國",
    //     "phoneCode": "+33"
    // },
    // {
    //     "countryCode": "DE",
    //     "countryName": "德國",
    //     "phoneCode": "+49"
    // },



    // {
    //     "countryCode": "AF",
    //     "countryName": "阿富汗",
    //     "phoneCode": "+93"
    // },
    // {
    //     "countryCode": "AR",
    //     "countryName": "阿根廷",
    //     "phoneCode": "+54"
    // },
    // {
    //     "countryCode": "AT",
    //     "countryName": "奧地利",
    //     "phoneCode": "+43"
    // },
    //
    // {
    //     "countryCode": "BH",
    //     "countryName": "巴林",
    //     "phoneCode": "+973"
    // },
    // {
    //     "countryCode": "BD",
    //     "countryName": "孟加拉",
    //     "phoneCode": "+880"
    // },
    // {
    //     "countryCode": "BE",
    //     "countryName": "比利時",
    //     "phoneCode": "+32"
    // },
    // {
    //     "countryCode": "BT",
    //     "countryName": "不丹",
    //     "phoneCode": "+975"
    // },
    // {
    //     "countryCode": "BO",
    //     "countryName": "玻利維亞",
    //     "phoneCode": "+591"
    // },
    // {
    //     "countryCode": "BR",
    //     "countryName": "巴西",
    //     "phoneCode": "+55"
    // },
    // {
    //     "countryCode": "KH",
    //     "countryName": "柬埔寨",
    //     "phoneCode": "+855"
    // },
    // {
    //     "countryCode": "CM",
    //     "countryName": "喀麥隆",
    //     "phoneCode": "+237"
    // },
    // {
    //     "countryCode": "AI",
    //     "countryName": "安圭拉",
    //     "phoneCode": "+1264"
    // },
    // {
    //     "countryCode": "AG",
    //     "countryName": "安地瓜",
    //     "phoneCode": "+1268"
    // },
    // {
    //     "countryCode": "AW",
    //     "countryName": "阿魯巴",
    //     "phoneCode": "+297"
    // },
    // {
    //     "countryCode": "BM",
    //     "countryName": "百慕達",
    //     "phoneCode": "+1441"
    // },
    // {
    //     "countryCode": "DO",
    //     "countryName": "多明尼加",
    //     "phoneCode": "+1767"
    // },
    // {
    //     "countryCode": "GD",
    //     "countryName": "格瑞那達",
    //     "phoneCode": "+1473"
    // },
    // {
    //     "countryCode": "LC",
    //     "countryName": "聖盧西亞",
    //     "phoneCode": "+1758"
    // },
    // {
    //     "countryCode": "CO",
    //     "countryName": "哥倫比亞",
    //     "phoneCode": "+57"
    // },
    // {
    //     "countryCode": "CG",
    //     "countryName": "剛果共和國",
    //     "phoneCode": "+243"
    // },
    // {
    //     "countryCode": "CH",
    //     "countryName": "瑞士",
    //     "phoneCode": "+41"
    // },
    //
    // {
    //     "countryCode": "DK",
    //     "countryName": "丹麥",
    //     "phoneCode": "+45"
    // },
    // {
    //     "countryCode": "EG",
    //     "countryName": "埃及",
    //     "phoneCode": "+20"
    // },
    // {
    //     "countryCode": "ES",
    //     "countryName": "西班牙",
    //     "phoneCode": "+34"
    // },
    // {
    //     "countryCode": "SV",
    //     "countryName": "薩爾瓦多",
    //     "phoneCode": "+503"
    // },
    // {
    //     "countryCode": "FI",
    //     "countryName": "芬蘭",
    //     "phoneCode": "+358"
    // },
    // {
    //     "countryCode": "FJ",
    //     "countryName": "斐濟",
    //     "phoneCode": "+679"
    // },
    //
    // {
    //     "countryCode": "GE",
    //     "countryName": "喬治亞",
    //     "phoneCode": "+995"
    // },
    // {
    //     "countryCode": "GH",
    //     "countryName": "迦納",
    //     "phoneCode": "+233"
    // },
    // {
    //     "countryCode": "GR",
    //     "countryName": "希臘",
    //     "phoneCode": "+30"
    // },
    // {
    //     "countryCode": "GT",
    //     "countryName": "瓜地馬拉",
    //     "phoneCode": "+502"
    // },
    // {
    //     "countryCode": "GY",
    //     "countryName": "蓋亞那",
    //     "phoneCode": "+967"
    // },
    // {
    //     "countryCode": "HT",
    //     "countryName": "海地",
    //     "phoneCode": "+509"
    // },
    // {
    //     "countryCode": "HN",
    //     "countryName": "宏都拉斯",
    //     "phoneCode": "+504"
    // },
    //
    // {
    //     "countryCode": "IN",
    //     "countryName": "印度",
    //     "phoneCode": "+91"
    // },
    // {
    //     "countryCode": "IS",
    //     "countryName": "冰島",
    //     "phoneCode": "+354"
    // },
    // {
    //     "countryCode": "ID",
    //     "countryName": "印尼",
    //     "phoneCode": "+62"
    // },
    // {
    //     "countryCode": "IQ",
    //     "countryName": "伊拉克",
    //     "phoneCode": "+964"
    // },
    // {
    //     "countryCode": "IE",
    //     "countryName": "愛爾蘭",
    //     "phoneCode": "+353"
    // },
    // {
    //     "countryCode": "IT",
    //     "countryName": "義大利",
    //     "phoneCode": "+39"
    // },
    // {
    //     "countryCode": "JM",
    //     "countryName": "牙買加",
    //     "phoneCode": "+1876"
    // },
    // {
    //     "countryCode": "JO",
    //     "countryName": "約旦",
    //     "phoneCode": "+962"
    // },
    // {
    //     "countryCode": "KZ",
    //     "countryName": "哈薩克",
    //     "phoneCode": "+7"
    // },
    // {
    //     "countryCode": "KE",
    //     "countryName": "肯亞",
    //     "phoneCode": "+254"
    // },
    //
    // {
    //     "countryCode": "KW",
    //     "countryName": "科威特",
    //     "phoneCode": "+965"
    // },
    // {
    //     "countryCode": "LU",
    //     "countryName": "盧森堡",
    //     "phoneCode": "+352"
    // },
    //
    // {
    //     "countryCode": "MK",
    //     "countryName": "馬其頓",
    //     "phoneCode": "+389"
    // },
    // {
    //     "countryCode": "MG",
    //     "countryName": "馬達加斯加",
    //     "phoneCode": "+261"
    // },
    // {
    //     "countryCode": "ML",
    //     "countryName": "馬來西亞",
    //     "phoneCode": "+60"
    // },
    // {
    //     "countryCode": "MV",
    //     "countryName": "馬爾地夫",
    //     "phoneCode": "+960"
    // },
    // {
    //     "countryCode": "MX",
    //     "countryName": "墨西哥",
    //     "phoneCode": "+52"
    // },
    // {
    //     "countryCode": "MA",
    //     "countryName": "摩洛哥",
    //     "phoneCode": "+212"
    // },
    // {
    //     "countryCode": "NO",
    //     "countryName": "挪威",
    //     "phoneCode": "+47"
    // },
    // {
    //     "countryCode": "NR",
    //     "countryName": "諾魯",
    //     "phoneCode": "+674"
    // },
    //
    // {
    //     "countryCode": "NI",
    //     "countryName": "尼加拉瓜",
    //     "phoneCode": "+505"
    // },
    // {
    //     "countryCode": "NG",
    //     "countryName": "奈及利亞",
    //     "phoneCode": "+234"
    // },
    // {
    //     "countryCode": "PK",
    //     "countryName": "巴基斯坦",
    //     "phoneCode": "+92"
    // },
    // {
    //     "countryCode": "PA",
    //     "countryName": "巴拿馬",
    //     "phoneCode": "+507"
    // },
    // {
    //     "countryCode": "PG",
    //     "countryName": "巴布亞紐幾內亞",
    //     "phoneCode": "+675"
    // },
    // {
    //     "countryCode": "PT",
    //     "countryName": "葡萄牙",
    //     "phoneCode": "+351"
    // },
    // {
    //     "countryCode": "PY",
    //     "countryName": "巴拉圭",
    //     "phoneCode": "+595"
    // },
    // {
    //     "countryCode": "RO",
    //     "countryName": "羅馬尼亞",
    //     "phoneCode": "+40"
    // },
    // {
    //     "countryCode": "RU",
    //     "countryName": "俄羅斯",
    //     "phoneCode": "+7"
    // },
    // {
    //     "countryCode": "RW",
    //     "countryName": "盧旺達",
    //     "phoneCode": "+250"
    // },
    // {
    //     "countryCode": "SA",
    //     "countryName": "沙烏地阿拉伯",
    //     "phoneCode": "+966"
    // },
    // {
    //     "countryCode": "SY",
    //     "countryName": "敘利亞",
    //     "phoneCode": "+381"
    // },
    // {
    //     "countryCode": "SC",
    //     "countryName": "塞席爾",
    //     "phoneCode": "+248"
    // },
    // {
    //     "countryCode": "LK",
    //     "countryName": "斯里蘭卡",
    //     "phoneCode": "+94"
    // },
    //
    // {
    //     "countryCode": "SD",
    //     "countryName": "蘇丹",
    //     "phoneCode": "+249"
    // },
    // {
    //     "countryCode": "SE",
    //     "countryName": "瑞典",
    //     "phoneCode": "+46"
    // },
    // {
    //     "countryCode": "TL",
    //     "countryName": "泰國",
    //     "phoneCode": "+66"
    // },
    // {
    //     "countryCode": "TO",
    //     "countryName": "湯加群島",
    //     "phoneCode": "+676"
    // },
    // {
    //     "countryCode": "TR",
    //     "countryName": "土耳其",
    //     "phoneCode": "+90"
    // },
    // {
    //     "countryCode": "UG",
    //     "countryName": "烏干達",
    //     "phoneCode": "+256"
    // },
    // {
    //     "countryCode": "UA",
    //     "countryName": "烏克蘭",
    //     "phoneCode": "+380"
    // },
    // {
    //     "countryCode": "AE",
    //     "countryName": "阿拉伯聯合大公國",
    //     "phoneCode": "+971"
    // },
    // {
    //     "countryCode": "UY",
    //     "countryName": "烏拉圭",
    //     "phoneCode": "+598"
    // },
    // {
    //     "countryCode": "UZ",
    //     "countryName": "烏茲別克",
    //     "phoneCode": "+998"
    // },
    // {
    //     "countryCode": "VE",
    //     "countryName": "委內瑞拉",
    //     "phoneCode": "+58"
    // },
    // {
    //     "countryCode": "YE",
    //     "countryName": "葉門",
    //     "phoneCode": "+967"
    // }
];
export const useRegisterFields = () => {
    return [
        {
            id: "scodeEmail",
            nodisplay: true,
            label: "scodeEmail",
            required: false,
            value: null,
        },
        {
            id: "vcodeEmail",
            nodisplay: false,
            label: "vcodeEmail",
            required: false,
            input: {
                props: {
                    type: "email",
                    placeholder: "",
                },
                state: useFormInput("")
            }
        },
        {
            id: "scodePhone",
            nodisplay: true,
            label: "scodePhone",
            required: false,
            value: null,
        },
        {
            id: "vcodePhone",
            nodisplay: false,
            label: "vcodePhone",
            required: false,
            input: {
                props: {
                    type: "email",
                    placeholder: "",
                },
                state: useFormInput("")
            }
        },

        {
            id: "p1",
            label: "電子郵件",
            required: true,
            input: {
                props: {
                    type: "email",
                    placeholder: "電子郵件",
                    autocomplete: "username",
                },
                state: useFormInput("")
            }
        },
        {
            id: "p2",
            label: "密碼",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "密碼",
                    autocomplete: "new-password"
                },
                state: useFormInput("")
            }
        },
        {
            id: "p2_2",
            label: "再一次輸入密碼",
            required: true,
            input: {
                props: {
                    type: "password",
                    placeholder: "再一次輸入密碼",
                },
                state: useFormInput("")
            }
        },
        {
            id: "p3",
            label: "手機國碼",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "手機國碼",

                },
                state: useFormInput("")
            }
        },
        {
            id: "p4",
            label: "手機號碼",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "手機號碼",
                    autocomplete: "tel-local"
                },
                state: useFormInput("")
            }
        },
        {
            id: "p5",
            label: "顯示名稱",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "顯示名稱",
                    autocomplete: "nickname"
                },
                state: useFormInput("")
            }
        },
        {
            id: "p6",
            label: "推薦碼",
            required: false,
            input: {
                props: {
                    type: "text",
                    placeholder: "推薦碼 (選填)",
                },
                state: useFormInput("duck")
            }
        },
    ];
}


const Pg_C_1 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_C_1";
    const pageId = "Pg_C_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    const agreementRef = useRef<any>(null);

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Modal
    const [showModal, setShowModal] = useState<any>(false);
    const [modalOptions, setModalOptions] = useState<any>(false);

    const handleModal = async (index: any) => {
        await setModalOptions(tab1SideMenu[index]);
        setShowModal(true);
    }
    //#endregion

    //#region Data Loading
    const params = useParams();
    const [areaCode, setAreaCode] = useState('');
    const fields = useRegisterFields();
    let keyedFields = indexBy(fields, 'id');

    const [errors, setErrors] = useState(false);
    const [emailVerifyStage, setEmailVerifyStage] = useState<number>(1);
    const [phoneVerifyStage, setPhoneVerifyStage] = useState<number>(1);

    // useEffect(() => {
    //     fetch_pg_0_3({});
    // }, []);
    //
    // let reload = async (event: CustomEvent<RefresherEventDetail>) => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth",
    //     });
    //     await fetch_pg_0_3({});
    //     event.detail.complete();
    // }
    //
    // const pgData = useStoreState(Pg_0_3_Store, getPageData);
    //
    // const hasLoaded = pgData?.Root ?? false;
    // const Item_會員 = hasLoaded ? pgData.Root.Item_會員 : undefined;
    //#endregion

    //#region +Event

    //#region push register
    const push_register = async () => {
        //if (agreementRef.current.value =)

        const errors = validateForm(fields);
        setErrors(errors);

        if (errors.length > 1) {
            alert('欄位沒寫完成，請檢視 (欄位右側有註解缺甚麼)');
            return;
        }

        if (!agreementRef?.current?.checked) {
            alert('需要勾選同意會員條款');
            return;
        }

        if (!errors.length) {
            //  Submit your form here
            let keyedFields = indexBy(fields, 'id');
            //const username = fields && (fields.filter(e => e.id === 'email')[0] as any).input.state.value;
            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;

            //const scodeEmail = fields && (keyedFields.scodeEmail as any).value;
            //const vcodeEmail = fields && (keyedFields.vcodeEmail as any).value;
            //const scodePhone = fields && (keyedFields.scodePhone as any).value;
            //const vcodePhone = fields && (keyedFields.vcodePhone as any).value;
            const p2 = fields && (keyedFields.p2 as any).input.state.value;
            const p2_2 = fields && (keyedFields.p2_2 as any).input.state.value;
            if (p2 !== p2_2) {
                alert('前後輸入的密碼不一致');
                return;
            }

            let {email, scodeEmail, vcodeEmail} = Pg_B_1_Store.getRawState() as any;
            let {phone, scodePhone, vcodePhone} = Pg_C_4_Store.getRawState() as any;

            if (emailVerifyStage !== 4) {
                alert("請先完成Email驗證流程，再按下註冊按鈕");
                return;
            }
            if (phoneVerifyStage !== 4) {
                alert("請先完成手機驗證流程，再按下註冊按鈕");
                return;
            }

            const p1 = fields && (keyedFields.p1 as any).input.state.value;
            // if (p1 !== email) {
            //     alert("填寫的Email與驗證的Email不同，請重新填寫");
            //     return;
            // }

            const p3 = fields && (keyedFields.p3 as any).input.state.value;
            const p4 = fields && (keyedFields.p4 as any).input.state.value;
            const p5 = fields && (keyedFields.p5 as any).input.state.value;
            const p6 = fields && (keyedFields.p6 as any).input.state.value;

            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;
            //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;

            try {



                if (await push_pg_C_1({
                    scodeEmail,
                    vcodeEmail,
                    scodePhone,
                    vcodePhone,
                    p1,
                    p2,
                    p3,
                    p4,
                    p5,
                    p6
                })) {
                    alert('謝謝註冊，現在請輸入剛註冊的帳密登入');
                    navToPg_A_1(router);
                }
            } catch (err) {

            }
        }
    }

    const _push_Pg_C_4__SendPhoneVerify = async () => {
        const p3 = fields && (keyedFields.p3 as any).input.state.value;
        const p4 = fields && (keyedFields.p4 as any).input.state.value;

        if (`${p3}` == '') {
            alert('國際碼請先選擇');
            return;
        }
        if (`${p4}` == '') {
            alert('手機號碼未填寫');
            return;
        }

        setPhoneVerifyStage(2);
        try {
            if (await push_pg_C_4_sendphoneverify({
                p1: `${p3}${p4}`,
            })) {
                let {phone, scodePhone} = Pg_C_4_Store.getRawState() as any;
                setPhoneVerifyStage(3);
                //navToPg_1_1();
            }
        } catch (err) {
            setPhoneVerifyStage(1);
        }
    }

    const _push_Pg_C_4__PhoneVerify = async () => {
        const p3 = fields && (keyedFields.p3 as any).input.state.value;
        const p4 = fields && (keyedFields.p4 as any).input.state.value;
        let {phone, scodePhone} = Pg_C_4_Store.getRawState() as any;
        const vcodePhone = fields && (keyedFields.vcodePhone as any).input.state.value;

        if (!vcodePhone) {
            alert('請填入驗證碼');
            return;
        }
        if (phone !== `${p3}${p4}`) {
            alert('手機號碼已被修改，請重新驗證');
            setPhoneVerifyStage(1);
            return;
        }
        //setPhoneVerifyStage(3);
        // setPhoneVerifyStage(2);
        if (await push_pg_C_4_phoneverify({
            p1: phone,
            scodePhone: scodePhone,
            vcodePhone: vcodePhone,
        })) {
            setPhoneVerifyStage(4);
            //navToPg_1_1();
        }
    }

    const _push_Pg_B_1__SendEmailVerify = async () => {
        const p1 = fields && (keyedFields.p1 as any).input.state.value;

        if (`${p1}` == '') {
            alert('電子郵件未填寫');
            return;
        }

        setEmailVerifyStage(2);
        try {
            if (await push_pg_B_1_sendemailverify({
                p1: `${p1}`,
            })) {
                let {email, scodeEmail} = Pg_B_1_Store.getRawState() as any;
                setEmailVerifyStage(3);
                //navToPg_1_1();
            }
        } catch (err) {
            setEmailVerifyStage(1);
        }
    }

    const _push_Pg_B_1__EmailVerify = async () => {
        const p1 = fields && (keyedFields.p1 as any).input.state.value;
        let {email, scodeEmail} = Pg_B_1_Store.getRawState() as any;
        const vcodeEmail = fields && (keyedFields.vcodeEmail as any).input.state.value;

        if (!vcodeEmail) {
            alert('請填入驗證碼');
            return;
        }
        if (email !== p1) {
            alert('電子郵件已被修改，請重新驗證');
            setEmailVerifyStage(1);
            return;
        }
        //setPhoneVerifyStage(3);
        // setPhoneVerifyStage(2);
        if (await push_pg_B_1_emailverify({
            p1: email,
            scodeEmail: scodeEmail,
            vcodeEmail: vcodeEmail,
        })) {
            setEmailVerifyStage(4);
            //navToPg_1_1();
        }
    }
    //#endregion

    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} showLargeHeader={false} sideMenu={false} backButton={true}>
                {/*<PageHeader count={sideMenuOptions.length} pageName={pageName}/>*/}

                <IonGrid className="ion-padding ion-margin-top ion-padding-top">
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p1} errors={errors} disabled={emailVerifyStage !== 1}/>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                        <IonCol>
                            {emailVerifyStage === 1 &&
                                <>
                                    <IonButton fill={"solid"} onClick={() => _push_Pg_B_1__SendEmailVerify()} disabled={emailVerifyStage !== 1}>
                                        驗證電子郵件
                                    </IonButton>
                                </>
                            }
                            {emailVerifyStage === 2 &&
                                <>
                                    寄件中，請稍後...
                                </>
                            }
                            {emailVerifyStage === 3 &&
                                <>
                                    <CustomField alternateLabel={<>
                                        <IonLabel>
                                            請檢查郵件，並填入驗證碼
                                        </IonLabel>
                                    </>} alternateRightZone={<>
                                        <IonButton fill={"solid"} onClick={() => _push_Pg_B_1__EmailVerify()} className={styles_Pg_C_1.sideRight}>
                                            驗證
                                        </IonButton>
                                    </>
                                    } field={keyedFields.vcodeEmail} errors={errors} className={styles_Pg_C_1.highlight} inputClassName={styles_Pg_C_1.hightlightColor}/>
                                </>
                            }
                            {emailVerifyStage === 4 &&
                                <>
                                    驗證完成
                                </>
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p2} errors={errors}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p2_2} errors={errors}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {/*<CustomField field={keyedFields.p3} errors={errors}/>*/}
                            <CustomField alternateLeftZone={<>
                                <IonSelect interfaceOptions={customSelectContainerOptions} interface="action-sheet" placeholder="+國際碼" disabled={phoneVerifyStage !== 1} onIonChange={(ev) => {
                                    setAreaCode(JSON.stringify(ev.detail.value))
                                    keyedFields.p3.input.state.reset(ev.detail.value);
                                }}
                                           multiple={false} className={styles_Pg_C_1.select}>
                                    {areaCodeList.map((v) => {
                                        return <IonSelectOption key={v.countryName} value={v.phoneCode}>{v.countryName} {v.phoneCode}</IonSelectOption>
                                    })}
                                </IonSelect>
                            </>} field={keyedFields.p4} errors={errors} noLabel={false} disabled={phoneVerifyStage !== 1}/>
                        </IonCol>
                    </IonRow>
                    {/*<IonRow>*/}
                    {/*    <IonCol>*/}

                    {/*    </IonCol>*/}
                    {/*</IonRow>*/}
                    <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                        <IonCol>
                            {phoneVerifyStage === 1 &&
                                <>
                                    <IonButton fill={"solid"} onClick={() => _push_Pg_C_4__SendPhoneVerify()} disabled={phoneVerifyStage !== 1 ? true : false}>
                                        驗證手機
                                    </IonButton>
                                </>
                            }
                            {phoneVerifyStage === 2 &&
                                <>
                                    簡訊中，請稍後...
                                </>
                            }
                            {phoneVerifyStage === 3 &&
                                <>
                                    <CustomField alternateLabel={<>
                                        <IonLabel>
                                            請檢查簡訊，並填入驗證碼
                                        </IonLabel>
                                    </>} alternateRightZone={<>
                                        <IonButton fill={"solid"} onClick={() => _push_Pg_C_4__PhoneVerify()} className={styles_Pg_C_1.sideRight}>
                                            驗證
                                        </IonButton>
                                    </>
                                    } field={keyedFields.vcodePhone} errors={errors} className={styles_Pg_C_1.highlight} inputClassName={styles_Pg_C_1.hightlightColor}/>
                                </>
                            }
                            {phoneVerifyStage === 4 &&
                                <>
                                    驗證完成
                                </>
                            }
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p5} errors={errors}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <CustomField field={keyedFields.p6} errors={errors}/>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            服務條款
                            <IonTextarea className={styles_Pg_C_1.agreement} value={
                                String.raw`
LABIE.io 服務條款
 
本服務條款及條件（以下簡稱「本條款」）規範LABIE.io（以下簡稱「LABIE」）提供所有LABIE相關產品與服務（以下簡稱「本服務」）給本服務的用戶（以下簡稱「用戶」）使用時所適用的條款及條件。
 
1. 定義
本條款使用下列各項用語：
1.1. 「內容」係指文字、語音、音樂、圖片、影片、軟體、程式、代碼及其他資訊等內容。
1.2. 「本內容」係指可透過本服務連結的內容。
1.3. 「社群貼文」係指用戶投稿、傳送、上傳至本服務的內容。
1.4. 「寶石」係指可兌換本服務中有償提供的服務或內容的支付換算工具等。
1.5. 「個別使用條款」係指與本服務有關但有別於本條款，以「條款」、「準則」、「政策」等名稱由LABIE所發布或上傳的條款及條件。
 
2. 對於本條款的同意表示
2.1. 用戶應遵照本條款規定使用本服務。除非用戶同意本條款，否則不得使用本服務。
2.2. 如用戶為未成年人，須經過親權人等法定代理人同意後，方能使用本服務。此外，如用戶代表業者使用本服務時，亦請於該業者同意本條款後，再使用本服務。
2.3. 本服務於有適用之個別使用條款時，除本條款外，用戶亦應遵照個別使用條款規定使用本服務。
 
3. 條款的變更
LABIE得於必要時，依據相關法律或法規，於本服務目的範圍內變更本條款。若有相關變更，LABIE將於本服務或LABIE網站公告本條款之變更內容及生效日期，或以LABIE決定的其他方式通知用戶。
本條款之變更將於其生效日當天生效。
 
4. 帳號
4.1. 用戶於使用本服務時可能需要註冊特定資料以設立帳戶。用戶應提供真實、正確且完整的資料，並應進行修改以隨時提供更新的資料。
4.2. 用戶如於使用本服務時註冊任何驗證資訊，應自行負責謹慎保管該等資訊，以免遭到不當使用。當用戶於註冊時提交的驗證資訊被用於使用本服務時，LABIE將推定該使用係由提交驗證資訊之用戶本人所為，並據此向其提供本服務。
4.3. 於本服務註冊的用戶，得隨時刪除帳號終止本服務。
4.4. LABIE得在不事先通知用戶的情形下，刪除自最後一次連線起已經過一年以上的帳號。
4.5. 自帳號刪除時起，用戶於本服務的所有使用權不論任何理由均為消滅。即便是用戶不慎刪除帳號，原有的帳號亦無法回復，敬請留意。
4.6. 本服務的帳號專屬於用戶個人。用戶於本服務的所有使用權皆不得轉讓、出借或以其他方式授予第三人或使第三人繼承或繼受。
 
5. 隱私權
5.1. LABIE尊重用戶的隱私權。
5.2. LABIE依照LABIE 隱私權政策，妥善處理用戶的隱私資料與個人資料。
5.3. LABIE將會以安全的方式管理自用戶處蒐集的資料，並對安全管理採取嚴格的措施。
 
6. 服務的提供
6.1. 用戶就本服務的使用，應自費及負責備置必要的個人電腦、行動裝置、智慧型手機及其他通信設備、作業系統、通信方式及電力等。
6.2. LABIE得按用戶年齡及身分、有無註冊資料、及其他LABIE判斷為必要的條件，將本服務的全部或部分提供給符合上揭條件的用戶。
6.3.  LABIE保留權利於認為有必要時，得不事先通知用戶，隨時自行決定變更本服務的全部或部分內容。
6.4. 於下列情況下，LABIE得不事先通知用戶，中止提供本服務之全部或一部：
(1) 執行系統維護或維修；
(2) 本服務因不可抗力事件，如意外事件（火災、停電等）、天災、戰爭、暴動、勞資糾紛、公司停業等，而無法提供；
(3) 系統故障或系統過載；
(4) 為確保用戶或第三人安全，或於緊急情況下為維護公眾利益；或
(5) 除上列第(1)至(4)款所載情事外，於LABIE合理認為有必要時。
 
7. 無緊急通報功能
本服務並未搭載能向警察機關、海岸巡防機關、消防機關或其他機關等發出緊急通報的功能。
 
8. 廣告刊登
LABIE得在本服務刊登LABIE或第三人的廣告。
 
9. 第三方服務
本服務內可能包含第三方業者所提供的服務或內容。第三方業者應為其提供的服務及/或內容負責。此外，第三方業者所規定的使用條款及其他條件，可能適用於該等服務或內容。
 
10. 內容
10.1. LABIE就LABIE所提供的本內容授予用戶使用權，此使用權不得轉讓、不得轉授權，且不具有專屬性，並以使用本服務為唯一目的。
10.2. 如果用戶使用的本內容另有規定使用費、使用期間等使用條件時，用戶即應遵照該等使用條件。即使本服務介面上有顯示「購買」、「販賣」等文字，LABIE對客戶提供的本內容相關智慧財產權及其他權利亦不因此移轉給用戶，對用戶僅有授予上述使用權。
10.3. 用戶不得超出本服務所訂定的使用樣態而使用（包括但不限於複製、傳輸、重製和修改等行為）本內容。
10.4. 請用戶自行備份社群貼文的全部或一部。LABIE並無義務備份任何社群貼文。
10.5. 本服務可能具備可使多位用戶編輯內容（投稿、修改、刪除等）的功能。在此情形下，用戶同意其他用戶可編輯該用戶自行投稿的內容。
10.6. 用戶對社群貼文應有的權利維持不變，LABIE並未取得相關權利。但若用戶向其他用戶普遍公開社群貼文（亦即，對其他不在社群內的用戶亦公開社群貼文），用戶將被視為授權LABIE得將該社群貼文用於提供服務和促銷目的（包括LABIE於認有必要且適當的範圍內，加以省略等變更的權利，以及將相關使用權轉授權給與LABIE有業務合作的第三人的權利）。
10.7. 用戶不得於LABIE或第三人依據上述第10.5條及第10.6條使用社群貼文時行使著作人格權。
10.8. 如LABIE認為有必要確認有無違反法令或本條款規定等情形時，得於法律及法規允許的範圍內，對社群貼文進行確認。但此不代表LABIE有義務進行該等確認。
10.9. 如LABIE認為用戶社群貼文違反法令或本條款規定或有違反之虞，或有其他業務上需求時，得不事先通知用戶，以刪除或禁止社群貼文、將用戶停權等方式限制本服務或社群貼文的使用。
 
11. 寶石
11.1. 寶石透過在本服務購買時的換算工具，或以LABIE指定的其他方式提供給用戶。寶石的購買單位、付款方式、其他寶石提供條件等，由LABIE訂定並顯示在本服務中或LABIE網站。
11.2. 除LABIE所指定服務或內容外，寶石無法兌換現金、財物或其他經濟利益。兌換服務或內容所需要的寶石數額及其他寶石使用條件，由LABIE訂定並標註於本服務中或LABIE網站。
11.3. 不論任何理由，寶石概不退還。但法令規定有必要者，則不在此限。於此情形下，寶石返還方式將依照法令規定由LABIE訂定，並顯示在LABIE網站等處。
 
12. 訂閱服務之提供
12.1. 用戶得依據LABIE訂定的條款及條件付費換取服務，用戶於支付特定金額款項做為對價後，即可於特定期間內使用LABIE指定的特定內容（以下簡稱「訂閱服務」）。用戶應遵守本條款、訂閱服務的費用和付款方式，以及刊載於本服務或LABIE網站的其他使用條款及條件。
12.2. 用戶得隨時採取相關程序取消訂閱服務，但即使用戶於使用期間開始前取消訂閱服務，用戶仍可能無法變更使用期間或取消購買訂閱服務。於此情況下，已支付的費用將無法全額或按比例退款。
12.3. 若用戶未於特定日期及期間前完成取消程序，訂閱服務的使用期間可能依據LABIE訂定的條款自動展延，即使在訂閱服務的使用期間屆滿後亦然。
 
13. 禁止事項
用戶於使用本服務時不得從事下列所述行為：
13.1. 違反法令、法院判決、裁定或命令或具有法令拘束力的行政措施的行為；
13.2. 恐有危害公共秩序或善良風俗的行為；
13.3. 侵害著作權、商標權、專利權等智慧財產權、名譽權、隱私權、其他依據法令或與LABIE及/或第三人合約規定的權利的行為；
13.4. 投稿或傳送內容有過度暴力的內容、性暗示過度露骨的內容、構成兒童色情或兒童虐待的內容、涉及種族、國籍、信仰、性別、社會地位、家世等有關歧視的內容、引誘或助長自殺、自傷行為或濫用藥物的內容、其他包含反社會情節在內等使人感到不愉快內容的行為；
13.5. 偽裝成LABIE及/或第三人的行為或故意散布不實資訊的行為；
13.6. 傳送相同或類似訊息給大量不特定用戶的行為（經LABIE同意者除外）、隨機將其他用戶加入為私人社群的行為、及其他LABIE判斷為構成垃圾訊息的行為；
13.7. 藉由LABIE規定以外的方式，以本內容及本服務的使用權兌換現金、財物或其他經濟利益的行為；
13.8. 以行銷、宣傳、廣告、招攬或其他營利為目的之行為（經LABIE同意者除外）、以性行為或猥褻行為為目的之行為、以與未曾謀面的第三人認識或發生性行為為目的之行為、以騷擾或毀謗中傷其他用戶為目的之行為、其他基於與本服務訂定的使用目的不同之目的使用本服務的行為；
13.9. 對反社會勢力提供利益或其他協助的行為；
13.10. 勸誘參與宗教活動或宗教團體的行為；
13.11. 未經授權或不當蒐集、公開或提供他人的個人資料、註冊資料、使用記錄資料等的行為；
13.12. 干擾本服務的伺服器或網路系統的行為、利用網路機器人（bots）、作弊工具、其他技術性手段不當操作服務的行為、故意利用本服務漏洞的行為、超出必要限度重複同一問題等對LABIE提出不當洽詢或要求的行為、其他妨礙LABIE經營本服務或其他用戶使用本服務，並製造干擾的行為；
13.13. 為達成不合理目的或透過不公平方式，透過逆向工程、反編譯或其他方式對本服務之原始碼進行解碼；
13.14. 支援或助長符合前述第13.1.項至第13.13項任一行為的行為；以及
13.15. 前述第13.1.項至第13.14項所述行為外，其他LABIE合理判斷為不適當的行為。
 
14. 用戶責任
14.1. 用戶應自行承擔使用本服務之風險與責任。
14.2. 若用戶發生或可能發生下列情事，LABIE得不事先通知用戶，即可中止該用戶使用本服務之全部或一部，停用或刪除帳戶、取消用戶與LABIE之間關於本服務的合約（包括但不限於依據本條款成立的合約，以下皆同），或採取LABIE合理認為必要及適當的任何其他措施：
(1) 用戶違反相關法令、本條款或任何個別條款；
(2) 用戶為反社會勢力或相關黨派成員；
(3) 用戶透過散播不實資訊、利用詐欺方式或勢力，或透過其他不法方式破壞LABIE的信譽；
(4) 用戶遭聲請被扣押、假扣押、拍賣、進入破產、民事重整或類似程序，或LABIE合理認為用戶的信用有不確定性；或
(5) 用戶與LABIE之間的信任關係已不存在，或因上列第(1)款至第(4)款以外事由，使LABIE合理認為LABIE不再適合向用戶提供本服務。
14.3. 起因於用戶使用本服務（包括但不限於LABIE收到第三人對於用戶使用本服務所提出的申訴），致LABIE直接或間接蒙受任何損失/損害（包括但不限於律師費用的負擔）時，用戶應依照LABIE依循相關法律及法規所提出的要求立即給予補償。
 
15. 無保證
LABIE不就本服務（包括本內容）作出任何無瑕疵（包括但不限於關於安全性、誤差、錯誤、程式錯誤或侵權）、安全性、可靠性、正確性、完整性、有效性或特定目的之適用性的明示或默示保證。無論如何，LABIE無須負責將該等缺失刪除後再向用戶提供本服務。
 
16. 有限責任
16.1. 用戶若因使用本服務而遭受任何損害，除非該損害係因可歸責於LABIE的故意或重大過失所致，LABIE概不負責。但若用戶與LABIE之間關於本服務的合約屬於日本消費者合約法定義的消費者合約（下稱「消費者合約」），LABIE應就任何因LABIE過失（不含重大過失）而產生的合約或侵權損害提供賠償，惟僅限於：(A) 正常產生的損害（亦即，不包括利潤損失及其他因特殊情事而產生的損害）；且 (B) 賠償金額最高僅限於用戶於發生損害之當月，就付費服務而向LABIE支付的服務使用費。
16.2. 若用戶因LABIE的重大過失而產生損害，LABIE將負責提供賠償，但僅限於：(A) 正常產生的損害（亦即，不包括利潤損失及其他因特殊情事而產生的損害）；且 (B) 賠償金額最高僅限於用戶於發生損害之當月，就付費服務而向LABIE支付的服務使用費。
 
17. 本條款及法令間的關係
若本條款違反用戶與LABIE間就本服務成立的相關合約所適用的相關法令，該違法的條款即不應適用於該等用戶合約，但本條款其餘部分不受影響。
 
18. 聯絡方式
18.1. LABIE向用戶發送本服務相關通知，將以本服務或LABIE網站內的適當處公布或其他LABIE判斷為適當的方式進行。
18.2. 用戶向LABIE發送本服務相關通知，請以本服務或LABIE網站內的適當處所設置的問題反應表傳送或依LABIE指定方式進行。
 
19. 準據法、管轄法院
本條款的準據法為中華民國法律。用戶與LABIE間所發生起因於本服務或與本服務有關連的紛爭，以台北士林地方法院為第一審專屬合意管轄法院。
 
以上
 
最後更新日期：2023年2月1日
                                `
                            }>
                            </IonTextarea>

                            <IonItem>
                                <IonCheckbox slot="start" ref={agreementRef}></IonCheckbox>
                                <IonLabel>我已詳讀，並同意以上會員條款</IonLabel>
                            </IonItem>
                            <IonItem>
                                <div className={styles_Pg_C_1.topBottomMargin}>目前只開放中港澳台註冊，若您無法順利註冊，請以您的手機發簡訊到+886988113071，並註明（LABIE註冊/您的大名/您的手機/您的Email/推薦碼 - 非必要），我們會幫您開通臨時帳號，並Email給您登入方式。</div>
                            </IonItem>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top ion-padding-top">
                        <IonCol size="12">
                            <IonButton className="custom-button" expand="block" onClick={() => push_register()} >註冊</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                {/*{(showModal && modalOptions) &&*/}
                {/*    <Modal showModal={showModal} modalOptions={modalOptions} close={() => setShowModal(false)}/>*/}
                {/*}*/}
            </CustomPage>

            {/*<IonFooter>*/}
            {/*    <IonGrid className="ion-no-margin ion-no-padding">*/}
            {/*        <Action message="Don't have an account?" text="Sign up" link="/signup"/>*/}
            {/*        <IconWave/>*/}
            {/*    </IonGrid>*/}
            {/*</IonFooter>*/}
        </IonPage>
    );
};

export default Pg_C_1;
