import React, {Component} from 'react';
import {useState, useEffect} from 'react';

var moment = require('moment');


const msOneMinute = 60 * 1000;
const msOneHour = 60 * 60 * 1000;
const msTwentyFourHours = 60 * 60 * 24 * 1000;

export const fillZero = n => (n < 10 ? `0${n}` : `${n}`);

export const setTimeToPHPTimestamp = (ts: number) => {
    return (ts - (ts % 1000)) / 1000;
};

export const getMonthDateYearAndHourMinuteFromPHPTimestamp = ts => {
    const d = new Date(ts * 1000);

    return `${fillZero(d.getMonth() + 1)}.${fillZero(d.getDate())}.${d.getFullYear()} ${fillZero(
        d.getHours()
    )}:${fillZero(d.getMinutes())}`;
};

export const getMonthDateYearByDateString = (date: Date, joinBy = '.', digitsOfYear: number = 0) => {
    const d = new Date(date);

    return `${fillZero(d.getMonth() + 1)}${joinBy}${fillZero(d.getDate())}${joinBy}${`${d.getFullYear()}`.slice(
        digitsOfYear
    )}`;
};

export const getStartEndTimeByRange = range => {
    const now = Date.now();
    let startAt;

    switch (range) {
        case '1m':
            startAt = getMonthsAgoTimestamp(now, 1);
            break;
        case '3m':
            startAt = getMonthsAgoTimestamp(now, 3);
            break;
        case '6m':
            startAt = getMonthsAgoTimestamp(now, 6);
            break;
        case '1y':
            startAt = getYearsAgoTimestamp(now, 1);
            break;
        case '5y':
            startAt = getYearsAgoTimestamp(now, 5);
            break;
        default:
            startAt = getYearsAgoTimestamp(now, 1);
    }

    return {
        endAt: toPHPTimestamp(now),
        startAt: toPHPTimestamp(startAt),
    };
};

export const getYearsAgoTimestamp = (now, numberOfYears, isUtc = false) => {
    if (now && numberOfYears) {
        const d = new Date(now);
        let setFullYear = 'setFullYear';
        let getFullYear = 'getFullYear';

        if (isUtc) {
            setFullYear = 'setUTCFullYear';
            getFullYear = 'getUTCFullYear';
        }

        return d[setFullYear](d[getFullYear]() - numberOfYears);
    }

    return now;
};

export const getMonthsAgoTimestamp = (now, numberOfMonths, UTC = '') => {
    if (now && numberOfMonths) {
        const d = new Date(now);

        let setMonth = 'setMonth';
        let getMonth = 'getMonth';

        if (UTC === 'UTC') {
            setMonth = 'setUTCMonth';
            getMonth = 'getUTCMonth';
        }

        return d[setMonth](d[getMonth]() - numberOfMonths);
    }

    return now;
};

export const toPHPTimestamp = (ts: number) => {
    return Math.floor(ts / 1000);
};

export const getMonthDateYearFromPHPTimestamp = ts => {
    const d = new Date(ts * 1000);

    return `${fillZero(d.getMonth() + 1)}.${fillZero(d.getDate())}.${d.getFullYear()}`;
};

export const getHourMinuteFromPHPTimestamp = ts => {
    const d = new Date(ts * 1000);

    return `${fillZero(d.getHours())}:${fillZero(d.getMinutes())}`;
};

export const getYearMonthDate = ts => {
    const d = new Date(ts);

    return `${d.getFullYear()}/${fillZero(d.getMonth() + 1)}/${fillZero(d.getDate())}`;
};

export const postTimeDisplayFactory = (timestampInMs: number, now: number) => {
    if (!timestampInMs) {
        return {};
    }
    const d = new Date(timestampInMs);
    const nowDate = new Date(now);

    const diff = +nowDate - +d;
    const todayStartInTimestamp = new Date(nowDate).setHours(0, 0, 0, 0);
    const tomorrowStartInTimestamp = todayStartInTimestamp + msTwentyFourHours;

    // < 1 min: 剛剛
    if (diff < msOneMinute) {
        return {
            display: '剛剛',
            delay: msOneMinute - diff,
        };
    }

    // > 1 min: %s分鐘
    if (diff < msOneHour) {
        return {
            display: `${Math.floor(diff / msOneMinute)}分鐘前`,
            delay: msOneHour - diff,
        };
    }

    // 當日
    if (+todayStartInTimestamp < +d) {
        return {
            display: `${Math.floor(diff / msOneHour)}小時`,
            delay: tomorrowStartInTimestamp - +nowDate,
        };
    }

    // 昨天: 昨天 HH:mm
    if (+d > +todayStartInTimestamp - msTwentyFourHours) {
        return {
            display: `昨天 ${fillZero(d.getHours())}:${fillZero(d.getMinutes())}`,
            delay: tomorrowStartInTimestamp - +nowDate,
        };
    }

    // 前天: MM/dd HH:mm
    if (+d > +todayStartInTimestamp - msTwentyFourHours * 2) {
        return {
            display: `前天 ${fillZero(d.getHours())}:${fillZero(d.getMinutes())}`,
            delay: tomorrowStartInTimestamp - +nowDate,
        };
    }

    // else: yyyy/MM/dd
    return {
        display: `${d.getFullYear()}/${fillZero(d.getMonth() + 1)}/${fillZero(d.getDate())}`,
        delay: 0,
    };
};

export const getYearMonthDateFromPHPTimestamp = ts => {
    const d = new Date(ts * 1000);

    return `${d.getFullYear()}/${fillZero(d.getMonth() + 1)}/${fillZero(d.getDate())}`;
};


type Props = {
    now?: number;
    msTimestamp: number; //顯示時間
};

export default function Ln_PostTime(props: Props) {
    const [now, setNow] = useState(props.now || Date.now());

    const {display, delay} = postTimeDisplayFactory(props.msTimestamp, now);


    const isValidInput = moment(props.msTimestamp).isValid();

    if (!isValidInput) {
        return null;  //if invalid date, all do nothing
    }


    return (
        <React.Fragment>
            <span><time dateTime={moment(props.msTimestamp).format('YYYY/MM/DD hh:mm:ss A')}>{display}</time></span>
        </React.Fragment>
    );
}

Ln_PostTime.displayName = 'Ln_PostTime';
