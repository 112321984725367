// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pg_0_2_avatarContainer__MlBoN {\n  text-align: center;\n  margin-top: 2em; }\n  .Pg_0_2_avatarContainer__MlBoN .avatar {\n    display: inline-block;\n    aspect-ratio: 1;\n    width: 40%;\n    height: 40%;\n    max-width: 200px;\n    max-height: 200px; }\n  .Pg_0_2_avatarContainer__MlBoN .name {\n    display: inline-block; }\n\n.Pg_0_2_memberQrBar__2t7ss {\n  margin-top: 16px;\n  display: inline-block;\n  text-align: center;\n  width: 100%; }\n  .Pg_0_2_memberQrBar__2t7ss .l {\n    font-size: 24px;\n    margin: 16px 0; }\n", "",{"version":3,"sources":["webpack://./src/pages/Pg_0_2.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAkB;EAClB,eAAe,EAAA;EAFjB;IAKI,qBAAqB;IACrB,eAAe;IAEf,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,iBAAiB,EAAA;EAXrB;IAeI,qBAAoB,EAAA;;AAKxB;EACE,gBAAgB;EAEhB,qBAAqB;EACrB,kBAAkB;EAClB,WAAW,EAAA;EALb;IAQI,eAAe;IACf,cAAc,EAAA","sourcesContent":["\r\n.avatarContainer {\r\n  text-align: center;\r\n  margin-top: 2em;\r\n\r\n  :global(.avatar) {\r\n    display: inline-block;\r\n    aspect-ratio: 1;\r\n\r\n    width: 40%;\r\n    height: 40%;\r\n    max-width: 200px;\r\n    max-height: 200px;\r\n  }\r\n\r\n  :global(.name) {\r\n    display:inline-block;\r\n  }\r\n}\r\n\r\n\r\n.memberQrBar {\r\n  margin-top: 16px;\r\n\r\n  display: inline-block;\r\n  text-align: center;\r\n  width: 100%;\r\n\r\n  :global(.l) {\r\n    font-size: 24px;\r\n    margin: 16px 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarContainer": "Pg_0_2_avatarContainer__MlBoN",
	"memberQrBar": "Pg_0_2_memberQrBar__2t7ss"
};
export default ___CSS_LOADER_EXPORT___;
