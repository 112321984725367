// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pg_2_3_viewerframe__\\+oV1T {\n  width: 100%;\n  height: 50vh;\n  border: 0;\n  margin: 0; }\n\n.Pg_2_3_content__5s0YZ iframe {\n  margin-left: auto;\n  margin-right: auto;\n  display: block; }\n", "",{"version":3,"sources":["webpack://./src/pages/Pg_2_3.module.scss"],"names":[],"mappings":"AACA;EACE,WAAU;EACV,YAAY;EACZ,SAAS;EACT,SAAS,EAAA;;AAIX;EAGI,iBAAiB;EACjB,kBAAkB;EAClB,cAAc,EAAA","sourcesContent":["\r\n.viewerframe {\r\n  width:100%;\r\n  height: 50vh;\r\n  border: 0;\r\n  margin: 0;\r\n}\r\n\r\n\r\n.content {\r\n\r\n  :global(iframe) {\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    display: block;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewerframe": "Pg_2_3_viewerframe__+oV1T",
	"content": "Pg_2_3_content__5s0YZ"
};
export default ___CSS_LOADER_EXPORT___;
