import {IonBadge, IonButton, IonCol, IonIcon, IonNote, IonRouterLink, useIonRouter} from "@ionic/react";
import {bookmark, bookmarkOutline, checkmarkOutline, star, trash, trashOutline} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";
import styles from "./TokenStackSelfItem.module.scss";
import {push_pg_5_5__CancelEntrust, push_pg_5_7__AddEntrustBookmark} from "../store/Pg_5_Store";
import {handleFetchCommonErrors} from "../pages/Utils";
import {useStoreState} from "pullstate";
import {UserTokenEntrustStore} from "../store/Common_Store";
import {getPageData} from "../store/Selectors";


const _push_pg_5_5__CancelEntrust = async (router, tokenEnwEntrustId, reload) => {
    let errorObj = (await push_pg_5_5__CancelEntrust({
        p1: tokenEnwEntrustId,
    }));
    handleFetchCommonErrors(router, errorObj);
    if (!errorObj) {
        alert('取消委託成功');
        reload(null);
        //navToPg_A_1(router);
    } else {
    }
}

export const TokenStackSelfItem = ({Item_Token, reload, fromHome = false}) => {
    const router = useIonRouter();

    let Item_委託狀態: any = Item_Token?._委託狀態;
    if (!Item_委託狀態) {
        return <>(ERROR:Item_委託狀態)</>;
    }

    return (
        <ActionLink link={`/tabs/5_2/${Item_Token.TokenId}`}>
            <div className={styles.container}>
                <div className={styles.coverImg}>
                    {Ln_TokenPictureSetCoverImg({
                        圖片組: Item_Token.圖片組,
                        名稱: 'cover',
                    })}
                </div>

                <div className={`${styles.productTitleContainer}`}>
                    <div>
                        <h1 className={`${styles.productTitle} truncate`}>{Item_Token.名稱}</h1>
                    </div>

                    <div className="last">
                        <div className="line"/>
                        <div className="lowestPrice">
                            委託價格: {Item_委託狀態.委託價格.toFixed(0)}
                        </div>
                        <div className="cancel">
                            <IonButton fill={"solid"} expand="block" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                _push_pg_5_5__CancelEntrust(router, Item_委託狀態.TokenEnwEntrustId, reload);

                                return false;
                            }}>
                                <IonIcon icon={trashOutline}/>
                                &nbsp;取消委託
                            </IonButton>

                        </div>
                    </div>

                </div>
            </div>
        </ActionLink>
    );
}
