import { heartOutline, homeOutline, shirtOutline } from "ionicons/icons";

import Pg_1_1 from "./Pg_1_1";
import Pg_2_1 from "./Pg_2_1";
import Pg_3_1 from "./Pg_3_1";
import Pg_4_2 from "./Pg_4_2";
import Pg_5_1 from "./Pg_5_1";

export const pages = [
  {
    href: "/1_1",
    icon: shirtOutline,
    component: Pg_1_1,
    default: true,
    isTab: true,
    text: "新品商店",
  },
  {
    href: "/2_1",
    icon: shirtOutline,
    component: Pg_2_1,
    default: false,
    isTab: true,
    text: "個人收藏",
  },
  {
    href: "/3_1",
    icon: shirtOutline,
    component: Pg_3_1,
    default: false,
    isTab: true,
    text: "社群貼文",
  },
  {
    href: "/4_1",
    icon: shirtOutline,
    component: Pg_4_2,
    default: false,
    isTab: true,
    text: "領取獎勵",
  },
  {
    href: "/5_1",
    icon: shirtOutline,
    component: Pg_5_1,
    default: false,
    isTab: true,
    text: "交易市集",
  },
];
