import {IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_2_1, fetch_pg_2_13, fetch_pg_2_3, Pg_2_13_Store, Pg_2_3_Store} from "../store/Pg_2_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {chevronDownCircleOutline, copyOutline, exitOutline, heartOutline} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import {Simulate} from "react-dom/test-utils";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {useAppUtil, useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {useLoginFields} from "./Pg_A_1";
import {validateForm} from "../_/useForms";
import indexBy from "underscore/modules/indexBy";
import styles_TokenItem from "../components/TokenItem.module.scss";
import styles_2_13 from './Pg_2_13.module.scss';
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleFetchCommonErrors} from "./Utils";
import {push_pg_A_1__Login} from "../store/Pg_A_Store";

const Pg_2_13 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_2_13";
    const pageId = "Pg_2_13";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_2_13();
            handleFetchCommonErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_2_13();
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_2_13_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const List_Token = hasLoaded ? pgData.Root.List_Token : undefined;
    //#endregion

    //#region +Event

    //#region click_收藏品兌換
    const click_我的最愛 = async () => {
        const navToPg_2_13 = () => {
            router.push("/tabs/2_13", "forward", "push");
        }
        navToPg_2_13();
        return false;
    }

    const click_我的收藏 = async () => {
        const navToPg_2_1 = () => {
            router.push("/tabs/2_1", "forward", "push");
        }
        navToPg_2_1();
        return false;
    }
    //#endregion

    //#region push login

    //	A simple, hard-coded navigation
    const navToPg_1_1 = () => {
        router.push("/tabs/1_1", "forward", "push");
    }

    const params = useParams();

    const fields = useLoginFields();
    const [errors, setErrors] = useState(false);


    const appUtil = useAppUtil();

    const push_login = async () => {
        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {
            //  Submit your form here
            let keyedFields = indexBy(fields, 'id');
            const username = fields && (keyedFields.email as any).input.state.value;
            const password = fields && (keyedFields.password as any).input.state.value;
            await appUtil.toast.present('登入中...', 10000)
            if (await push_pg_A_1__Login({
                p1: username,
                p2: password,
            })) {
                await appUtil.toast.dismiss();
                await appUtil.toast.present('登入成功', 1000)
                navToPg_1_1();
            } else {
                await appUtil.toast.dismiss();
                await appUtil.toast.present('登入失敗', 1000)
            }
        }
    }
    //#endregion

    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageName} sideMenu={true} sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonSegment value="我的最愛">
                                        <IonSegmentButton value="我的收藏" onClick={click_我的收藏}>
                                            <IonLabel>我的收藏</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="我的最愛">
                                            <IonLabel>我的最愛</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <IonGrid className="ion-padding">
                            <IonRow>
                                <IonCol className={`${styles_2_13.avatarWrap}`}>
                                    <div className={`${styles_2_13.avatarContainer}`}>
                                        <IonIcon icon={heartOutline} className="avatar"></IonIcon>
                                    </div>
                                </IonCol>
                            </IonRow>

                            <IonRow className="ion-text-center">
                                <IonCol size="12">
                                    <IonText color="dark">
                                        <p className="title">我的最愛</p>
                                    </IonText>
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        {List_Token.length === 0 ?
                            <>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol className="ion-justify-content-center ion-text-center w100 ion-padding">
                                            我的最愛暫無資料，請先前往新品商店選擇喜愛的商品，點選右下角愛心，加入個人收藏我的最愛清單中！
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </> : <>
                                <IonGrid className="ion-padding">
                                    <div className={styles_TokenItem.coverImageContainer}>
                                        {List_Token.map((item, index) => {
                                            return <Fragment key={item.TokenId}>
                                                <TokenItem Item_Token={item}/>
                                            </Fragment>
                                        })}
                                    </div>
                                </IonGrid>
                            </>}
                    </>
                }


            </CustomPage>


        </IonPage>
    );
};

export default Pg_2_13;
