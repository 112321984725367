// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pg_2_13_avatarContainer__Qqq5W {\n  width: 80px;\n  height: 80px;\n  line-height: 80px;\n  vertical-align: middle;\n  border: 1px solid #33333330;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-flow: row nowrap; }\n  .Pg_2_13_avatarContainer__Qqq5W .avatar {\n    display: inline-block;\n    color: red;\n    width: 50px;\n    height: 50px; }\n  .Pg_2_13_avatarContainer__Qqq5W .name {\n    display: inline-block; }\n\n.Pg_2_13_avatarWrap__1QtHi {\n  text-align: center;\n  margin-top: 2em;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-flow: row nowrap; }\n", "",{"version":3,"sources":["webpack://./src/pages/Pg_2_13.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EAEZ,iBAAiB;EACjB,sBAAsB;EAEtB,2BAA2B;EAC3B,kBAAkB;EAElB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB,EAAA;EAbvB;IAgBI,qBAAqB;IAErB,UAAS;IACT,WAAW;IACX,YAAY,EAAA;EApBhB;IAwBI,qBAAoB,EAAA;;AAKxB;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EAEX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB,EAAA","sourcesContent":[".avatarContainer {\r\n  width: 80px;\r\n  height: 80px;\r\n\r\n  line-height: 80px;\r\n  vertical-align: middle;\r\n\r\n  border: 1px solid #33333330;\r\n  border-radius: 50%;\r\n\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-flow: row nowrap;\r\n\r\n  :global(.avatar) {\r\n    display: inline-block;\r\n\r\n    color:red;\r\n    width: 50px;\r\n    height: 50px;\r\n  }\r\n\r\n  :global(.name) {\r\n    display:inline-block;\r\n  }\r\n}\r\n\r\n\r\n.avatarWrap {\r\n  text-align: center;\r\n  margin-top: 2em;\r\n  width: 100%;\r\n\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  flex-flow: row nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarContainer": "Pg_2_13_avatarContainer__Qqq5W",
	"avatarWrap": "Pg_2_13_avatarWrap__1QtHi"
};
export default ___CSS_LOADER_EXPORT___;
