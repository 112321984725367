import {IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonSegment, IonSegmentButton, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_Pg_5_5, Pg_5_1_Store, Pg_5_5_Store} from "../store/Pg_5_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
import {fetch_pg_1_2} from "../store/Pg_1_Store";
import {checkmarkOutline, chevronDownCircleOutline} from "ionicons/icons";
import {TokenStackItem} from "../components/TokenStackItem";
import {TokenEnwEntrustListItem} from "../components/TokenEnwEntrustListItem";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import styles_TokenItem from "../components/TokenItem.module.scss";
import {TokenStackSelfItem} from "../components/TokenStackSelfItem";
import CustomPage from "./CustomPage";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleFetchCommonErrors} from "./Utils";

const Pg_5_5 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_5_5";
    const pageId = "Pg_5_5";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    useEffect(() => {
        (async () => {
            let errorObj = await fetch_Pg_5_5();
            handleFetchCommonErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj =await fetch_Pg_5_5();
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_5_5_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const List_Token = hasLoaded ? pgData.Root.List_Token : undefined;
    //#endregion

    //#region +Event

    //#region click_全部委託
    const click_全部委託 = async () => {
        const navToPg_5_1 = () => {
            router.push("/tabs/5_1", "forward", "push");
        }
        navToPg_5_1();
        return false;
    }
    //#endregion

    //#region click_我的委託
    const click_我的委託 = async () => {
        const navToPg_5_5 = () => {
            router.push("/tabs/5_5", "forward", "push");
        }
        navToPg_5_5();
        return false;
    }
    //#endregion

    //#region click_標記委託
    const click_標記委託 = async () => {
        const navToPg_5_7 = () => {
            router.push("/tabs/5_7", "forward", "push");
        }
        navToPg_5_7();
        return false;
    }
    //#endregion

    //#endregion


    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonSegment value="我的委託">
                                        <IonSegmentButton value="全部委託" onClick={click_全部委託}>
                                            <IonLabel>全部委託</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="我的委託" >
                                            <IonLabel>我的委託</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="標記委託" onClick={click_標記委託}>
                                            <IonLabel>標記委託</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <IonGrid>
                            <div className={ styles_TokenItem.coverImageContainer}>
                                {List_Token.map((item, index) => {
                                    return <Fragment key={item?._委託狀態?.TokenEnwEntrustId}>
                                        <TokenStackSelfItem Item_Token={item} reload={reload}/>
                                    </Fragment>
                                })}
                            </div>
                        </IonGrid>
                    </>
                }
            </CustomPage>
        </IonPage>
    );
};

export default Pg_5_5;
