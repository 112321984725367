import React, {Component} from 'react';
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {IonIcon, IonImg} from "@ionic/react";
import {personCircle, personCircleOutline} from "ionicons/icons";
import styles from './Common.module.scss';

type Props = {
    圖片組?: string | null;
    imageAtIndex?: number | null,

    //#region Option: Generate DefaultImage (Pick-One)
    名稱?: string;
    DefaultImage?: string;
    DefaultContent?: string;
    //#endregion

    type?: string,   //multiple, single
    testMode?: boolean,
    allowClickToOpen?: boolean,

    emitNullIfEmpty?: boolean
};

export default function Ln_TokenPictureSetCoverImg(props: Props) {
    const param_圖片組 = props['圖片組'] || '';
    const param_名稱 = props['名稱'];
    const param_DefaultImage = props['DefaultImage'];
    const param_DefaultContent = props['DefaultContent'];
    const param_TestMode = props['testMode'] || false;
    const param_type = props['type'] || 'single';
    const param_allowClickToOpen = props['allowClickToOpen'] || false;
    const imageAtIndex = props['imageAtIndex'] || 0;

    let imgIds: any[] = _._b.getCoverSafeList(param_圖片組);  //if undefined,empty will be [null]

    let firstImgId:any = null;
    if (imageAtIndex < imgIds.length) {
        firstImgId = imgIds[imageAtIndex];
    } else {
        firstImgId = null;
    }


    if (param_TestMode) {
        return <React.Fragment>
            <IonImg src={"https://via.placeholder.com/90x90.png?text=NoImage"} alt={param_名稱}/>
        </React.Fragment>;
    }

    if (firstImgId == null) {
        if (param_DefaultImage) {
            return <React.Fragment>
                <img loading="lazy" src={param_DefaultImage}/>
            </React.Fragment>;
        }
        if (param_DefaultContent) {
            return param_DefaultContent;
        } else {
            return <React.Fragment>
                {/*<IonImg src={"https://via.placeholder.com/700x700.png?text=NoImage"} alt={param_名稱}/>*/}
                {/*<IonImg src={ personCircle } alt={param_名稱} className={styles.defaultAvatarBgColor}/>*/}
                <IonIcon icon={personCircle} className={styles.defaultAvatarBgColor}/>
                {/*<img src={`https://ui-avatars.com/api/?name=${encodeURIComponent(param_名稱 || '')}`}/>*/}
            </React.Fragment>;
        }
    } else {
        if (firstImgId.indexOf('data:') !== -1) { //if inline
            return <React.Fragment>
                <img loading="lazy" src={firstImgId.replaceAll('_#comma|_', ',')}/>
            </React.Fragment>;
        }

        // others
        if (param_type === "multiple") {
            return <React.Fragment>
                {imgIds.map((imgId) => {
                    let img = <img key={imgId} loading="lazy"
                                   src={ApiService.urlSmartImageMapping("~/" + imgId, 'crop', 700, '')}
                                   srcSet={`${ApiService.urlSmartImageMapping("~/" + imgId, 'crop', 700, '')} 700w, ${ApiService.urlSmartImageMapping("~/" + imgId)} 1200w`}
                    />
                    if (param_allowClickToOpen) {
                        return <React.Fragment key={`${imgId}_wrapper`}>
                            <a href={firstImgId ?? "#"} >
                                {img}
                            </a>
                        </React.Fragment>
                    } else {
                        return img;
                    }
                })}
            </React.Fragment>;
        } else {
            let img = <>
                <img key={firstImgId} loading="lazy"
                     src={ApiService.urlSmartImageMapping("~/" + firstImgId, 'crop', 700, '')}
                     srcSet={`${ApiService.urlSmartImageMapping("~/" + firstImgId, 'crop', 700, '')} 700w, ${ApiService.urlSmartImageMapping("~/" + firstImgId)} 1200w`}
                />
            </>;
            if (param_allowClickToOpen) {
                return <React.Fragment key={`${firstImgId}_wrapper`}>
                    <a href={firstImgId ?? "#"} >
                        {img}
                    </a>
                </React.Fragment>
            } else {
                return img;
            }
        }


    }
}
