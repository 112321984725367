import {IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_Pg_5_1, fetch_Pg_5_2, Pg_5_1_Store, Pg_5_2_Store} from "../store/Pg_5_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
import {fetch_pg_1_2} from "../store/Pg_1_Store";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {TokenEnwEntrustListItem} from "../components/TokenEnwEntrustListItem";
import CustomPage from "./CustomPage";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import styles_5_2 from './Pg_5_2.module.scss';
import {chevronDownCircleOutline} from "ionicons/icons";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleFetchCommonErrors} from "./Utils";


const Pg_5_2 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_5_1";
    const pageId = "Pg_5_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    const {tokenId}: any = useParams();

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_Pg_5_2({
                p1: tokenId
            });
            handleFetchCommonErrors(router, errorObj);
        })();

    }, [tokenId]);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_Pg_5_2({
            p1: tokenId
        });
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_5_2_Store, getPageData);

    let hasLoaded = pgData?.Root ?? false;
    const Item_Token = hasLoaded ? pgData.Root.Item_Token : undefined;
    const List_TokenEnw委託二手交易 = hasLoaded ? pgData.Root.List_TokenEnw委託二手交易 : undefined;

    if (hasLoaded && (tokenId != Item_Token?.TokenId)) {
        hasLoaded = false;
    }


    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        {/*<IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">*/}
                        {/*    /!*<IonRow>*!/*/}
                        {/*    /!*    <IonCol size="12">*!/*/}
                        {/*    /!*        /!*{ talks.map((talk, talkIndex) => {*!/*!/*/}
                        {/*    /!*        /!*    return talkIndex > 0 && <TalkCard key={ talkIndex } talk={ talk } pageRef={ pageRef } />;*!/*!/*/}
                        {/*    /!*        /!*})}*!/*!/*/}
                        {/*    /!*    </IonCol>*!/*/}
                        {/*    /!*</IonRow>*!/*/}

                        {/*    <IonGrid className="ion-padding">*/}
                        {/*        /!*<IonRow className={ styles.searchContainer }>*!/*/}
                        {/*        /!*    <IonCol size="12">*!/*/}
                        {/*        /!*        <IonSearchbar animated placeholder="Search for a product" />*!/*/}
                        {/*        /!*    </IonCol>*!/*/}
                        {/*        /!*</IonRow>*!/*/}


                        {/*        /!*click={() => handleShowModal(item)}*!/*/}
                        {/*        <IonRow>*/}
                        {/*            {List_TokenEnw委託二手交易.map((item, index) => {*/}
                        {/*                return <Fragment key={item.TokenEnw委託二手交易Id}>*/}
                        {/*                    <TokenEnwEntrustListItem Item_TokenEnwEntrust={item}/>*/}
                        {/*                </Fragment>;*/}
                        {/*            })}*/}
                        {/*        </IonRow>*/}
                        {/*    </IonGrid>*/}
                        {/*</IonGrid>*/}


                        <IonList>
                            <IonItem lines="full">
                                <div className={styles_5_2.listTitle}>
                                    <div className="l ">
                                        <h4 className="ll-1">{Item_Token.名稱} #{Item_Token.TokenId}</h4>
                                    </div>
                                    <div className="r">
                                        <h6>價錢由低到高</h6>
                                    </div>
                                </div>
                            </IonItem>
                            {List_TokenEnw委託二手交易.map((item, index) => {
                                return <Fragment key={item.TokenEnw委託二手交易Id}>
                                    <IonItem className={styles_5_2.listItem} lines="full">
                                        <TokenEnwEntrustListItem Item_TokenEnwEntrust={item}/>
                                    </IonItem>
                                </Fragment>;
                            })}
                        </IonList>

                    </>
                }
            </CustomPage>
        </IonPage>
    );
};

export default Pg_5_2;
