import {IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_1_2, Pg_1_2_Store} from "../store/Pg_1_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {chevronDownCircleOutline, copyOutline} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import {Simulate} from "react-dom/test-utils";
import select = Simulate.select;
import Ln_ContractPictureSetCoverImg from "./Ln_ContractPictureSetCoverImg";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import {PageHeader} from "./PageHeader";
import {ActionLink} from "../components/ActionLink";
import {Modal} from "./Modal";
import CustomPage from "./CustomPage";

import styles_TokenItem from "../components/TokenItem.module.scss";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleFetchCommonErrors} from "./Utils";

const Pg_1_2 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_1_2";
    const pageId = "Pg_1_2";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    const {contractId}: any = useParams();

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_1_2({
                p1: contractId,
            });
            handleFetchCommonErrors(router, errorObj);
        })();
    }, [contractId]);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_1_2({
            p1: contractId,
        });
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_1_2_Store, getPageData);

    let hasLoaded = pgData?.Root ?? false;
    const Item_合約 = hasLoaded ? pgData.Root.Item_合約 : undefined;
    const List_Token__已發行 = hasLoaded ? pgData.Root.List_Token__已發行 : undefined;
    const List_Token__即將發行 = hasLoaded ? pgData.Root.List_Token__即將發行 : undefined;

    if (hasLoaded && (contractId != Item_合約?.合約Id)) {
        hasLoaded = false;
    }

    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>
                {/*<PageHeader count={ sideMenuOptions.length } pageName={ pageName } />*/}
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        <IonGrid className="pad-0">
                            <IonRow className="ion-justify-content-center ion-text-center w100">
                                {Ln_ContractPictureSetCoverImg({
                                    圖片組: Item_合約.圖片組,
                                    名稱: 'cover',
                                })}
                            </IonRow>
                            {/*<IonCardContent>*/}
                            {/*</IonCardContent>*/}

                            {/*<IonRow>*/}
                            {/*    <IonCol size="6">*/}
                            {/*        /!*<IonButton fill={ !bookmarked ? "outline" : "solid" } onClick={ () => bookmarked ? removeSavedQuote(quote.id) : addSavedQuote(quote.id) }>*!/*/}
                            {/*        /!*    <IonIcon icon={ bookmarked ? checkmarkOutline : bookmarkOutline } />*!/*/}
                            {/*        /!*    &nbsp;{ bookmarked ? "Bookmarked" : "Save as Bookmark" }*!/*/}
                            {/*        /!*</IonButton>*!/*/}
                            {/*    </IonCol>*/}

                            {/*    /!*<IonCol size="4">*!/*/}
                            {/*    /!*    <IonButton fill="outline">*!/*/}
                            {/*    /!*        <IonIcon icon={copyOutline}/>*!/*/}
                            {/*    /!*        &nbsp;Copy Quote*!/*/}
                            {/*    /!*    </IonButton>*!/*/}
                            {/*    /!*</IonCol>*!/*/}
                            {/*</IonRow>*/}
                            <IonRow className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                                <IonCol>
                                    <h1>{Item_合約.Name}</h1>
                                    <IonText dangerouslySetInnerHTML={{__html: Item_合約.Description}}/>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            {List_Token__即將發行.length > 0 &&
                                <>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonText color="dark">
                                                <p className="title">即將發行</p>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>
                                    <IonGrid className="ion-padding">
                                        {/*<IonRow className={ styles.searchContainer }>*/}
                                        {/*    <IonCol size="12">*/}
                                        {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                        {/*    </IonCol>*/}
                                        {/*</IonRow>*/}


                                        {/*click={() => handleShowModal(item)}*/}
                                        <IonRow>
                                            <div className={styles_TokenItem.coverImageContainer}>
                                                {List_Token__即將發行.map((item, index) => {
                                                    return <Fragment key={item.TokenId}>
                                                        <TokenItem Item_Token={item}/>
                                                    </Fragment>
                                                })}
                                            </div>
                                        </IonRow>
                                    </IonGrid>
                                </>}

                            {List_Token__已發行.length > 0 &&
                                <>
                                    <IonRow>
                                        <IonCol size="12">
                                            <IonText color="dark">
                                                <p className="title">已發行</p>
                                            </IonText>
                                        </IonCol>
                                    </IonRow>

                                    {/*<IonRow>*/}
                                    {/*    <IonCol size="12">*/}
                                    {/*        /!*{ talks.map((talk, talkIndex) => {*!/*/}
                                    {/*        /!*    return talkIndex > 0 && <TalkCard key={ talkIndex } talk={ talk } pageRef={ pageRef } />;*!/*/}
                                    {/*        /!*})}*!/*/}
                                    {/*    </IonCol>*/}
                                    {/*</IonRow>*/}

                                    <IonGrid className="ion-padding">
                                        {/*<IonRow className={ styles.searchContainer }>*/}
                                        {/*    <IonCol size="12">*/}
                                        {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                        {/*    </IonCol>*/}
                                        {/*</IonRow>*/}


                                        {/*click={() => handleShowModal(item)}*/}
                                        <IonRow>
                                            <div className={styles_TokenItem.coverImageContainer}>
                                                {List_Token__已發行.map((item, index) => {
                                                    return <Fragment key={item.TokenId}>
                                                        <TokenItem Item_Token={item}/>
                                                    </Fragment>
                                                })}
                                            </div>
                                        </IonRow>
                                    </IonGrid>
                                </>
                            }


                        </IonGrid>
                    </>
                }
            </CustomPage>
        </IonPage>
    );
};

export default Pg_1_2;
