// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pg_3_11_listTitle__UiVMI {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  flex-flow: row nowrap;\n  justify-content: space-between; }\n  .Pg_3_11_listTitle__UiVMI .l {\n    display: inline; }\n\n.Pg_3_11_line__cJ3lo {\n  border-top: 1px solid black;\n  height: 1px;\n  display: block; }\n", "",{"version":3,"sources":["webpack://./src/pages/Pg_3_11.module.scss"],"names":[],"mappings":"AAKA;EACE,WAAW;EAEX,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,8BAA8B,EAAA;EANhC;IASI,eAAe,EAAA;;AAKnB;EACE,2BAA2B;EAG3B,WAAW;EACX,cAAc,EAAA","sourcesContent":[".listItem {\r\n\r\n}\r\n\r\n\r\n.listTitle {\r\n  width: 100%;\r\n\r\n  display: flex;\r\n  align-items: center;\r\n  flex-flow: row nowrap;\r\n  justify-content: space-between;\r\n\r\n  :global(.l) {\r\n    display: inline;\r\n  }\r\n}\r\n\r\n\r\n.line {\r\n  border-top: 1px solid black;\r\n  //padding-top: 10px;\r\n  //padding-bottom: 5px;\r\n  height: 1px;\r\n  display: block;\r\n  //margin-left: 10px;\r\n  //margin-right: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listTitle": "Pg_3_11_listTitle__UiVMI",
	"line": "Pg_3_11_line__cJ3lo"
};
export default ___CSS_LOADER_EXPORT___;
