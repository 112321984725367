import {IonCol, IonRouterLink, IonRow} from "@ionic/react";


type Props = {
    message: string
    text: string
    link: string
};
export const Action = (props: Props) => {
    return <IonRow className="ion-text-center ion-justify-content-center">
        <IonCol size="12">
            { props.message }
            <IonRouterLink className="custom-link" routerLink={props.link}> {props.text} &rarr;</IonRouterLink>
        </IonCol>
    </IonRow>
};
