// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_2_3_OpenCamera_appicons__PuIfQ {\n  margin-top: 10px;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  flex-flow: row nowrap; }\n", "",{"version":3,"sources":["webpack://./src/pages/Modal_2_3_OpenCamera.module.scss"],"names":[],"mappings":"AAKA;EACE,gBAAgB;EAChB,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,qBAAqB,EAAA","sourcesContent":["\r\n.half {\r\n}\r\n\r\n\r\n.appicons {\r\n  margin-top: 10px;\r\n  display: flex;\r\n  justify-content: space-evenly;\r\n  align-items: center;\r\n  flex-flow: row nowrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appicons": "Modal_2_3_OpenCamera_appicons__PuIfQ"
};
export default ___CSS_LOADER_EXPORT___;
