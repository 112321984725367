// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pg_A_0_homePage__dvE3N ion-header ion-img {\n  border-bottom: 3px solid #ec9523; }\n\n.Pg_A_0_homePage__dvE3N ion-footer {\n  background-color: #7c3b6a;\n  color: white; }\n\n.Pg_A_0_getStarted__gsNWd {\n  height: 100%;\n  background-color: #ffffff;\n  background-image: radial-gradient(#b8b8b8 1px, transparent 1px), radial-gradient(#b8b8b8 1px, #ffffff 1px);\n  background-size: 40px 40px;\n  background-position: 0 0, 20px 20px; }\n  .Pg_A_0_getStarted__gsNWd ion-card-title {\n    color: black !important;\n    letter-spacing: -0.08rem;\n    font-weight: 900 !important; }\n\n.Pg_A_0_heading__-FaOs {\n  margin-top: 7rem; }\n\n.Pg_A_0_getStartedButton__b2PrV {\n  font-size: 1.2rem;\n  margin-top: 1rem; }\n", "",{"version":3,"sources":["webpack://./src/pages/Pg_A_0.module.scss"],"names":[],"mappings":"AAAA;EAMY,gCAA0C,EAAA;;AANtD;EAYQ,yBAAyB;EACzB,YAAY,EAAA;;AAIpB;EAEI,YAAY;EACZ,yBAAyB;EACzB,0GACyC;EACzC,0BAA0B;EAC1B,mCAAmC,EAAA;EAPvC;IAWQ,uBAAuB;IACvB,wBAAwB;IACxB,2BAA2B,EAAA;;AAInC;EAEI,gBAAgB,EAAA;;AAGpB;EAEI,iBAAiB;EACjB,gBAAgB,EAAA","sourcesContent":[".homePage {\n\n    ion-header {\n\n        ion-img {\n\n            border-bottom: 3px solid rgb(236, 149, 35);\n        }\n    }\n\n    ion-footer {\n\n        background-color: #7c3b6a;\n        color: white;\n    }\n}\n\n.getStarted {\n\n    height: 100%;\n    background-color: #ffffff;\n    background-image: radial-gradient(#b8b8b8 1px, transparent 1px),\n    radial-gradient(#b8b8b8 1px, #ffffff 1px);\n    background-size: 40px 40px;\n    background-position: 0 0, 20px 20px;\n\n    ion-card-title {\n\n        color: black !important;\n        letter-spacing: -0.08rem;\n        font-weight: 900 !important;\n    }\n}\n\n.heading {\n\n    margin-top: 7rem;\n}\n\n.getStartedButton {\n\n    font-size: 1.2rem;\n    margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homePage": "Pg_A_0_homePage__dvE3N",
	"getStarted": "Pg_A_0_getStarted__gsNWd",
	"heading": "Pg_A_0_heading__-FaOs",
	"getStartedButton": "Pg_A_0_getStartedButton__b2PrV"
};
export default ___CSS_LOADER_EXPORT___;
