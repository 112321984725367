import {IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_1_5, fetch_pg_1_6, Pg_1_5_Store, Pg_1_6_Store, push_pg_1_6__Buy} from "../store/Pg_1_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {checkmarkOutline, chevronDownCircleOutline, copyOutline} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import styles_TokenItem from "../components/TokenItem.module.scss";
import {handleFetchCommonErrors} from "./Utils";
import styles_1_6 from './Pg_1_6.module.scss';
import styles_1_12 from "./Pg_1_12.module.scss";

const Pg_1_6 = (props) => {
        const pageRef = useRef();
        const pageName = "Pg_1_6";
        const pageId = "Pg_1_6";
        const {sideMenuOptions} = props;
        const setSideMenu: any = useSideMenuUpdate();
        const sideMenu: any = useSideMenuOptions();

        const router = useIonRouter();

        useEffect(() => {
            setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
        }, []);

        //#region Data Loading
        const {tokenId}: any = useParams();

        useEffect(() => {
            (async () => {
                let errorObj = await fetch_pg_1_6({
                    p1: tokenId,
                });
                handleFetchCommonErrors(router, errorObj);
            })();
        }, [tokenId]);

        let reload = async (event: CustomEvent<RefresherEventDetail>) => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            let errorObj = await fetch_pg_1_6({
                p1: tokenId,
            });
            handleFetchCommonErrors(router, errorObj);
            event?.detail?.complete();
        }

        const pgData = useStoreState(Pg_1_6_Store, getPageData);

        const hasLoaded = pgData?.Root ?? false;
        const Item_Token = hasLoaded ? pgData.Root.Item_Token : undefined;
        const Item_盲盒 = hasLoaded ? pgData.Root.Item_盲盒 : undefined;
        const List_Token = hasLoaded ? pgData.Root.List_Token : undefined;

        //#endregion

        const _push_pg_1_6__Buy = async (tokenId) => {
            let errorObj = (await push_pg_1_6__Buy({
                    p1: tokenId,
                    p4: window.location.href,
                }, (result) => {
                    window.location.href = result.RedirectTo;
                    //router.push(result.RedirectTo);
                })
            );
            handleFetchCommonErrors(router, errorObj);
            if (!errorObj) {
                //alert('購買成功');
                //navToPg_A_1(router);
            } else {
            }
        }

        return (
            <IonPage id={pageId}>
                <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>
                    {/*<PageHeader count={ sideMenuOptions.length } pageName={ pageName } />*/}
                    <IonRefresher slot="fixed" onIonRefresh={reload}>
                        <IonRefresherContent
                            pullingIcon={chevronDownCircleOutline}
                            pullingText="Pull to refresh"
                            refreshingSpinner="circles"
                            refreshingText="Refreshing...">
                        </IonRefresherContent>
                    </IonRefresher>

                    {hasLoaded &&
                        <>
                            <IonGrid className={`ion-justify-content-center ${styles_1_6.topImage}`}>
                                <IonImg src={"/assets/box.gif"} alt="box cover"/>
                            </IonGrid>

                            <IonGrid className="ion-padding">
                                <IonRow className="">
                                    <IonCol>
                                        <IonButton fill={"solid"} expand="block" onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();

                                            _push_pg_1_6__Buy(Item_Token.TokenId);

                                            return false;
                                        }}>
                                            確定購買 - {Item_盲盒.名稱}
                                            <div className={styles_1_12.right}>{Item_盲盒.購買價格} 寶石</div>
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                                    <IonCol>
                                        <h1>{Item_盲盒.名稱}</h1>
                                        <IonText dangerouslySetInnerHTML={{__html: Item_盲盒.介紹}}/>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol>
                                        <IonText color="dark">
                                            <p className="title">盲盒內容</p>
                                        </IonText>
                                    </IonCol>
                                </IonRow>


                                {/*<IonRow className={ styles.searchContainer }>*/}
                                {/*    <IonCol size="12">*/}
                                {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                {/*    </IonCol>*/}
                                {/*</IonRow>*/}
                                {/**/}
                                <div className={styles_TokenItem.coverImageContainer}>
                                    {List_Token.map((item, index) => {
                                        return <Fragment key={item.TokenId}>
                                            <TokenItem Item_Token={item}/>
                                        </Fragment>
                                    })}
                                </div>
                            </IonGrid>
                        </>
                    }
                </CustomPage>


            </IonPage>
        );
    }
;

export default Pg_1_6;
