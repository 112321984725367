import React, {useEffect, useState} from 'react';
import {IonHeader, IonContent, IonToolbar, IonTitle, IonButtons, IonMenuButton, IonBackButton, IonIcon, IonSearchbar, IonImg, useIonRouter} from '@ionic/react';
import {chevronBack} from 'ionicons/icons';
import styles from './Common.module.scss';
import {ActionLink} from "../components/ActionLink";
import {useStoreState} from "pullstate";
import {AuthStore} from "../store/Common_Store";
import {getPageData} from "../store/Selectors";
import styles_Common from "../pages/Common.module.scss";
import {navToPg_1_1} from "./Utils";
const CustomPage = (props) => {
    const router = useIonRouter();

    const [, setForceRender] = useState<number>(0);
    const forceRender = (triggerLoad = false) => {
        setForceRender(s => s + 1);
    }

    (window as any).testme = forceRender;

    const authStore = useStoreState(AuthStore, getPageData);
    const hasAuthed = authStore?.signBy ?? false;
    const Item_會員 = hasAuthed ? authStore?.AuthData : undefined;

    useEffect(() => {
        //fetch_Pg_A_1();
        forceRender();
    }, [Item_會員]);

    const mainContent = props.children;
    const {
        name,
        sideMenu = false,
        sideMenuPosition = "end",
        backButton = true,
        backButtonIcon = chevronBack,
        backButtonText = " ",
        backButtonPath = "",
        actionButton = true,
        actionButtonPosition = "end",
        actionButtonIcon,
        actionButtonIconSize,
        actionButtonClickEvent,
        contentClass = "",
        searchbar = false,
        searchbarEvent,
        showLargeHeader = false
    } = props;

    return (
        <>
            <IonHeader translucent={true} key={Item_會員?.會員Id}>
                <IonToolbar className={styles.themeBorderBottom}>
                    {/*<IonTitle>{ name }</IonTitle>*/}

                    <IonTitle>
                        <ActionLink link={`/tabs/1_1`} noLink={false} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            navToPg_1_1(router);

                            return false;
                        }}>
                            <IonImg src={"/assets/icon/labie_logo.svg"} alt="logo" className={styles.logo}/>
                        </ActionLink>
                    </IonTitle>


                    {backButton &&
                        <IonButtons slot="start">
                            <IonBackButton icon={backButtonIcon} text={backButtonText} defaultHref={backButtonPath}/>
                        </IonButtons>
                    }

                    {(actionButton && actionButtonIcon) &&
                        <IonButtons slot={actionButtonPosition}>
                            <IonIcon style={{fontSize: actionButtonIconSize}} icon={actionButtonIcon} onClick={actionButtonClickEvent}></IonIcon>
                        </IonButtons>
                    }
                    {/*slot={ sideMenuPosition }*/}
                    {/*{sideMenu &&*/}
                    {/*    <IonButtons slot={'end'}>*/}
                    {/*        <IonMenuButton/>*/}
                    {/*    </IonButtons>*/}
                    {/*}*/}
                    {/*<IonButtons slot={actionButtonPosition}>*/}
                    {/*    <IonIcon style={{fontSize: actionButtonIconSize}} icon={actionButtonIcon} onClick={actionButtonClickEvent}></IonIcon>*/}
                    {/*</IonButtons>*/}
                    <IonButtons slot={'end'}>
                        <IonMenuButton/>
                    </IonButtons>
                    {/*{hasAuthed &&*/}
                    {/*    <IonButtons slot={'end'} key={Item_會員?.會員Id}>*/}
                    {/*        <IonMenuButton/>*/}
                    {/*    </IonButtons>*/}
                    {/*}*/}
                </IonToolbar>
            </IonHeader>
            {/**/}
            <IonContent className={`${styles_Common.ionContent} ${contentClass}`} fullscreen>
                {showLargeHeader &&
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle slot="start" size="large">{name}</IonTitle>
                            {searchbar && <IonSearchbar style={{marginTop: "-0.2rem", width: "50%", float: "right"}} onKeyUp={e => searchbarEvent(e)} onChange={e => searchbarEvent(e)}/>}

                        </IonToolbar>
                    </IonHeader>
                }
                {mainContent}
            </IonContent>
        </>
    );
}

export default CustomPage;
