import {IonGrid, IonRow, IonCol, IonModal, IonButtons, IonButton, IonIcon, IonContent, IonHeader, IonToolbar, IonTitle, useIonRouter, RefresherEventDetail, IonSelect, IonSelectOption, IonAvatar, IonLabel, IonItemGroup} from "@ionic/react";
import {checkmarkOutline, chevronBack, scan} from "ionicons/icons";
import {QrReader} from "react-qr-reader";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {OnResultFunction} from "react-qr-reader/src/types";
import CustomField from "../components/CustomField";
import {useFormInput, validateForm} from "../_/useForms";
import {useCallback, useContext, useEffect, useState} from "react";
import indexBy from "underscore/modules/indexBy";
import {Pg_B_1_Store, push_pg_B_1_emailverify, push_pg_B_1_sendemailverify} from "../store/Pg_B_Store";
import {Pg_C_4_Store, push_pg_C_1, push_pg_C_4_phoneverify, push_pg_C_4_sendphoneverify} from "../store/Pg_C_Store";
import {handleCommonFetchErrors, handleFetchCommonErrors, navToPg_A_1} from "./Utils";
import {fetch_pg_3_1, fetch_pg_3_1__Write, Pg_3_1__Write_Store, push_pg_3_11__Comment, push_pg_3_1__Write} from "../store/Pg_3_Store";
import {fetch_pg_2_3_entrust, Pg_2_3_Entrust_Store} from "../store/Pg_2_Store";
import {useStoreState} from "pullstate";
import {getPageData} from "../store/Selectors";
import {Pg_3_1_Store} from "../store/PgStore";
import {useParams} from "react-router";
import {fetch_pg_1_6, Pg_1_6_Store} from "../store/Pg_1_Store";
import styles_Pg_C_1 from "./Pg_C_1.module.scss";
import {push_pg_0_3} from "../store/Pg_0_Store";
import styles_Modal_3_1 from "./Modal_3_1_WriteArticle.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import {v4 as uuidv4} from "uuid";
import MyUploadProgress from "./MyUploadProgress";
import MyUploadFail from "./MyUploadFail";
import ChunkedUploady, {UploadyContext, useBatchFinishListener, useChunkStartListener} from "@rpldy/chunked-uploady";
import {useBatchAddListener, useRequestPreSend} from "@rpldy/uploady";
import Button from "@mui/material/Button";
import AddCircleOutlineSharp from "@mui/icons-material/AddCircleOutlineSharp";


export const useLoginFields = () => {
    return [
        {
            id: "commentText",
            label: "留言內文",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "請輸入留言內文",
                    autoGrow: false,
                },
                state: useFormInput("")
            }
        }

    ];
}

const customSelectContainerOptions = {
    header: '貼文相關物品',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};


const MAX_FILE_SIZE = 99 * 1000000; //99MB
const MyUploadButton = ({uploadedFile, setUploadedFile}) => {
    const uploady = useContext(UploadyContext);

    const [fileId, set_fileId] = useState("");
    useRequestPreSend(({items, options}) => {
        const _fileID = uuidv4() + "." + items[0].file.name.split(".").pop();
        set_fileId(_fileID);
        return {
            options: { //will be merged with the rest of the options
                destination: {
                    params: {
                        fileId: fileId
                    }
                }
            }
        };
    });
    useChunkStartListener(({chunk, chunkItem}) => {
        return {
            sendOptions: {
                method: "POST",
                paramName: "data",
                params: {
                    // chunkId: chunk?.index,
                    fileSize: chunkItem?.file?.size
                }
            }
        };
    });
    useBatchAddListener((batch) => {
        let result = true;
        (batch?.items as any)?.forEach((item, i) => {
            if (batch?.items[i]?.file?.size > MAX_FILE_SIZE) {
                // saveUploadedDocumentToProject({fileName: batch?.items[0]?.file.name,fileId: fileID,});
                result = result && false; //false will cancel.
            }
        })
        return result;
    });
    useBatchFinishListener((batch) => {
        let fileBag: string[] = [];

        (batch?.items as any)?.forEach((item, i) => {
            if (batch?.items[i]?.state === "finished") {
                // saveUploadedDocumentToProject({fileName: batch?.items[0]?.file.name,fileId: fileID,});

                let fileId_2 = JSON.parse(batch.items[i].uploadResponse.results[0].data).files[0].id;
                //console.log(fileId);

                fileBag.push(fileId_2);
            }
        })

        if (fileBag.length >= 1) {
            if (((uploadedFile?.length) ?? 0) > 0) {
                setUploadedFile(`${uploadedFile},${fileBag.join(',')}`);
            } else {
                setUploadedFile(`${fileBag.join(',')}`);
            }
        }
    });
    const handleUpload = useCallback(() => {
        uploady.showFileUpload({
            autoUpload: true
        });
    }, [uploady]);
    return (
        <Button onClick={handleUpload}>
            <AddCircleOutlineSharp/>
            <span>&nbsp;&nbsp;上傳圖片</span>
        </Button>
    );
};

const RenderForm = ({router, List_TokenEnw, onResult = null, reload = null, dismiss = null, Item_圖片生成 = null, PhotoSet = null}: {
    router: any,
    List_TokenEnw: any,
    reload?: any, //[opt]
    dismiss?: any, //[opt]
    onResult?: any, //[opt]

    Item_圖片生成?: any, //[opt]
    PhotoSet?: any, //[opt]
}) => {
    const [uploadedFile, setUploadedFile] = useState<string | null>((PhotoSet ?? Item_圖片生成?.PhotoSet) ?? null);

    //#region +Event
    const fields = [
        {
            id: "commentText",
            label: "貼文內文",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "請輸入貼文內文",
                    autoGrow: true,
                },
                state: useFormInput("")
            }
        },
        {
            id: "videoText",
            label: "影片連結",
            required: false,
            input: {
                props: {
                    type: "text",
                    placeholder: "或影片 例: https://www.youtube.com/watch?v=C9V_1FzdqiY",
                    autoGrow: false,
                },
                state: useFormInput("")
            }
        },
        {
            id: "TokenEnwId",
            label: "物品唯一碼",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "請輸入物品唯一碼",
                },
                state: useFormInput((Item_圖片生成?._TokenEnw?.TokenEnwId) ?? "")
            }
        },
        // {
        //     id: "File",
        //     label: "照片",
        //     required: true,
        //     input: {
        //         props: {
        //             type: "file",
        //             placeholder: "請張貼一個照片",
        //         },
        //         state: useFormInput("", (val, oldVal) => {
        //         })
        //     }
        // },
    ];

    let keyedFields = indexBy(fields, 'id');

    //#endregion

    const [errors, setErrors] = useState(false);

    const handleScan = result => {
        if (result) {
            //setQRData(result);
            //play();
            //handleSuccess(result);
            alert(`代碼: ${result.text}`);
            keyedFields.code.input.state.reset(result.text);
            //onResult(result);
        }
    }

    const push_writeArticle = async () => {
        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {
            const commentText = fields && (keyedFields.commentText as any).input.state.value;
            const TokenEnwId = fields && (keyedFields.TokenEnwId as any).input.state.value;
            const videoText = fields && (keyedFields.videoText as any).input.state.value;

            /*
                p1: string, //param_photoSet
    p2: string, //param_contractId
    p3: string, //param_content
    p4: string, //param_TokenEnwId
             */

            if (uploadedFile == null && !videoText) {
                alert('請上傳【一張照片】或填寫【影片連結】');
                return;
            }

            let errorObj = await push_pg_3_1__Write({
                p1: uploadedFile,
                p2: '',
                p3: commentText,
                p4: TokenEnwId,
                p5: videoText,
            });
            if (errorObj) {
                handleCommonFetchErrors(router, errorObj);
            } else {
                //alert('謝謝註冊，現在請輸入剛註冊的帳密登入');
                //navToPg_A_1(router);
                alert('留言成功')

                if (dismiss) {
                    dismiss();
                }
                if (onResult) {
                    onResult(true);
                }
            }
        }
    }

    return <>
        <IonItemGroup>
            {/*<IonItemDivider>*/}
            {/*    <IonLabel>選填</IonLabel>*/}
            {/*</IonItemDivider>*/}
            <IonRow>
                <IonCol className={'flex'}>
                    {/*<CustomField key={keyedFields.File.id} field={keyedFields.File} errors={errors} noLabel={true}/>*/}
                    <ChunkedUploady
                        destination={{url: "/MemberUploadHandler.ashx"}}
                        chunkSize={5242880}
                        multiple={true}
                    >
                        <div className={`${styles_Modal_3_1.h50px} ${styles_Modal_3_1.multipleImgs}`}>
                            {Ln_TokenPictureSetCoverImg({
                                圖片組: uploadedFile,
                                名稱: 'cover',
                                type: 'multiple',
                            })}
                        </div>
                        <div>
                            <MyUploadButton uploadedFile={uploadedFile} setUploadedFile={setUploadedFile}/>
                            <MyUploadProgress/>
                            <MyUploadFail/>
                        </div>
                    </ChunkedUploady>
                </IonCol>
            </IonRow>
        </IonItemGroup>

        <IonRow>
            <IonCol>
                <CustomField field={keyedFields.videoText} errors={errors} noLabel={false}/>
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol>
                {/*<CustomField alternateLeftZone={<>*/}
                {/*    <IonSelect value={keyedFields.TokenEnwId.input.state.value} interfaceOptions={customSelectContainerOptions} interface="action-sheet" placeholder="您的寵物名字" onIonChange={(ev) => {*/}
                {/*        keyedFields.TokenEnwId.input.state.reset(ev.detail.value);*/}
                {/*    }}*/}
                {/*               multiple={false} className={styles_Pg_C_1.select}>*/}
                {/*        {List_TokenEnw.map((v) => {*/}
                {/*            return <IonSelectOption key={v.TokenEnwId} value={v.TokenEnwId}>{v.PetName}</IonSelectOption>*/}
                {/*        })}*/}
                {/*    </IonSelect>*/}
                {/*</>} field={keyedFields.TokenEnwId} errors={errors} isHideField={true}/>*/}

                <CustomField mainZone={<>
                    <IonSelect value={keyedFields.TokenEnwId.input.state.value} interfaceOptions={customSelectContainerOptions} interface="action-sheet" placeholder="物品唯一碼" onIonChange={(ev) => {
                        keyedFields.TokenEnwId.input.state.reset_WithEndCallbacks(ev.detail.value);
                    }}
                               multiple={false} className={styles_Pg_C_1.select}>
                        {List_TokenEnw.map((v) => {
                            return <IonSelectOption key={v.TokenEnwId} value={v.TokenEnwId}>{v._Token._合約.Name} {v._Token.名稱} #{v.Edition}</IonSelectOption>
                        })}
                    </IonSelect>
                </>} field={keyedFields.TokenEnwId} errors={errors} noLabel={false}/>
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol>
                <CustomField inputClassName={styles_Modal_3_1.maxHeight} field={keyedFields.commentText} errors={errors} noLabel={false} textType="textarea"/>
            </IonCol>
        </IonRow>

        <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
            <IonCol>
                <IonButton fill={"solid"} onClick={(e) => {
                    //_push_pg_4_2__AddCouponBookmark(Item_合約獎勵.合約獎勵Id);
                    // alert('代碼為空，無法兌換')

                    e.preventDefault();
                    e.stopPropagation();

                    push_writeArticle();

                    return false;
                }}>
                    送出
                </IonButton>
            </IonCol>
        </IonRow>
    </>

}


export const Modal_3_1_WriteArticle = ({dismiss, onResult, reloadParent = null, Item_圖片生成 = null, PhotoSet = null}: {
    dismiss: any,
    onResult: any,
    reloadParent?: any,  //[opt]
    Item_圖片生成?: any, //[opt]
    PhotoSet?: any, //[opt]
}) => {

    const router = useIonRouter();

    // useEffect(() => {
    //     setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    // }, []);

    //#region Back button
    useEffect(() => {
        let callback: any = (ev) => {
            // ev.detail.register(10, () => {
            //     console.log('Handler was called!');
            // });

            dismiss();
        };

        document.addEventListener('ionBackButton', callback);
        // Remove listener on unmount
        return () => document.removeEventListener("ionBackButton", callback);
    }, []);
    //#endregion


    //#region Data Loading
    //const {tokenId}: any = useParams();

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_3_1__Write({
                //p1: tokenId,
            });
            handleFetchCommonErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_3_1__Write({
            //p1: tokenId,
        });
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_3_1__Write_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const List_TokenEnw = hasLoaded ? pgData.Root.List_TokenEnw : undefined;

    //#endregion


    const fields = useLoginFields();
    let keyedFields = indexBy(fields, 'id');


    return <>
        <IonHeader>
            <IonToolbar>
                <IonTitle>新增貼文</IonTitle>
                <IonButtons slot="start">
                    <IonButton onClick={dismiss}>
                        <IonIcon size="large" icon={chevronBack} style={{marginLeft: "-0.7rem"}}/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol>

                    </IonCol>
                </IonRow>

                {hasLoaded &&
                    <RenderForm router={router} List_TokenEnw={List_TokenEnw} Item_圖片生成={Item_圖片生成} PhotoSet={PhotoSet} onResult={onResult} reload={reload} dismiss={dismiss}/>
                }

            </IonGrid>
        </IonContent>
    </>;
};
