//  Side Menus
import {tab1SideMenu, tab2SideMenu, tab3SideMenu} from "../sideMenu/PageSideMenus";

//  Main tab children
//import Settings from "../../pages/Settings";

//  Sub pages
import Pg_1_1 from "../../pages/Pg_1_1";

//	Tab icons
import {personOutline, mailOutline, mapOutline, shirtOutline, storefrontOutline, gitCompareOutline, giftOutline, imagesOutline, briefcaseOutline} from "ionicons/icons";

//  Import custom tab menu
import TabPane from "./TabPane";
import SubRoutes from "./SubRoutes";
import Pg_2_1 from "../../pages/Pg_2_1";
import Pg_3_1 from "../../pages/Pg_3_1";
import Pg_4_2 from "../../pages/Pg_4_2";
import Pg_5_1 from "../../pages/Pg_5_1";
import Pg_1_2 from "../../pages/Pg_1_2";
import Pg_1_5 from "../../pages/Pg_1_5";
import Pg_1_6 from "../../pages/Pg_1_6";
import Pg_1_12 from "../../pages/Pg_1_12";
import Pg_2_3 from "../../pages/Pg_2_3";
import Pg_2_13 from "../../pages/Pg_2_13";
import Pg_5_2 from "../../pages/Pg_5_2";
import Pg_5_3 from "../../pages/Pg_5_3";
import Pg_5_5 from "../../pages/Pg_5_5";
import Pg_5_7 from "../../pages/Pg_5_7";
import Pg_A_0 from "../../pages/Pg_A_0";
import Pg_A_1 from "../../pages/Pg_A_1";
import Pg_C_1 from "../../pages/Pg_C_1";
import Pg_4_4 from "../../pages/Pg_4_4";
import Pg_3_11 from "../../pages/Pg_3_11";
import Pg_0_1 from "../../pages/Pg_0_1";
import Pg_0_2 from "../../pages/Pg_0_2";
import Pg_0_3 from "../../pages/Pg_0_3";
import Pg_0_18 from "../../pages/Pg_0_18";
import Pg_0_19 from "../../pages/Pg_0_19";
import Pg_0_20 from "../../pages/Pg_0_20";
import Pg_B_1 from "../../pages/Pg_B_1";
import Pg_1_6_1 from "../../pages/Pg_1_6_1";
import {lazy} from "react";
import Pg_0_22 from "../../pages/Pg_0_22";
import Pg_0_24 from "../../pages/Pg_0_24";
import Test from "../../pages/Test";
const Viewer3d = lazy(() => import("../../pages/Viewer3d"));
const RealityViewer3d = lazy(() => import("../../pages/RealityViewer3d"));

//	Array of objects representing tab pages
//  These will be the main tabs across the app

//  *   PARAMS per tab object   *
//  isTab = true will make the tab appear
//  default = the default tab page to open and be redirected to at "/"
//  NOTE: there should only be one default tab (default: true)
//  label = the label to show with the tab
//  component = the component related to this tab page
//  icon = icon to show on the tab bar menu
//  path = the path which the tab is accessible
export const tabRoutes = [
    // { label: "Profile", component: Pg_1_1, icon: personOutline, path: "/tabs/tab1", default: true, isTab: true, sideMenu: true, sideMenuOptions: tab1SideMenu },
    // { label: "Inbox", component: Pg_2_1, icon: mailOutline, path: "/tabs/tab2", default: false, isTab: true, sideMenu: true, sideMenuOptions: tab2SideMenu },
    // { label: "Places", component: Pg_3_1, icon: mapOutline, path: "/tabs/tab3", default: false, isTab: true, sideMenu: true, sideMenuOptions: tab3SideMenu }
    {
        path: "/tabs/1_1",
        icon: storefrontOutline,
        component: Pg_1_1,
        default: true,
        isTab: true,
        label: "新品商店",
        sideMenu: true,
        sideMenuOptions: tab1SideMenu,
    },
    {
        path: "/tabs/2_1",
        icon: briefcaseOutline,
        component: Pg_2_1,
        default: false,
        isTab: true,
        label: "個人收藏",
        sideMenu: true,
        sideMenuOptions: tab1SideMenu,
    },
    {
        path: "/tabs/3_1",
        icon: imagesOutline,
        component: Pg_3_1,
        default: false,
        isTab: true,
        label: "社群貼文",
        sideMenu: true,
        sideMenuOptions: tab1SideMenu,
    },
    {
        path: "/tabs/4_2",
        icon: giftOutline,
        component: Pg_4_2,
        default: false,
        isTab: true,
        label: "領取獎勵",
        sideMenu: true,
        sideMenuOptions: tab1SideMenu,
    },
    {
        path: "/tabs/5_1",
        icon: gitCompareOutline,
        component: Pg_5_1,
        default: false,
        isTab: true,
        label: "交易市集",
        sideMenu: true,
        sideMenuOptions: tab1SideMenu,
    },


];

//  Array of objects representing children pages of tabs

//  *   PARAMS per tab object   *
//  isTab = should always be set to false for these
//  component = the component related to this tab page
//  path = the path which the tab is accessible

//  These pages should be related to tab pages and be held within the same path
//  E.g. /tabs/tab1/child
const tabChildrenRoutes = [
    //{ component: Pg_1_1, path: "/tabs/tab2/:id", isTab: false },

    {component: Pg_0_2, path: "/tabs/0_2", isTab: false},
    {component: Pg_0_3, path: "/tabs/0_3", isTab: false},
    {component: Pg_0_18, path: "/tabs/0_18", isTab: false},
    {component: Pg_0_19, path: "/tabs/0_19", isTab: false},
    {component: Pg_0_20, path: "/tabs/0_20", isTab: false},
    {component: Pg_0_22, path: "/tabs/0_22", isTab: false},
    {component: Pg_0_24, path: "/tabs/0_24", isTab: false},

    {component: Pg_1_2, path: "/tabs/1_2/:contractId", isTab: false},
    {component: Pg_1_5, path: "/tabs/1_5/:tokenId", isTab: false},
    {component: Pg_1_6, path: "/tabs/1_6/:tokenId", isTab: false},
    {component: Pg_1_6_1, path: "/tabs/1_6_1", isTab: false},
    {component: Pg_1_12, path: "/tabs/1_12/:tokenId", isTab: false},


    {component: Pg_2_3, path: "/tabs/2_3/:tokenEnwId", isTab: false},
    {component: Pg_2_13, path: "/tabs/2_13", isTab: false},

    {component: Pg_3_11, path: "/tabs/3_11/:postId", isTab: false},

    {component: Pg_4_2, path: "/tabs/4_2/:contractId", isTab: false},
    {component: Pg_4_4, path: "/tabs/4_4/:contractBonusId", isTab: false},

    {component: Pg_5_2, path: "/tabs/5_2/:tokenId", isTab: false},
    {component: Pg_5_3, path: "/tabs/5_3/:tokenEnwEntrustId", isTab: false},
    {component: Pg_5_5, path: "/tabs/5_5", isTab: false},
    {component: Pg_5_7, path: "/tabs/5_7", isTab: false},
];

//  Array of objects representing sub pages

//  *   PARAMS per tab object   *
//  component = the component related to this sub page
//  path = the path which the sub page is accessible

//  This array should be sub pages which are not directly related to a tab page
//  E.g. /child
export const subPageRoutes = [
    // { component: Pg_1_1, path: "/settings" },
    {component: Pg_A_0, path: "/A_0", sideMenu: false},
    {component: Pg_A_1, path: "/A_1", sideMenu: false},
    {component: Pg_B_1, path: "/B_1", sideMenu: false},
    {component: Pg_C_1, path: "/C_1", sideMenu: false},
    {component: Pg_0_1, path: "/0_1", sideMenu: false},
    {component: Viewer3d, path: "/viewer", sideMenu: false},
    {component: Test, path: "/test", sideMenu: false},
    {component: RealityViewer3d, path: "/realityviewer", sideMenu: false},
];

//  Let's combine these together as they need to be controlled within the same IonRouterOutlet
//const tabsAndChildrenRoutes = [ ...tabRoutes, ...tabChildrenRoutes ];


export const tabsAndChildrenRoutes = [...tabRoutes, ...tabChildrenRoutes];

//  Render sub routes
export const SubPages = () => (<SubRoutes routes={subPageRoutes}/>);

//	Render tab menu
export const Tabs = () => (<TabPane tabs={tabsAndChildrenRoutes} position="bottom"/>);
