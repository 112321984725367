import {IonInput, IonLabel, IonSelect, IonSelectOption, IonTextarea} from "@ionic/react";
import styles from "./CustomField.module.scss";
import {Fragment} from "react";
import styles_Pg_C_1 from "../pages/Pg_C_1.module.scss";

type Props = {
    field: any
    errors: any

    // children?: any
    noLabel?: boolean
    alternateLabel?: any
    className?: any
    inputClassName?: any
    alternateRightZone?: any
    alternateLeftZone?: any
    mainZone?: any
    disabled?: boolean
    textType?: string
};

const CustomField = ({
                         field,
                         errors,

                         // children = null,
                         noLabel = false,
                         className = null,
                         inputClassName = "",
                         alternateLabel = null,
                         alternateRightZone = null,
                         alternateLeftZone = null,
                         mainZone = null,

                         disabled = false,
                         textType = 'text',
                     }: Props) => {
    if (!field) {
        return <>(ERROR:field)</>;
    }

    const error = errors && errors.filter(e => e.id === field.id)[0];
    const errorMessage = error && errors.filter(e => e.id === field.id)[0].message;

    if (field?.nodisplay === true) {
        return null;
    }

    let sidePlacedInput: any = <Fragment>
        {textType === 'textarea' ? <>
            <IonTextarea className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} {...{'disabled': (disabled ? 'disabled' : null)}} />
        </> : <>
            <IonInput className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} {...{'disabled': (disabled ? 'disabled' : null)}} />
        </>}
    </Fragment>;

    let mainPlacedInput: any = null;
    if (mainZone !== null) {
        mainPlacedInput = <Fragment>
            {mainZone}
        </Fragment>;
    } else {
        mainPlacedInput = <Fragment>
            {textType === 'textarea' ? <>
                <IonTextarea className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} disabled={(disabled ? 'disabled' : null)}/>
                {alternateRightZone}
            </> : <>
                <IonInput className={`${styles.customInput} ${inputClassName}`} {...field.input.props} {...field.input.state} disabled={(disabled ? 'disabled' : null)}/>
                {alternateRightZone}
            </>}

        </Fragment>
    }
    if (alternateLeftZone !== null || alternateRightZone !== null) {
        sidePlacedInput = <Fragment>
            <div className={styles.rowsContainer}>
                {alternateLeftZone}
                {mainPlacedInput}
            </div>
        </Fragment>;
    } else {
        sidePlacedInput = <Fragment>
            {mainPlacedInput}
        </Fragment>;
    }

    return (
        <>
            <div className={className}>
                <div className={styles.field}>
                    {/*{!noLabel && (alternateLabel ||*/}
                    {/*    <IonLabel>*/}
                    {/*        {field.label}*/}
                    {/*        {error && <p className="animate__animated animate__bounceIn">{errorMessage}</p>}*/}
                    {/*    </IonLabel>*/}
                    {/*)}*/}
                    {!noLabel && error && (
                        <IonLabel>
                            <span>&nbsp;</span>
                            {<p className="animate__animated animate__bounceIn">{errorMessage}</p>}
                        </IonLabel>
                    )}
                    {sidePlacedInput}
                </div>
            </div>
        </>
    );
};

export default CustomField;
