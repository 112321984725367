import {IonAvatar, IonBadge, IonButton, IonButtons, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, useIonRouter, useIonToast} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {Pg_A_1_Store, push_pg_A_1__Login, push_pg_A_1__Logout} from "../store/Pg_A_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {useEffect, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
import {fetch_pg_1_2} from "../store/Pg_1_Store";
import {useAppUtil, useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import {personCircle} from "ionicons/icons";
import CustomField from "../components/CustomField";
import {useParams} from "react-router";
import {useFormInput, validateForm} from "../_/useForms";
import {Action} from "../components/Action";
import {IconWave} from "../components/IconWave";

import styles from './Pg_A_0.module.scss';
import indexBy from 'underscore/modules/indexBy.js';
import {ActionLink} from "../components/ActionLink";
import {navToPg_1_1, navToPg_B_1, navToPg_C_1} from "./Utils";
import queryString from "query-string";

export const useLoginFields = () => {
    return [
        {
            id: "email",
            label: "電子郵件",
            required: true,
            input: {

                props: {
                    type: "email",
                    placeholder: "電子郵件",
                    autocomplete: "username",
                },
                state: useFormInput("")
            }
        },
        {
            id: "password",
            label: "密碼",
            required: true,
            input: {

                props: {
                    type: "password",
                    placeholder: "密碼",
                    autocomplete: "password"
                },
                state: useFormInput("")
            }
        }
    ];
}

const Pg_A_1 = (props) => {
    const pageId = "pg_a_1";
    const pageName = "pg_a_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    let {redirTo = null}: {
        redirTo: string | null,
    } = queryString.parse(window.location.search) as any;

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    useEffect(() => {
        //fetch_Pg_A_1();
    }, []);

    useEffect(() => {
        if (props.location.pathname === "/tabs/1_1") {
            setSideMenu({options: sideMenuOptions, side: "start", pageName: pageName});
        }
    }, [props.location]);

    //const pgData = useStoreState(Pg_A_1_Store, getPageData);

    //const saved = useStoreState(QuoteStore, getSavedQuotes);
    const [amountLoaded, setAmountLoaded] = useState(20);

    const fetchMore = async e => {
        setAmountLoaded(amountLoaded => amountLoaded + 20);
        e.target.complete();
    }


    //#region push login
    const params = useParams();

    const fields = useLoginFields();
    const [errors, setErrors] = useState(false);


    const appUtil = useAppUtil();

    const push_login = async () => {
        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {
            //  Submit your form here
            let keyedFields = indexBy(fields, 'id');
            const username = fields && (keyedFields.email as any).input.state.value;
            const password = fields && (keyedFields.password as any).input.state.value;
            await appUtil.toast.present('登入中...', 10000)
            if (await push_pg_A_1__Login({
                p1: username,
                p2: password,
            })) {
                await appUtil.toast.dismiss();
                await appUtil.toast.present('登入成功', 1000)

                if (redirTo != null) {
                    window.location.replace(redirTo);
                } else {
                    navToPg_1_1(router);
                }
            } else {
                await appUtil.toast.dismiss();
                await appUtil.toast.present('登入失敗', 1000)
            }
        }
    }
    //#endregion

    //#region push logout
    const push_logout = async () => {
        await appUtil.toast.present('登出中...', 10000)
        if (await push_pg_A_1__Logout({})) {
            await appUtil.toast.dismiss();
            await appUtil.toast.present('登出成功', 1000)
            navToPg_1_1(router);
        }
    }
    //#endregion

    const click_forgetPassword = async () => {
        navToPg_B_1(router);
    }

    const click_registerNewAccount = async () => {
        navToPg_C_1(router);
    }

    //#endregion

    // useEffect(() => {
    //
    //     return () => {
    //
    //         fields.forEach(field => field.input.state.reset(""));
    //         setErrors(false);
    //     }
    // }, [params]);

    //const List_合約 = pgData?.Root?.List_合約 ?? [];
    //const categories = Object.keys(productInfo);

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} showLargeHeader={false} sideMenu={false} backButton={true}>
                {/*<PageHeader count={sideMenuOptions.length} pageName={pageName}/>*/}

                <IonGrid className="ion-padding">
                    <IonRow>
                        <IonCol size="12" className={styles.headingText}>
                            <IonCardTitle>Log in</IonCardTitle>
                            <h5>請先登入您的LABIE.io帳號，登入後才能使用網站完整功能！</h5>
                        </IonCol>
                    </IonRow>

                    <IonRow className="ion-margin-top ion-padding-top">
                        <IonCol size="12">

                            {fields.map(field => {
                                return <CustomField key={field.id} field={field} errors={errors}/>;
                            })}

                            <IonButton className="custom-button" expand="block" onClick={push_login}>登入</IonButton>

                            <div className="ion-text-center ion-margin-top ion-padding-top">
                                <ActionLink link={`/B_1`}><IonText>忘記密碼</IonText></ActionLink> | <ActionLink link={`/C_1`}><IonText>註冊帳號</IonText></ActionLink>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                {/*{(showModal && modalOptions) &&*/}
                {/*    <Modal showModal={showModal} modalOptions={modalOptions} close={() => setShowModal(false)}/>*/}
                {/*}*/}
            </CustomPage>

            {/*<IonFooter>*/}
            {/*    <IonGrid className="ion-no-margin ion-no-padding">*/}
            {/*        <Action message="Don't have an account?" text="Sign up" link="/C_1"/>*/}
            {/*        <IconWave/>*/}
            {/*    </IonGrid>*/}
            {/*</IonFooter>*/}
        </IonPage>
    );
};

export default Pg_A_1;
