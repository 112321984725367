import {IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_2_1, fetch_pg_2_3, Pg_2_1_Store, Pg_2_3_Store} from "../store/Pg_2_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import React, {useEffect, useRef, useState, Suspense, lazy} from "react";
import {checkmarkOutline, chevronDownCircleOutline, copyOutline} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import {Simulate} from "react-dom/test-utils";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {TokenEnwDetailItem} from "../components/TokenEnwDetailItem";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import styles_TokenItem from "../components/TokenItem.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import {ActionLink} from "../components/ActionLink";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleFetchCommonErrors, navToPg_2_1} from "./Utils";
import styles_2_3 from './Pg_2_3.module.scss';

import {CallMe} from "../my-lib.mjs";
import Loading from "./Loading";
import {Modal_3_11_WriteComment} from "./Modal_3_11_WriteComment";
import {Modal_2_3_Transfer} from "./Modal_2_3_Transfer";
import {Modal_2_3_OpenCamera} from "./Modal_2_3_OpenCamera";
import {Modal_2_3_Entrust} from "./Modal_2_3_Entrust";
import {AuthStore} from "../store/Common_Store";
import styles_Modal_2_3_OpenCamera from "./Modal_2_3_OpenCamera.module.scss";

const Viewer3d = lazy(() => import("./Viewer3d"));
//const MyComponent = lazy(() => import("./MyComponent"));

const Pg_2_3 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_2_3";
    const pageId = "Pg_2_3";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    ///會員
    const authStore = useStoreState(AuthStore, getPageData);
    const hasAuthed = authStore?.signBy ?? false;
    const Item_會員 = hasAuthed ? authStore?.AuthData : undefined;

    //#region Data Loading
    const {tokenEnwId}: any = useParams();

    const [hideIframe, setHideIframe] = useState(false);

    useIonViewWillEnter(() => {
        //console.log('ionViewWillEnter event fired');
        setHideIframe(false);
    });

    useIonViewWillLeave(() => {
        //console.log('ionViewWillLeave event fired');
        setHideIframe(true);
    });

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_2_3({
                p1: tokenEnwId,
            });
            handleFetchCommonErrors(router, errorObj);
        })();
    }, [tokenEnwId]);


    let reload = async (event: CustomEvent<RefresherEventDetail> | null) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_2_3({
            p1: tokenEnwId,
        });
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_2_3_Store, getPageData);
    const [amountLoaded, setAmountLoaded] = useState(20);
    const [selectedProduct, setSelectedProduct] = useState(false);

    let hasLoaded = pgData?.Root ?? false;
    const Item_TokenEnw = hasLoaded ? pgData.Root.Item_TokenEnw : undefined;

    if (hasLoaded && (tokenEnwId != Item_TokenEnw?.TokenEnwId)) {
        hasLoaded = false;
    }

    //#endregion

    const [presentWebModal_trasnfer, dismissWebModal_transfer] = useIonModal(Modal_2_3_Transfer, {
        dismiss: () => dismissWebModal_transfer(),
        tokenEnwId: tokenEnwId,
        //set: (val) => setQRData(val),
        onResult: (result) => {
            navToPg_2_1(router);
            if (result) {
                reload(null);
            }
        },
        //product: selectedProduct
    });

    const [presentWebModal_opencamera, dismissWebModal_opencamera] = useIonModal(Modal_2_3_OpenCamera, {
        dismiss: () => dismissWebModal_opencamera(),
        tokenEnwId: tokenEnwId,
        //set: (val) => setQRData(val),
        onResult: (result) => {
            navToPg_2_1(router);
            if (result) {
                reload(null);
            }
        },
        //product: selectedProduct
    });

    const start_opencamera = async () => {
        // const platforms = getPlatforms();
        // const isWeb = (platforms.includes("desktop") || platforms.includes("mobileweb") || platforms.includes("pwa"));
        //
        // if (!isWeb) {
        //     const data = await BarcodeScanner.scan();
        //     if (data) {
        //         handleSuccess(data);
        //     }
        // } else {
        //     presentWebModal_trasnfer({
        //         presentingElement: pageRef.current
        //     });
        // }

        presentWebModal_opencamera({
            //cssClass: "product-modal",
            presentingElement: pageRef.current,
            initialBreakpoint: 0.50,
            breakpoints: [0, 0.50],
        });
    }

    const start_transfer = async () => {
        // const platforms = getPlatforms();
        // const isWeb = (platforms.includes("desktop") || platforms.includes("mobileweb") || platforms.includes("pwa"));
        //
        // if (!isWeb) {
        //     const data = await BarcodeScanner.scan();
        //     if (data) {
        //         handleSuccess(data);
        //     }
        // } else {
        //     presentWebModal_trasnfer({
        //         presentingElement: pageRef.current
        //     });
        // }

        if (hasAuthed && Item_會員.Is啟用移轉) {
            presentWebModal_trasnfer({
                //cssClass: "product-modal",
                presentingElement: pageRef.current,
                initialBreakpoint: 0.95,
                breakpoints: [0, 0.25, 0.5, 0.75, 0.95],
            });
        } else {
            alert('遵循所在地法規，暫不開放移轉功能！');
        }
    }

    const [presentWebModal_entrust, dismissWebModal_entrust] = useIonModal(Modal_2_3_Entrust, {
        dismiss: () => dismissWebModal_entrust(),
        tokenEnwId: tokenEnwId,
        //set: (val) => setQRData(val),
        onResult: (result) => {
            if (result === true) {
                reload(null);
            }
        },
        //product: selectedProduct
    });

    const start_entrust = async () => {
        // const platforms = getPlatforms();
        // const isWeb = (platforms.includes("desktop") || platforms.includes("mobileweb") || platforms.includes("pwa"));
        //
        // if (!isWeb) {
        //     const data = await BarcodeScanner.scan();
        //     if (data) {
        //         handleSuccess(data);
        //     }
        // } else {
        //     presentWebModal_trasnfer({
        //         presentingElement: pageRef.current
        //     });
        // }

        if (hasAuthed && Item_會員.Is啟用二手市場) {
            presentWebModal_entrust({
                //cssClass: "product-modal",
                presentingElement: pageRef.current,
                initialBreakpoint: 0.95,
                breakpoints: [0, 0.25, 0.5, 0.75, 0.95],
            });
        } else {
            alert('遵循所在地法規，暫不開放委託交易功能！');
        }
    }

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>


                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                <Suspense fallback={<Loading/>}>
                    {hasLoaded &&
                        <>
                            <IonGrid key={Item_TokenEnw.TokenEnwId}>
                                {/*<IonRow>*/}
                                {/*    <IonCol>*/}
                                {/*        /!*<Viewer3d AnimationUrl={Item_TokenEnw._Token.AnimationUrl}/>*!/*/}
                                {/*        {hideIframe ||*/}
                                {/*            <iframe src={`/viewer?sm=1&url=${Item_TokenEnw._Token.AnimationUrl}`} className={styles_2_3.viewerframe}></iframe>*/}
                                {/*        }*/}
                                {/*        /!*<div className={styles_TokenItem.coverImg}>*!/*/}
                                {/*        /!*    {Ln_TokenPictureSetCoverImg({*!/*/}
                                {/*        /!*        圖片組: Item_TokenEnw._Token.圖片組,*!/*/}
                                {/*        /!*        名稱: 'cover',*!/*/}
                                {/*        /!*    })}*!/*/}
                                {/*        /!*</div>*!/*/}
                                {/*    </IonCol>*/}
                                {/*</IonRow>*/}
                                <IonRow>
                                    <IonCol>
                                        {hideIframe ||
                                            <iframe src={`/realityviewex/?src=${Item_TokenEnw._Token.AnimationUrl ? (window.location.origin + "/" + Item_TokenEnw._Token.AnimationUrl) : ""}&use=${Item_TokenEnw._Token.ArHasActions ? "" : "noactions"}&usdz=${Item_TokenEnw._Token.ArUsdzFile ? (window.location.origin + "/" + Item_TokenEnw._Token.ArUsdzFile) : ""}&poster=${Item_TokenEnw._Token.ArPosterFile ?? ""}&skybox=${Item_TokenEnw._Token.ArSkyboxFile ?? ""}&skyboxheight=${Item_TokenEnw._Token.ArSkyboxHeight ?? ""}&hasxr=true`} className={styles_2_3.viewerframe}></iframe>
                                        }
                                    </IonCol>
                                </IonRow>
                                {/*<IonCardContent>*/}
                                {/*</IonCardContent>*/}
                                <IonGrid>
                                    <IonRow>
                                        <IonCol className="ion-justify-content-center ion-text-center w100">
                                            {/*<div>*/}
                                            {/*    <ActionLink link={`/tabs/1_12/${'1'}`} noLink={true}>*/}
                                            {/*        <IonButton fill={"solid"} onClick={(e) => {*/}
                                            {/*            e.preventDefault();*/}
                                            {/*            e.stopPropagation();*/}

                                            {/*            start_opencamera();*/}

                                            {/*            return false;*/}
                                            {/*        }} expand="block">*/}
                                            {/*            使用元宇宙相機開啟*/}
                                            {/*        </IonButton>*/}
                                            {/*    </ActionLink>*/}
                                            {/*</div>*/}
                                            {/*<div>*/}
                                            {/*    <ActionLink link={`//openapi/1/realityview3d_webxr?tokenEnwId=${tokenEnwId}`} target="_blank">*/}
                                            {/*        <IonButton fill={"solid"} expand="block">*/}
                                            {/*            使用WebAR開啟*/}
                                            {/*        </IonButton>*/}
                                            {/*    </ActionLink>*/}
                                            {/*</div>*/}
                                            <div>
                                                <ActionLink link={`/tabs/1_12/${'1'}`}>
                                                    <IonButton fill={"solid"} onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        start_entrust();

                                                        return false;
                                                    }} expand="block">
                                                        委託市集交易
                                                    </IonButton>
                                                </ActionLink>
                                            </div>
                                            <div>
                                                <ActionLink link={`/tabs/1_12/${'1'}`}>
                                                    <IonButton fill={"solid"} onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        start_transfer();

                                                        return false;
                                                    }} expand="block">
                                                        轉移給他人
                                                    </IonButton>
                                                </ActionLink>
                                            </div>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonGrid>

                            <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                                <IonRow>
                                    <IonCol size="12">
                                        <IonText color="dark">
                                            <p className="title">{Item_TokenEnw._Token.名稱}</p>
                                            <div className={styles_2_3.content} dangerouslySetInnerHTML={{__html: Item_TokenEnw._Token.介紹}}/>
                                            {/*<p>{Item_TokenEnw._Token.介紹}</p>*/}
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                <IonGrid className="ion-padding">
                                    {/*<IonRow className={ styles.searchContainer }>*/}
                                    {/*    <IonCol size="12">*/}
                                    {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                    {/*    </IonCol>*/}
                                    {/*</IonRow>*/}


                                    {/*click={() => handleShowModal(item)}*/}
                                    <TokenEnwDetailItem Item_TokenEnw={Item_TokenEnw}/>
                                </IonGrid>
                            </IonGrid>
                        </>
                    }
                </Suspense>
            </CustomPage>
        </IonPage>
    );
};

export default Pg_2_3;
