import React, {useContext, useRef, useState} from "react";
import {useIonToast} from "@ionic/react";

// @ts-ignore
const SideMenuContext = React.createContext();
// @ts-ignore
const SideMenuUpdateContext = React.createContext();
// @ts-ignore
const AppUtilContext = React.createContext();

export function useSideMenuOptions() {
    return useContext(SideMenuContext);
}

export function useSideMenuUpdate() {
    return useContext(SideMenuUpdateContext);
}

export function useAppUtil() {
    return useContext(AppUtilContext) as any;
}

export function SideMenuProvider({ children }) {

    const [ sideMenuOptions, setSideMenuOptions ] = useState({
        options: {},
        side: "",
        pageName: "",
        disableSideMenu: false
    });

    const [present, dismiss] = useIonToast();

    //const [ sideMenuOptions, setSideMenuOptions ] = useRef({ options: [], side: "", pageName: "" });

    // const [, setForceRender] = useState<number>(0);
    // const forceRender = (triggerLoad = false) => {
    //     setForceRender(s => s + 1);
    // };

    //(window as any).testme2 = forceRender;

    const setSideMenu = (menuOptions) => {
        setSideMenuOptions(menuOptions);
        //forceRender();
    }

    const menuRef = useRef(null);

    return (
        <AppUtilContext.Provider value={{
            toast: {
                present,
                dismiss,
            },
            menu: {
                elementRef: menuRef,
            }
        }} >
            <SideMenuContext.Provider value={ sideMenuOptions }>
                <SideMenuUpdateContext.Provider value={ setSideMenu }>
                    {children}
                </SideMenuUpdateContext.Provider>
            </SideMenuContext.Provider>
        </AppUtilContext.Provider>
    );
}



