import {IonAvatar, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_Pg_1_1, Pg_1_1_Store, Pg_1_2_Store} from "../store/Pg_1_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
import {fetch_pg_1_2} from "../store/Pg_1_Store";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import Ln_AvaterPictureSetCoverImg from "./Ln_AvaterPictureSetCoverImg";
import Ln_ContractPictureSetCoverImg from "./Ln_ContractPictureSetCoverImg";
import {ActionLink} from "../components/ActionLink";
import {useParams} from "react-router";
import {chevronDownCircleOutline} from "ionicons/icons";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import styles_1_1 from './Pg_1_1.module.scss';
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleFetchCommonErrors} from "./Utils";

const Pg_1_1 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_1_1";
    const pageId = "Pg_1_1";
    const { sideMenuOptions } = props;
    const setSideMenu:any = useSideMenuUpdate();
    const sideMenu:any = useSideMenuOptions();

    // //#region Modal
    // const [ showModal, setShowModal ] = useState<any>(false);
    // const [ modalOptions, setModalOptions ] = useState<any>(false);
    //
    // const handleModal = async (index:any) => {
    //     await setModalOptions(tab1SideMenu[index]);
    //     setShowModal(true);
    // }
    // //#endregion

    // const [present, dismiss] = useIonModal(ProductModal, {
    //     dismiss: () => dismiss(),
    //     product: selectedProduct
    // });


    //#region Data Loading
    //const {contractId}: any = useParams();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_Pg_1_1();
            handleFetchCommonErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_Pg_1_1();
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    useEffect(() => {
        if (props.location.pathname === "/tabs/1_1") {
            setSideMenu({ options: sideMenuOptions, side: "start", pageName: pageName });
        }
    }, [ props.location ]);

    const fetchMore = async e => {
        setAmountLoaded(amountLoaded => amountLoaded + 20);
        e.target.complete();
    }

    const [amountLoaded, setAmountLoaded] = useState(20);

    const pgData = useStoreState(Pg_1_1_Store, getPageData);
    //const saved = useStoreState(QuoteStore, getSavedQuotes);

    const hasLoaded = pgData?.Root ?? false;
    const List_合約 = hasLoaded ? pgData.Root?.List_合約 : undefined;
    //#endregion


    // useIonViewWillEnter(() => {
    //     let selected = document.querySelector("ion-tab-bar");
    //     if (selected) {
    //         selected.style.display = "none";
    //     }
    // });
    //
    // useIonViewWillLeave(() => {
    //     let selected = document.querySelector("ion-tab-bar");
    //     if (selected) {
    //         selected.style.display = "";
    //     }
    // })

    //const categories = Object.keys(productInfo);

    return (
        <IonPage id={ pageId } >
            <CustomPage name={ pageName } sideMenu={ true } sideMenuPosition="start" backButton={false}>
                {/*<PageHeader count={ sideMenuOptions.length } pageName={ pageName } />*/}
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {/*animate__animated animate__slideInRight animate__faster*/}
                {/*ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom*/}
                {/*animate__animated animate__slideInRight animate__faster h100 ratio-inherit*/}
                {/*ion-padding*/}
                {/*ion-justify-content-center ion-text-center ion-margin-top*/}
                {/*ion-text-center ion-margin-top*/}


                {/*<IonItem lines="none">*/}
                {/*    <IonAvatar>*/}
                {/*        <IonImg src="/assets/alan.jpg" />*/}
                {/*    </IonAvatar>*/}
                {/*    <IonLabel className="ion-text-wrap ion-padding">*/}
                {/*        <h1>Author</h1>*/}
                {/*        <h2>*/}
                {/*            Alan Montgomery*/}
                {/*            <span className="role">*/}
				{/*				<IonBadge color="primary">Mobile Team Lead</IonBadge>*/}
				{/*			</span>*/}
                {/*        </h2>*/}
                {/*        <p>*/}
                {/*            Hey there, I'm Alan! Hopefully you can take something away from this little sample app. Or even if it's to have a poke around and see how I personally like to do things, that's OK too 👏🏻. Check out each page, side menu and have a look at how things work.*/}
                {/*        </p>*/}
                {/*    </IonLabel>*/}
                {/*</IonItem>*/}

                {hasLoaded &&
                    <>
                        {/*<IonGrid className="padlr16px">*/}
                        {/*    <IonList>*/}
                        {/*        <IonRow>*/}
                        {/*                <ActionLink link={`https://ar.labie.io/ufo001`} className={styles.categoryContainer} key={`Link`}>*/}
                        {/*                    <div className={styles.categoryInner}>*/}
                        {/*                        {Ln_ContractPictureSetCoverImg({*/}
                        {/*                            圖片組: `/Upload/ufo.webp`,*/}
                        {/*                            名稱: 'cover',*/}
                        {/*                        })}*/}
                        {/*                        <div><p>{capitalize(`UFO ! ?`)}</p></div>*/}
                        {/*                    </div>*/}
                        {/*                </ActionLink>*/}
                        {/*        </IonRow>*/}
                        {/*    </IonList>*/}
                        {/*</IonGrid>*/}

                        <IonGrid className="padlr16px">
                            <IonList>
                                <IonRow>
                                    {List_合約.map(item => (
                                        <ActionLink link={`/tabs/1_2/${item.合約Id}`} className={styles.categoryContainer} key={item.合約Id}>
                                            <div className={styles.categoryInner}>
                                                {Ln_ContractPictureSetCoverImg({
                                                    圖片組: item.圖片組,
                                                    名稱: 'cover',
                                                })}
                                                <div><p>{capitalize(item.Name)}</p></div>
                                            </div>
                                        </ActionLink>
                                        // <IonButton key={c} routerLink={`/categories/${c.toLowerCase()}`}>{capitalize(c)}</IonButton>
                                    ))}
                                </IonRow>
                            </IonList>
                        </IonGrid>
                    </>
                }



                {/*<IonGrid>*/}
                {/*    <IonRow className="ion-text-center">*/}
                {/*        <IonCol size="12">*/}
                {/*            <IonText color="primary">*/}
                {/*                <p>Contact me on twitter if you need anything else :)</p>*/}
                {/*                <a href="https://twitter.com/intent/tweet?screen_name=93alan&ref_src=twsrc%5Etfw" className="twitter-mention-button" data-size="large" data-related="93alan,93alan" data-dnt="true" data-show-count="false">Tweet to @93alan</a>*/}
                {/*            </IonText>*/}
                {/*        </IonCol>*/}
                {/*    </IonRow>*/}

                {/*    <IonRow className="ion-text-center">*/}
                {/*        <IonCol size="12">*/}
                {/*            <IonText>*/}
                {/*                <h4>Check out Mobile DevCast</h4>*/}
                {/*                <p>A podcast dedicated to mobile app development and web native technology like ionic & capacitor!</p>*/}
                {/*                <IonText color="warning">*/}
                {/*                    <a style={{ color: "yellow" }} href="https://mobiledevcast.com" target="_blank" rel="noreferrer">https://mobiledevcast.com</a>*/}
                {/*                </IonText>*/}
                {/*            </IonText>*/}
                {/*        </IonCol>*/}
                {/*    </IonRow>*/}
                {/*</IonGrid>*/}

                {/*{ (showModal && modalOptions) &&*/}
                {/*    <Modal showModal={ showModal } modalOptions={ modalOptions } close={ () => setShowModal(false) } />*/}
                {/*}*/}
            </CustomPage>

            {/*<IonHeader>*/}
            {/*    <IonToolbar>*/}
            {/*        <IonButtons slot="start">*/}
            {/*            <IonMenuButton />*/}
            {/*        </IonButtons>*/}
            {/*        <IonTitle>1_1 新品商店</IonTitle>*/}
            {/*    </IonToolbar>*/}
            {/*</IonHeader>*/}
            {/*<IonContent fullscreen>*/}
            {/*    /!*<IonHeader collapse="condense">*!/*/}
            {/*    /!*    <IonToolbar>*!/*/}
            {/*    /!*        <IonTitle size="large" className="page-title">*!/*/}
            {/*    /!*            <IonLabel>ionic</IonLabel>*!/*/}
            {/*    /!*            <IonNote>shop</IonNote>*!/*/}
            {/*    /!*        </IonTitle>*!/*/}
            {/*    /!*    </IonToolbar>*!/*/}
            {/*    /!*</IonHeader>*!/*/}
            {/*    <IonHeader collapse="condense">*/}
            {/*        <IonToolbar>*/}
            {/*            <IonTitle size="large">1_1 新品商店</IonTitle>*/}
            {/*        </IonToolbar>*/}
            {/*    </IonHeader>*/}

            {/*    <IonGrid>*/}
            {/*        <IonList>*/}
            {/*            <IonRow>*/}
            {/*                {List_合約.map(item => (*/}
            {/*                    <IonRouterLink routerLink={`/1_2/${item.合約Id}`} className={styles.categoryContainer}>*/}
            {/*                        <div className={styles.categoryInner}>*/}
            {/*                            <img src={"https://via.placeholder.com/300x90.png?text=ContractImage"} alt="cover"/>*/}
            {/*                            <div><p>{capitalize(item.Name)}</p></div>*/}
            {/*                        </div>*/}
            {/*                    </IonRouterLink>*/}
            {/*                // <IonButton key={c} routerLink={`/categories/${c.toLowerCase()}`}>{capitalize(c)}</IonButton>*/}
            {/*                    ))}*/}
            {/*            </IonRow>*/}
            {/*        </IonList>*/}
            {/*    </IonGrid>*/}
            {/*</IonContent>*/}

        </IonPage>
    );
};

export default Pg_1_1;
