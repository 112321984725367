import {useRef, useState} from "react";

export const useFormInput = (initialValue = "",
                             hookOnChange: any = null) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = async e => {
        const tempValue = await e.currentTarget.value;
        setValue(tempValue);

        if (hookOnChange) {
            hookOnChange(tempValue, initialValue);
        }
    }

    // const handleOnKeyUp = async e => {
    //     const tempValue = await e.currentTarget.value;
    //     setValue(tempValue);
    // }

    return {
        initialValue,
        value,
        reset: (newValue) => {
            setValue(newValue);
        },
        reset_WithEndCallbacks: (newValue) => {
            const tempValue = newValue;
            setValue(newValue);

            if (hookOnChange) {
                hookOnChange(tempValue, initialValue);
            }
        },
        onIonChange: handleChange,
        onKeyUp: handleChange
    };
}

export const validateForm = fields => {
    let errors: any = [];

    fields.forEach(field => {
        if (field.required) {
            const fieldValue = field.input.state.value;
            if (fieldValue === "") {

                const error: any = {
                    id: field.id,
                    message: `[${field.label}] 需填寫`,
                };
                errors.push(error);
            }
        }
    });

    return errors;
}
