import {IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {fetch_pg_3_11, Pg_3_11_Store} from "../store/Pg_3_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {chevronDownCircleOutline, copyOutline} from "ionicons/icons";
import {useParams} from "react-router";
import {TokenItem} from "../components/TokenItem";
import {ProductModal} from "../components/ProductModal";
import {Simulate} from "react-dom/test-utils";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {Modal} from "./Modal";
import {fetch_pg_2_1, Pg_2_1_Store} from "../store/Pg_2_Store";
import queryString from 'query-string';
import {PostItem} from "../components/PostItem";
import styles_3_11 from "./Pg_3_11.module.scss";
import {TokenEnwEntrustListItem} from "../components/TokenEnwEntrustListItem";
import {PostReplyListItem} from "../components/PostReplyListItem";
import {PostDetailItem} from "../components/PostDetailItem";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleFetchCommonErrors} from "./Utils";
import {Modal_2_1} from "./Modal_2_1";
import {Modal_3_11_WriteComment} from "./Modal_3_11_WriteComment";

const Pg_3_11 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_3_11";
    const pageId = "Pg_3_11";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    const {postId}: any = useParams();

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_3_11({
                p1: postId,
            });
            handleFetchCommonErrors(router, errorObj);
        })();
    }, [postId]);

    let reload = async (event: CustomEvent<RefresherEventDetail> | null) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_3_11({
            p1: postId,
        });
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    let pgData = useStoreState(Pg_3_11_Store, getPageData);

    let hasLoaded = pgData?.Root ?? false;
    const List_文章 = hasLoaded ? pgData.Root.List_文章 : undefined;
    const List_留言 = hasLoaded ? pgData.Root.List_留言 : undefined;


    if (hasLoaded && (postId != List_文章?.[0]?.文章Id)) {
        hasLoaded = false;
    }

    const [presentWebModal, dismissWebModal] = useIonModal(Modal_3_11_WriteComment, {
        dismiss: () => dismissWebModal(),
        文章Id: postId,
        //set: (val) => setQRData(val),
        onResult: (result) => {
            if (result === true) {
                reload(null);
            }
        },
        //product: selectedProduct
    });

    const start = async () => {
        // const platforms = getPlatforms();
        // const isWeb = (platforms.includes("desktop") || platforms.includes("mobileweb") || platforms.includes("pwa"));
        //
        // if (!isWeb) {
        //     const data = await BarcodeScanner.scan();
        //     if (data) {
        //         handleSuccess(data);
        //     }
        // } else {
        //     presentWebModal({
        //         presentingElement: pageRef.current
        //     });
        // }

        presentWebModal({
            //cssClass: "product-modal",
            presentingElement: pageRef.current,
            initialBreakpoint: 0.50,
            breakpoints: [0, 0.25, 0.5, 0.75],
        });
    }


    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={true}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        {/*<IonGrid>*/}
                        {/*    <IonRow>*/}
                        {/*        <IonCol>*/}
                        {/*            /!*<IonButton fill={ !bookmarked ? "outline" : "solid" } onClick={ () => bookmarked ? removeSavedQuote(quote.id) : addSavedQuote(quote.id) }>*!/*/}
                        {/*            /!*    <IonIcon icon={ bookmarked ? checkmarkOutline : bookmarkOutline } />*!/*/}
                        {/*            /!*    &nbsp;{ bookmarked ? "Bookmarked" : "Save as Bookmark" }*!/*/}
                        {/*            /!*</IonButton>*!/*/}
                        {/*        </IonCol>*/}

                        {/*        /!*<IonCol size="4">*!/*/}
                        {/*        /!*    <IonButton fill="outline">*!/*/}
                        {/*        /!*        <IonIcon icon={copyOutline}/>*!/*/}
                        {/*        /!*        &nbsp;Copy Quote*!/*/}
                        {/*        /!*    </IonButton>*!/*/}
                        {/*        /!*</IonCol>*!/*/}
                        {/*    </IonRow>*/}
                        {/*</IonGrid>*/}

                        <IonGrid className="ion-padding-start ion-padding-end extra-padding">
                            <IonGrid>
                                {/*<IonRow className={ styles.searchContainer }>*/}
                                {/*    <IonCol size="12">*/}
                                {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                {/*    </IonCol>*/}
                                {/*</IonRow>*/}


                                {/*click={() => handleShowModal(item)}*/}
                                <IonRow>
                                    {List_文章.map((item, index) => {
                                        return <Fragment key={item.文章Id}>
                                            <PostDetailItem Item_文章={item} noLink={true}/>
                                        </Fragment>
                                    })}
                                </IonRow>
                            </IonGrid>
                        </IonGrid>
                        <div className={styles_3_11.line}></div>
                        <IonList>
                            {/*<IonItem lines="full">*/}
                            {/*    <div className={styles_3_11.listTitle} >*/}
                            {/*        <div className="l">*/}
                            {/*            <h1>{Item_Token.名稱} #{Item_Token.TokenId}</h1>*/}
                            {/*        </div>*/}
                            {/*        <div>{'價錢由低到高'}</div>*/}
                            {/*    </div>*/}
                            {/*</IonItem>*/}
                            {List_留言.map((item, index) => {
                                return <Fragment key={item.留言Id}>
                                    <IonItem className={styles_3_11.listItem} lines="full">
                                        <PostReplyListItem Item_留言={item} noLink={true}/>
                                    </IonItem>
                                </Fragment>;
                            })}
                        </IonList>
                        <IonRow className="ion-justify-content-center ion-text-center w100">
                            <IonCol>
                                <IonButton fill={"solid"} onClick={() => start()} expand="block">
                                    我要留言
                                </IonButton>
                            </IonCol>
                        </IonRow>

                    </>
                }

            </CustomPage>
        </IonPage>
    );
};

export default Pg_3_11;
