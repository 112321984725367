import {IonBadge, IonButton, IonCol, IonIcon, IonNote, IonRouterLink, IonRow} from "@ionic/react";
import styles from "./StoneTransactionRow.module.scss";
import {heartOutline, star} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";
import styles_Common from "../pages/Common.module.scss";
import moment from "moment/moment";

export const StoneTransactionRow = ({Item_會員錢包流水, fromHome = false}) => {
    // if (!Item_Token) {
    //     return <>(ERROR:Item_Token)</>;
    // }

    // let Item_Token: any = Item_TokenEnwItem?._Token;
    // if (!Item_Token) {
    //     return <>(ERROR:Item_Token)</>;
    // }

//
    return (

        <IonRow>
            <IonCol>{moment(Item_會員錢包流水.CreatedAt).format('YYYY/MM/DD hh:mm:ss A')}</IonCol>
            <IonCol>{Item_會員錢包流水.項目類別}</IonCol>
            <IonCol>{(Item_會員錢包流水.異動 < 0 ? "" : "+") + Item_會員錢包流水.異動}</IonCol>
            <IonCol>{Item_會員錢包流水.新Balance}</IonCol>
        </IonRow>

        // <ActionLink link={`/tabs/1_5/${Item_Token.TokenId}`}>
        //     <div className={styles.container}>
        //         <div className={styles.coverImg}>
        //             {Ln_TokenPictureSetCoverImg({
        //                 圖片組: Item_Token.圖片組,
        //                 名稱: 'cover',
        //             })}
        //         </div>
        //         {/*<div className={`${styles.stackBadge}`}>100+</div>*/}
        //         <div className={`${styles.productTitleContainer}`}>
        //             <div>
        //                 <h1 className={`${styles.productTitle} truncate`}>{Item_Token.名稱}</h1>
        //             </div>
        //
        //             <div className="last">
        //                 <div className="line"/>
        //                 <div className="lowestPrice">
        //                     {/*最低價格: {Item_Token.首次發行價格.toFixed(0)}*/}
        //                     &nbsp;
        //                 </div>
        //                 <div className="right">
        //                     <div className={styles.productRating}>
        //                         <IonButton fill="clear" className={styles_Common.actionButton} >
        //                             <IonIcon icon={ heartOutline } className={styles_Common.actionButtonIcon}></IonIcon>
        //                         </IonButton>
        //
        //                         {/*<IonNote>*/}
        //                         {/*    <IonIcon icon={heartOutline}/>*/}
        //                         {/*</IonNote>*/}
        //                         {/*<IonNote>*/}
        //                         {/*    {5}*/}
        //                         {/*</IonNote>*/}
        //                     </div>
        //                 </div>
        //             </div>
        //
        //         </div>
        //     </div>
        // </ActionLink>
    );
}
