import {IonAvatar, IonBadge, IonButton, IonButtons, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import {Pg_A_0_Store} from "../store/Pg_A_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
//import {fetch_pg_0_1} from "../store/Pg_A_Store";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import Ln_AvaterPictureSetCoverImg from "./Ln_AvaterPictureSetCoverImg";
import Ln_ContractPictureSetCoverImg from "./Ln_ContractPictureSetCoverImg";
import {Action} from "../components/Action";

import styles from './Pg_A_0.module.scss';

const Pg_0_1 = (props) => {
    const pageName = "pg_a_0";
    const pageId = "pg_a_0";
    const { sideMenuOptions } = props;
    const setSideMenu:any = useSideMenuUpdate();
    const sideMenu:any = useSideMenuOptions();

    const [ showModal, setShowModal ] = useState<any>(false);
    const [ modalOptions, setModalOptions ] = useState<any>(false);

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    const handleModal = async (index:any) => {
        await setModalOptions(tab1SideMenu[index]);
        setShowModal(true);
    }

    const pageRef = useRef();

    useEffect(() => {
        //fetch_Pg_0_1();
    }, []);

    useEffect(() => {
        // if (props.location.pathname === "/tabs/0_1") {
        //     setSideMenu({ options: sideMenuOptions, side: "start", pageName: pageName });
        // }
    }, [ props.location ]);

    const pgData = useStoreState(Pg_A_0_Store, getPageData);
    //const saved = useStoreState(QuoteStore, getSavedQuotes);
    const [amountLoaded, setAmountLoaded] = useState(20);

    const fetchMore = async e => {
        setAmountLoaded(amountLoaded => amountLoaded + 20);
        e.target.complete();
    }


    const List_合約 = pgData?.Root?.List_合約 ?? [];

    //const categories = Object.keys(productInfo);

    return (
        <IonPage id={ pageId } >
            <CustomPage name={ pageName } sideMenu={ false } sideMenuPosition="start" showLargeHeader={ true }>
                {/*<PageHeader count={ sideMenuOptions.length } pageName={ pageName } />*/}
                {/*<IonItem lines="none">*/}
                {/*    <IonAvatar>*/}
                {/*        <IonImg src="/assets/alan.jpg" />*/}
                {/*    </IonAvatar>*/}
                {/*    <IonLabel className="ion-text-wrap ion-padding">*/}
                {/*        <h1>Author</h1>*/}
                {/*        <h2>*/}
                {/*            Alan Montgomery*/}
                {/*            <span className="role">*/}
				{/*				<IonBadge color="primary">Mobile Team Lead</IonBadge>*/}
				{/*			</span>*/}
                {/*        </h2>*/}
                {/*        <p>*/}
                {/*            Hey there, I'm Alan! Hopefully you can take something away from this little sample app. Or even if it's to have a poke around and see how I personally like to do things, that's OK too 👏🏻. Check out each page, side menu and have a look at how things work.*/}
                {/*        </p>*/}
                {/*    </IonLabel>*/}
                {/*</IonItem>*/}

                <IonImg src="/assets/login2.jpeg" />

                <div className={ styles.getStarted }>
                    <IonGrid>
                        <IonRow className={ `ion-text-center ion-justify-content-center ${ styles.heading }` }>
                            <IonCol size="11" className={ styles.headingText }>
                                <IonCardTitle>Join millions of other people discovering their creative side</IonCardTitle>
                            </IonCol>
                        </IonRow>

                        <IonRow className={ `ion-text-center ion-justify-content-center` }>
                            <IonRouterLink routerLink="/signup" className="custom-link">
                                <IonCol size="11">
                                    <IonButton className={ `${ styles.getStartedButton } custom-button` }>Get started &rarr;</IonButton>
                                </IonCol>
                            </IonRouterLink>
                        </IonRow>
                    </IonGrid>
                </div>



                { (showModal && modalOptions) &&
                    <Modal showModal={ showModal } modalOptions={ modalOptions } close={ () => setShowModal(false) } />
                }
            </CustomPage>

            <IonFooter>
                <IonGrid>
                    <Action message="Already got an account?" text="Login" link="/A_1" />
                </IonGrid>
            </IonFooter>
        </IonPage>
    );
};

export default Pg_0_1;
