import {Route, withRouter} from "react-router-dom";
import {useEffect} from "react";

// let ScrollToTop: any = function ({history}) {
//     useEffect(() => {
//         const unlisten = history.listen(() => {
//             window.scrollTo(0, 0);
//         });
//         return () => {
//             unlisten();
//         }
//     }, []);
//
//     return (null);
// }
// ScrollToTop = withRouter(ScrollToTop);

const SubRoutes = (props) => {


    return (
        <>
            {props.routes.map((route, i) => {

                const RouteComponent = route.component;

                return <Route key={i} path={route.path} render={(props) =>
                    <RouteComponent {...props} sideMenu={route.sideMenu ? true : false} sideMenuOptions={route.sideMenuOptions ? route.sideMenuOptions : false}/>
                } exact={true}/>;
            })}
        </>
    );
}

export default SubRoutes;
