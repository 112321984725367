import { createSelector } from 'reselect';

const getState = state => state;

//  General getters
export const getFavourites = createSelector(getState, state => state.favourites);
export const checkFavourites = product => createSelector(getState, state => state.favourites.includes(product));
export const getCart = createSelector(getState, state => state.cart);
export const getCartCount = createSelector(getState, state => state.cart.length);


export const getAuthSignBy = createSelector(getState, state => state.signBy);
export const getPageData = createSelector(getState, state => state);

//  General getters
export const getQuotes = createSelector(getState, state => state.quotes);


export const getSavedQuotes = createSelector(getState, state => state.saved);

//	Specific getters
export const getQuote = id => createSelector(getState, state => state.quotes.filter(q => parseInt(q.id) === parseInt(id))[0]);
