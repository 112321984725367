import {IonAvatar, IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {fetch_pg_0_2, fetch_pg_0_3, Pg_0_1_Store, Pg_0_2_Store} from "../store/Pg_0_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
//import {fetch_pg_0_1} from "../store/Pg_1_Store";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import Ln_AvaterPictureSetCoverImg from "./Ln_AvaterPictureSetCoverImg";
import Ln_ContractPictureSetCoverImg from "./Ln_ContractPictureSetCoverImg";
import {fetch_pg_2_1, Pg_2_1_Store} from "../store/Pg_2_Store";
import {chevronDownCircleOutline} from "ionicons/icons";
import styles_2_1 from "./Pg_2_1.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import styles_TokenItem from "../components/TokenItem.module.scss";
import {TokenEnwItem} from "../components/TokenEnwItem";
import QRCode from "react-qr-code";
import styles_Pg_0_2 from "./Pg_0_2.module.scss";
import {handleFetchCommonErrors} from "./Utils";

const Pg_0_2 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_0_1";
    const pageId = "Pg_0_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    //#region Data Loading
    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_0_2({});
            handleFetchCommonErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_0_2({});
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_0_2_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const Item_會員 = hasLoaded ? pgData.Root.Item_會員 : undefined;
    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageName} sideMenu={true} sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <>
                        <div className={`${styles_Pg_0_2.avatarContainer}`}>
                            <div>
                                <IonAvatar className="avatar">
                                    {Ln_TokenPictureSetCoverImg({
                                        圖片組: Item_會員.個人照片,
                                        名稱: 'cover',
                                    })}
                                </IonAvatar>
                            </div>
                            <div>
                                <h6>{Item_會員.暱稱}</h6>
                            </div>
                        </div>

                        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            <div className={styles_Pg_0_2.memberQrBar}>
                                <div className="c" style={{height: "auto", margin: "0 auto", maxWidth: 125, width: "100%"}}>
                                    <QRCode
                                        size={256}
                                        style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                        value={Item_會員.登入Uid}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                                <div className="l">會員卡號: {Item_會員.登入Uid}</div>
                                <div className="f"></div>
                            </div>
                        </IonGrid>
                    </>
                }

            </CustomPage>
        </IonPage>
    );
};

export default Pg_0_2;
