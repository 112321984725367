import {IonAvatar, IonBadge, IonButton, IonChip, IonCol, IonContent, IonIcon, IonItem, IonLabel, IonList, IonNote, IonPopover, IonRouterLink, useIonRouter} from "@ionic/react";
import styles from "./PostItem.module.scss";
import styles_Common from "../pages/Common.module.scss";
import {bookmark, bookmarkOutline, chatboxEllipses, chatboxEllipsesOutline, heart, heartOutline, shareOutline, shieldCheckmarkSharp, star} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";
import Ln_ContractPictureSetCoverImg from "../pages/Ln_ContractPictureSetCoverImg";
import {capitalize} from "../utils";
import {push_pg_3_11__AddArticleBookmark, push_pg_3_11__AddArticleFavor, push_pg_3_11__AddCommentFavor, push_pg_3_11__RemoveArticleBookmark, push_pg_3_11__RemoveArticleFavor} from "../store/Pg_3_Store";
import {useStoreState} from "pullstate";
import {Pg_1_1_Store} from "../store/Pg_1_Store";
import {getPageData} from "../store/Selectors";
import {AuthStore, UserPostBookmarkStore, UserPostFavorStore} from "../store/Common_Store";
import Ln_PostTime from "../pages/Ln_PostTime";
import {default as lodash} from 'lodash';
import sharedConfig from "../_/config";
import {_push_pg_3_11__AddArticleBookmark, _push_pg_3_11__AddArticleFavor, _push_pg_3_1__Delete, _push_pg_3_11__RemoveArticleBookmark, _push_pg_3_11__RemoveArticleFavor, _push_pg_3_11__Share} from "./PostDetailItem";
import React from "react";

//#region +Event
// const navToPg_1_1 = () => {
//     router.push("/tabs/1_1", "forward", "push");
// }


//#endregion

export const PostItem = ({Item_文章, fromHome = false, noLink = false}) => {
    const router = useIonRouter();
    const postFavors = useStoreState(UserPostFavorStore, getPageData);
    const postBookmarks = useStoreState(UserPostBookmarkStore, getPageData);

    ///#region 會員
    const authStore = useStoreState(AuthStore, getPageData);
    const hasAuthed = authStore?.signBy ?? false;
    const Item_會員 = hasAuthed ? authStore?.AuthData : undefined;
    ///#endregion


    let Item_Token: any = Item_文章?._Token;
    if (!Item_Token) {
        return <>(ERROR:Item_Token)</>;
    }
    let Item_Token_合約: any = Item_Token?._合約;
    if (!Item_Token_合約) {
        return <>(ERROR:Item_Token_合約)</>;
    }
    let Item_Token_會員公開: any = Item_文章?._會員公開;
    if (!Item_Token_會員公開) {
        return <>(ERROR:Item_Token_會員公開)</>;
    }

    const navToPg_3_11_ByUser = (userId) => {
        router.push(`/tabs/3_1?userId=${userId}`, "forward", "push");

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    const navToPg_3_11_ByContract = (contractId) => {
        router.push(`/tabs/3_1?contractId=${contractId}`, "forward", "push");

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }


    return (
        <ActionLink link={`/tabs/3_11/${Item_文章.文章Id}`} className={styles.container} noLink={noLink}>
            <div className={styles.containerInner}>
                {Item_文章.Is刪除 ? <>
                    文章已刪除
                </> : <>
                    <div className={styles.nameBar}>
                        <div className="container">
                            <div className="l" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                navToPg_3_11_ByUser(Item_Token_會員公開.會員Id);

                                return false;
                            }}>
                                <IonAvatar className="avatar">
                                    {Ln_TokenPictureSetCoverImg({
                                        圖片組: Item_Token_會員公開.個人照片,
                                        名稱: 'cover',
                                    })}

                                </IonAvatar>
                            </div>
                            <div className="r" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                navToPg_3_11_ByUser(Item_Token_會員公開.會員Id);

                                return false;
                            }}>
                                {Item_Token_會員公開.暱稱}{Item_Token_會員公開.藍標 && <IonIcon icon={shieldCheckmarkSharp} className={styles_Common.userNameBlueIcon}></IonIcon>}
                            </div>


                            <div className="r2" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                //navToPg_3_11_ByContract(Item_Token_合約.合約Id);

                                return false;
                            }}>
                                <div className="roundbg">
                                    {Item_文章.__寵物名字}
                                </div>
                            </div>
                            <div className="r2" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                navToPg_3_11_ByContract(Item_Token_合約.合約Id);

                                return false;
                            }}>
                                <div className="roundbg">
                                    {Item_Token_合約.Name}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.postBar}>
                        {Ln_TokenPictureSetCoverImg({
                            圖片組: Item_文章.封面組,
                            名稱: 'cover',
                            type: 'multiple'
                        })}
                    </div>


                    <div className={styles.textBar}>
                        {Item_文章.YoutubeVideoId &&
                            <div>
                                <div className="plugin-video" dangerouslySetInnerHTML={{__html: `<iframe allow="autoplay" sandbox="allow-scripts allow-same-origin allow-presentation" width="100%" height="315" src="https://www.youtube.com/embed/${Item_文章.YoutubeVideoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}}/>
                            </div>
                        }
                        <div dangerouslySetInnerHTML={{__html: Item_文章.內容}} />
                        <div className="dt">
                            <Ln_PostTime
                                msTimestamp={Item_文章.CreatedAt}/>
                        </div>


                    </div>
                    <div className={styles.actionBar} onClick={e => {
                        e.preventDefault();
                        e.stopPropagation()
                    }}>
                        {/*///like*/}
                        {postFavors[Item_文章.文章Id] &&
                            <>
                                <IonButton fill="clear" className={`${styles_Common.actionButton} ${styles_Common.activeButton_InFavor}`} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    _push_pg_3_11__RemoveArticleFavor(router, Item_文章.文章Id);

                                    return false;
                                }}><IonIcon icon={heart} className={styles_Common.actionButtonIcon}></IonIcon>{Item_文章.讚數}
                                </IonButton>
                            </>
                        }
                        {!postFavors[Item_文章.文章Id] &&
                            <>
                                <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    _push_pg_3_11__AddArticleFavor(router, Item_文章.文章Id);

                                    return false;
                                }}><IonIcon icon={heartOutline} className={styles_Common.actionButtonIcon}></IonIcon>{Item_文章.讚數}
                                </IonButton>
                            </>
                        }


                        {/*///chatbox*/}
                        {Item_文章.回覆數 > 0 &&

                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                router.push(`/tabs/3_11/${Item_文章.文章Id}`, "forward", "push");
                            }}>
                                <IonIcon icon={chatboxEllipses} className={styles_Common.actionButtonIcon}></IonIcon>{Item_文章.回覆數}
                            </IonButton>
                        }
                        {Item_文章.回覆數 === 0 &&
                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                router.push(`/tabs/3_11/${Item_文章.文章Id}`, "forward", "push");
                            }}>
                                <IonIcon icon={chatboxEllipsesOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                            </IonButton>
                        }

                        {postBookmarks[Item_文章.文章Id] &&
                            <>
                                <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    _push_pg_3_11__RemoveArticleBookmark(router, Item_文章.文章Id);

                                    return false;
                                }}><IonIcon icon={bookmark} className={styles_Common.actionButtonIcon}></IonIcon>
                                </IonButton>
                            </>
                        }
                        {!postBookmarks[Item_文章.文章Id] &&
                            <>
                                <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    _push_pg_3_11__AddArticleBookmark(router, Item_文章.文章Id);

                                    return false;
                                }}><IonIcon icon={bookmarkOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                                </IonButton>
                            </>
                        }
                        {
                            <>
                                <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    _push_pg_3_11__Share(Item_文章);

                                    return false;
                                }}><IonIcon icon={shareOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                                </IonButton>
                            </>
                        }


                        {/*///bookmark*/}
                        {/*<IonButton fill="clear" className={styles_Common.actionButton}>*/}
                        {/*    <IonIcon icon={bookmarkOutline} className={styles_Common.actionButtonIcon}></IonIcon>*/}
                        {/*</IonButton>*/}
                        {/*<IonButton fill="clear" className={styles_Common.actionButton}>*/}
                        {/*    <IonIcon icon={bookmark} className={styles_Common.actionButtonIcon}></IonIcon>*/}
                        {/*</IonButton>*/}


                        {hasAuthed && (Item_文章.會員Id === Item_會員.會員Id) && <>
                            <div className={styles.actionBarSideRight}>
                                <IonButton id={`PostItem_${Item_文章.文章Id}_popover-button`} className={styles.actionManageButton}>管理</IonButton>
                                <IonPopover trigger={`PostItem_${Item_文章.文章Id}_popover-button`} dismissOnSelect={true} showBackdrop={false} backdropDismiss={true}>
                                    <IonContent>
                                        <IonList>
                                            <IonItem button={true} detail={false} onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                if (window.confirm('刪除指定圖片，將不可復原')) {
                                                    _push_pg_3_1__Delete(router, Item_文章.文章Id);
                                                }

                                                return false;
                                            }}>
                                                刪除
                                            </IonItem>
                                            {/*<IonItem button={true} detail={false}>*/}
                                            {/*    Option 2*/}
                                            {/*</IonItem>*/}
                                            {/*<IonItem button={true} id="nested-trigger">*/}
                                            {/*    More options...*/}
                                            {/*</IonItem>*/}

                                            {/*<IonPopover trigger="nested-trigger" dismissOnSelect={true} side="end">*/}
                                            {/*    <IonContent>*/}
                                            {/*        <IonList>*/}
                                            {/*            <IonItem button={true} detail={false}>*/}
                                            {/*                Nested option*/}
                                            {/*            </IonItem>*/}
                                            {/*        </IonList>*/}
                                            {/*    </IonContent>*/}
                                            {/*</IonPopover>*/}
                                        </IonList>
                                    </IonContent>
                                </IonPopover>
                            </div>
                        </>}

                    </div>
                    {/*<div><p>{capitalize(item.Name)}</p></div>*/}
                </>}
            </div>
        </ActionLink>
    );
}
