import {IonAvatar, IonBadge, IonButton, IonCol, IonIcon, IonNote, IonRouterLink, useIonRouter} from "@ionic/react";
import styles from "./ContractBonusItem.module.scss";
import {bookmark, bookmarkOutline, chatboxEllipses, chatboxEllipsesOutline, heart, heartOutline, star} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";
import styles_Common from "../pages/Common.module.scss";
import moment from "moment";
import {useStoreState} from "pullstate";
import {AuthStore, UserCouponBookmarkStore} from "../store/Common_Store";
import {getPageData} from "../store/Selectors";
import {push_pg_3_11__AddArticleFavor, push_pg_3_11__AddCommentFavor, push_pg_3_11__RemoveArticleFavor} from "../store/Pg_3_Store";
import {push_pg_4_2__AddCouponBookmark, push_pg_4_2__RemoveCouponBookmark} from "../store/Pg_4_Store";
import indexBy from "underscore/modules/indexBy";


//#region +Event
// const navToPg_1_1 = () => {
//     router.push("/tabs/1_1", "forward", "push");
// }

//#region push register
const _push_pg_3_11__AddCommentFavor = async (commentId) => {
    debugger;

    if (await push_pg_3_11__AddCommentFavor({
        p1: commentId,
    })) {
        //navToPg_1_1();
    }
}

const _push_pg_4_2__AddCouponBookmark = async (postId) => {
    if (await push_pg_4_2__AddCouponBookmark({
        p1: postId,
    })) {
        //navToPg_1_1();
    }
}

const _push_pg_4_2__RemoveCouponBookmark = async (postId) => {
    if (await push_pg_4_2__RemoveCouponBookmark({
        p1: postId,
    })) {
        //navToPg_1_1();
    }
}

//#endregion

//#endregion

export const ContractBonusItem = ({Item_合約獎勵, fromHome = false, noLink = false}) => {
    const router = useIonRouter();
    const couponBookmarks = useStoreState(UserCouponBookmarkStore, getPageData);

    let Item_合約: any = Item_合約獎勵?._合約;
    if (!Item_合約) {
        return <>(ERROR:Item_合約)</>;
    }

    let 剩下數量: number = parseInt(Item_合約獎勵.CanUseTimes) - parseInt(Item_合約獎勵.UseTimes);

    let endReason: any = null;
    if (moment(Item_合約獎勵.生效區間結束) <= moment()) {
        endReason = <span className="label-redeem ended">已過期</span>;
    }
    if (endReason === null && 剩下數量 <= 0) {
        endReason = <span className="label-redeem ended">兌換完畢</span>;
    }



    return (
        <ActionLink link={`/tabs/4_4/${Item_合約獎勵.合約獎勵Id}`} className={styles.container} noLink={noLink}>
            <div className={styles.containerInner}>


                <div className={styles.postBar}>
                    <div className="l">
                        {Ln_TokenPictureSetCoverImg({
                            圖片組: Item_合約獎勵.封面組,
                            名稱: 'cover',
                        })}
                    </div>
                    <div className="r">
                        <div className="rInner">
                            <div className={styles.nameBar}>
                                <div className="container">
                                    {/*<div className="l">*/}
                                    {/*    <IonAvatar className="avatar">*/}
                                    {/*        {Ln_TokenPictureSetCoverImg({*/}
                                    {/*            圖片組: Item_Token_會員公開.個人照片,*/}
                                    {/*            名稱: 'cover',*/}
                                    {/*        })}*/}
                                    {/*    </IonAvatar>*/}
                                    {/*</div>*/}
                                    {/*<div className="r">*/}
                                    {/*    {Item_Token_會員公開.暱稱}*/}
                                    {/*</div>*/}
                                    <div className="r2">
                                        <div className="roundbg">
                                            {Item_合約.Name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.textBar}>
                                {Item_合約獎勵.標題}
                            </div>

                            <div className={styles.timeRangeBar}>
                                使用期限: {moment(Item_合約獎勵.起始生效區間).format('YYYY.MM.DD')} - {moment(Item_合約獎勵.生效區間結束).format('YYYY.MM.DD')}

                            </div>
                            <div className={styles.actionBar}>
                                <div className="actions">
                                    {/*///bookmark*/}
                                    {couponBookmarks[Item_合約獎勵.合約獎勵Id] &&
                                        <>
                                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                _push_pg_4_2__RemoveCouponBookmark(Item_合約獎勵.合約獎勵Id);

                                                return false;
                                            }}><IonIcon icon={bookmark} className={styles_Common.actionButtonIcon}></IonIcon>
                                            </IonButton>
                                        </>
                                    }
                                    {!couponBookmarks[Item_合約獎勵.合約獎勵Id] &&
                                        <>
                                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                _push_pg_4_2__AddCouponBookmark(Item_合約獎勵.合約獎勵Id);

                                                return false;
                                            }}><IonIcon icon={bookmarkOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                                            </IonButton>
                                        </>
                                    }
                                </div>
                                <div className="labels">
                                    {endReason !== null ?
                                        <>
                                            {endReason}
                                        </> : <>
                                            <span className="label-redeem">剩餘{剩下數量}份</span>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*<div><p>{capitalize(item.Name)}</p></div>*/}
            </div>

        </ActionLink>
    );
}
