import {IonAvatar, IonBadge, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles from "./Categories.module.scss";
import {Pg_0_1_Store} from "../store/Pg_0_Store";
import {fetch_pg_0_1} from "../store/Pg_0_Store";

import {getPageData, getQuotes} from "../store/Selectors";
import {useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import CustomPage from "./CustomPage";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import Ln_AvaterPictureSetCoverImg from "./Ln_AvaterPictureSetCoverImg";
import Ln_ContractPictureSetCoverImg from "./Ln_ContractPictureSetCoverImg";
import {fetch_pg_2_1, Pg_2_1_Store} from "../store/Pg_2_Store";
import {chatboxEllipsesOutline, chevronDownCircleOutline, diamondOutline, exitOutline, qrCodeOutline, receiptOutline, settingsOutline} from "ionicons/icons";
import styles_5_2 from "./Pg_5_2.module.scss";
import {ActionLink} from "../components/ActionLink";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import styles_TokenEnwEntrustListItem from "../components/TokenEnwEntrustListItem.module.scss";
import styles_2_1 from "./Pg_2_1.module.scss";

const Pg_0_1 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_0_1";
    const pageId = "Pg_0_1";
    const { sideMenuOptions } = props;
    const setSideMenu:any = useSideMenuUpdate();
    const sideMenu:any = useSideMenuOptions();


    //#region Data Loading
    useEffect(() => {
        //fetch_pg_0_1();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        //await fetch_pg_0_1();
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_0_1_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const List_TokenEnw = hasLoaded ? pgData.Root.List_TokenEnw : undefined;
    const Item_會員 = hasLoaded ? pgData.Box_Header.Item_會員 : undefined;
    //#endregion

    return (
        <IonPage id={ pageId } >
            <CustomPage name={ pageName } sideMenu={ true } sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>


                {/*<IonItem lines="none">*/}
                {/*    <IonAvatar>*/}
                {/*        <IonImg src="/assets/alan.jpg" />*/}
                {/*    </IonAvatar>*/}
                {/*    <IonLabel className="ion-text-wrap ion-padding">*/}
                {/*        <h1>Author</h1>*/}
                {/*        <h2>*/}
                {/*            Alan Montgomery*/}
                {/*            <span className="role">*/}
				{/*				<IonBadge color="primary">Mobile Team Lead</IonBadge>*/}
				{/*			</span>*/}
                {/*        </h2>*/}
                {/*        <p>*/}
                {/*            Hey there, I'm Alan! Hopefully you can take something away from this little sample app. Or even if it's to have a poke around and see how I personally like to do things, that's OK too 👏🏻. Check out each page, side menu and have a look at how things work.*/}
                {/*        </p>*/}
                {/*    </IonLabel>*/}
                {/*</IonItem>*/}

                <IonGrid>


                    <IonList>
                        <IonItem lines="full">
                            <ActionLink link={`/tabs/0_2`} className={styles_TokenEnwEntrustListItem.actionlink}>
                                <div className={styles_TokenEnwEntrustListItem.container}>
                                    <div className="ll">
                                        {/*<IonAvatar className="avatar">*/}
                                        {/*    {Ln_TokenPictureSetCoverImg({*/}
                                        {/*        圖片組: Item_會員公開__委託者.個人照片,*/}
                                        {/*        名稱: 'cover',*/}
                                        {/*    })}*/}
                                        {/*</IonAvatar>*/}
                                        <IonIcon icon={ qrCodeOutline }></IonIcon>
                                    </div>
                                    <div className="l ll-1">
                                        我的會員卡
                                    </div>
                                    {/*<div className="r">*/}
                                    {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                    {/*</div>*/}
                                </div>
                            </ActionLink>
                        </IonItem>
                        <IonItem lines="full">
                            <ActionLink link={`/tabs/0_3`} className={styles_TokenEnwEntrustListItem.actionlink}>
                                <div className={styles_TokenEnwEntrustListItem.container}>
                                    <div className="ll">
                                        {/*<IonAvatar className="avatar">*/}
                                        {/*    {Ln_TokenPictureSetCoverImg({*/}
                                        {/*        圖片組: Item_會員公開__委託者.個人照片,*/}
                                        {/*        名稱: 'cover',*/}
                                        {/*    })}*/}
                                        {/*</IonAvatar>*/}
                                        <IonIcon icon={ settingsOutline }></IonIcon>
                                    </div>
                                    <div className="l ll-1">
                                        帳號設定
                                    </div>
                                    {/*<div className="r">*/}
                                    {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                    {/*</div>*/}
                                </div>
                            </ActionLink>
                        </IonItem>
                        <IonItem lines="full">
                            <ActionLink link={`/tabs/0_18`} className={styles_TokenEnwEntrustListItem.actionlink}>
                                <div className={styles_TokenEnwEntrustListItem.container}>
                                    <div className="ll">
                                        {/*<IonAvatar className="avatar">*/}
                                        {/*    {Ln_TokenPictureSetCoverImg({*/}
                                        {/*        圖片組: Item_會員公開__委託者.個人照片,*/}
                                        {/*        名稱: 'cover',*/}
                                        {/*    })}*/}
                                        {/*</IonAvatar>*/}
                                        <IonIcon icon={ receiptOutline }></IonIcon>
                                    </div>
                                    <div className="l ll-1">
                                        收藏品交易紀錄
                                    </div>
                                    {/*<div className="r">*/}
                                    {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                    {/*</div>*/}
                                </div>
                            </ActionLink>
                        </IonItem>
                        <IonItem lines="full">
                            <ActionLink link={`/tabs/0_20`} className={styles_TokenEnwEntrustListItem.actionlink}>
                                <div className={styles_TokenEnwEntrustListItem.container}>
                                    <div className="ll">
                                        {/*<IonAvatar className="avatar">*/}
                                        {/*    {Ln_TokenPictureSetCoverImg({*/}
                                        {/*        圖片組: Item_會員公開__委託者.個人照片,*/}
                                        {/*        名稱: 'cover',*/}
                                        {/*    })}*/}
                                        {/*</IonAvatar>*/}
                                        <IonIcon icon={ diamondOutline }></IonIcon>
                                    </div>
                                    <div className="l ll-1">
                                        我的寶石
                                    </div>
                                    {/*<div className="r">*/}
                                    {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                    {/*</div>*/}
                                </div>
                            </ActionLink>
                        </IonItem>

                        <IonItem lines="full">
                            <ActionLink link={`/`} className={styles_TokenEnwEntrustListItem.actionlink} noLink={true}>
                                <div className={styles_TokenEnwEntrustListItem.container}>
                                    <div className="ll">
                                        {/*<IonAvatar className="avatar">*/}
                                        {/*    {Ln_TokenPictureSetCoverImg({*/}
                                        {/*        圖片組: Item_會員公開__委託者.個人照片,*/}
                                        {/*        名稱: 'cover',*/}
                                        {/*    })}*/}
                                        {/*</IonAvatar>*/}
                                        <IonIcon icon={ exitOutline }></IonIcon>
                                    </div>
                                    <div className="l ll-1">
                                        登出
                                    </div>
                                    {/*<div className="r">*/}
                                    {/*    {Item_TokenEnwEntrust.價錢}寶石*/}
                                    {/*</div>*/}
                                </div>
                            </ActionLink>
                        </IonItem>
                    </IonList>
                </IonGrid>

            </CustomPage>
        </IonPage>
    );
};

export default Pg_0_1;
