// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pg_1_5_list__\\+1GrQ {\n  width: 100vw; }\n\n.Pg_1_5_timeBar__Y-eF0 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-flow: row wrap; }\n  .Pg_1_5_timeBar__Y-eF0 .clock {\n    margin-left: 16px;\n    margin-right: 4px; }\n\n.Pg_1_5_bar__YwbVv {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-flow: row nowrap; }\n", "",{"version":3,"sources":["webpack://./src/pages/Pg_1_5.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAY,EAAA;;AAMhB;EACI,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB,EAAA;EAJvB;IAOQ,iBAAiB;IACjB,iBAAiB,EAAA;;AAMzB;EACI,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB,EAAA","sourcesContent":[".list {\n    width: 100vw;\n\n}\n\n\n\n.timeBar {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-flow: row wrap;\n\n    :global(.clock) {\n        margin-left: 16px;\n        margin-right: 4px;\n    }\n}\n\n\n\n.bar {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-flow: row nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "Pg_1_5_list__+1GrQ",
	"timeBar": "Pg_1_5_timeBar__Y-eF0",
	"bar": "Pg_1_5_bar__YwbVv"
};
export default ___CSS_LOADER_EXPORT___;
