import _ from './index';

export class ApiService {

  constructor() {
  }

  static internal_urlMapping(url) {
    if (url == null)
      return null;
    if (url.indexOf("~/") == 0) {
      url = url.replace(/^~[/]+/, `${_._s.INTERNAL_API_ROOT_URL}/`);
    }
    return url;
  }

  static urlMapping(url) {
    if (url == null)
      return null;
    if (url.indexOf("~/") == 0) {
      url = url.replace(/^~[/]+/, `${_._s.API_ROOT_URL}/`);
    }
    return url;
  }

  static urlImageMapping(url) {
    if (url == null)
      return null;
    if (url.indexOf("~/") == 0) {
      url = url.replace(/^~[/]+/, `${_._s.API_ROOT_URL}/`);
    }
    return url;
  }

  static urlSmartImageMapping(url, mode: any = null, width: any = null, height: any = null) {
    if (url == null)
      return null;
    if (url.indexOf("~/") == 0) {
      if (mode == null) {
        url = `/download_image.ashx?file=${encodeURIComponent(url.replace(/^~[/]+/, '/'))}`;
      } else {
        url = `/download_image.ashx?width=${width}&height=${height}&mode=${mode}&file=${encodeURIComponent(url.replace(/^~[/]+/, '/'))}`;
        // if (_._s.APP_MODE === 'dev') {
        //   url = `/download_image.ashx?width=${width}&height=${height}&mode=${mode}&file=${encodeURIComponent(url.replace(/^~[/]+/, '/'))}`;
        // } else if (_._s.APP_MODE === 'prod') {
        //   url = `/download_image.ashx?width=${width}&height=${height}&mode=${mode}&file=${encodeURIComponent(url.replace(/^~[/]+/, '/'))}`;
        // } else {
        //   url = url.replace(/~[/]+/, "//error/");
        // }
      }
    }
    return url;
  }
}
