// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pg_5_2_listTitle__pUOIQ {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  flex-flow: row nowrap;\n  justify-content: space-between; }\n  .Pg_5_2_listTitle__pUOIQ .l {\n    display: inline; }\n  .Pg_5_2_listTitle__pUOIQ .r {\n    min-width: 110px;\n    padding-left: 8px;\n    box-sizing: border-box; }\n", "",{"version":3,"sources":["webpack://./src/pages/Pg_5_2.module.scss"],"names":[],"mappings":"AAKA;EACE,WAAW;EAEX,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,8BAA8B,EAAA;EANhC;IASI,eAAe,EAAA;EATnB;IAaI,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB,EAAA","sourcesContent":[".listItem {\r\n\r\n}\r\n\r\n\r\n.listTitle {\r\n  width: 100%;\r\n\r\n  display: flex;\r\n  align-items: center;\r\n  flex-flow: row nowrap;\r\n  justify-content: space-between;\r\n\r\n  :global(.l) {\r\n    display: inline;\r\n  }\r\n\r\n  :global(.r) {\r\n    min-width: 110px;\r\n    padding-left: 8px;\r\n    box-sizing: border-box;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listTitle": "Pg_5_2_listTitle__pUOIQ"
};
export default ___CSS_LOADER_EXPORT___;
