import {IonRouterOutlet, IonSplitPane} from "@ionic/react";
import {IonReactRouter} from "@ionic/react-router";
import {Redirect, Route} from "react-router-dom";
import SideMenu from "../sideMenu/SideMenu";
import {SubPages, Tabs, tabRoutes, subPageRoutes, tabsAndChildrenRoutes} from "./AllRoutes";
import {useStoreState} from "pullstate";
import {AuthStore, initAuth} from "../../store/Common_Store";
import {getPageData} from "../../store/Selectors";
import {Fragment, Suspense, useEffect, useState} from "react";
import {useSideMenuOptions} from "../sideMenu/SideMenuProvider";
import Loading from "../../pages/Loading";
import queryString from "query-string";

const NavRoutes = () => {
    const [, setForceRender] = useState<number>(0);
    const forceRender = (triggerLoad = false) => {
        setForceRender(s => s + 1);
    }

    (window as any).testme = forceRender;

    const authStore = useStoreState(AuthStore, getPageData);

    const hasAuthed = authStore?.signBy ?? false;
    const Item_會員 = hasAuthed ? authStore?.AuthData : undefined;

    const sideMenu: any = useSideMenuOptions();

    // useEffect(() => {
    //     forceRender();
    // }, [Item_會員]);
    // key={Item_會員?.會員Id}

    let {sm = null}: {
        sm: string | null,
    } = queryString.parse(window.location.search) as any;

    if (sm) {
        return <>
            <IonReactRouter>
                <Suspense fallback={null}>
                    <Fragment key={`slim_${(Item_會員?.會員Id) ?? "-"}`}>
                        <Route path="/tabs" render={() => <Tabs/>}/>
                        <SubPages/>
                    </Fragment>
                </Suspense>
            </IonReactRouter>
        </>
    }
    return <>
        <IonReactRouter>
            <IonSplitPane contentId="main" disabled={sideMenu.disableSideMenu} key={`normal_${(Item_會員?.會員Id) ?? "-"}`}>
                <SideMenu/>
                {/*{hasAuthed &&*/}
                {/*    */}
                {/*}*/}

                <IonRouterOutlet id="main">
                    <Suspense fallback={null}>
                        <Fragment>
                            <Route path="/tabs" render={() => <Tabs/>}/>
                            <SubPages/>
                        </Fragment>
                    </Suspense>
                    {/*<Route path="/" component={subPageRoutes.filter(t => t.default)[0].component} exact={true}/>*/}
                    <Redirect exact from="/" to={tabRoutes.filter(t => t.default)[0].path.toString()}/>
                </IonRouterOutlet>
            </IonSplitPane>
        </IonReactRouter>
    </>
}

export default NavRoutes;
