import React, { useState, useCallback, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import {
    UploadyContext,
    useItemErrorListener,
    useBatchCancelledListener
} from "@rpldy/chunked-uploady";

const MyUploadFail = () => {
    const uploady = useContext(UploadyContext);
    const [failedOpen, set_failedOpen] = useState(false);
    useItemErrorListener(() => {
        set_failedOpen(true);
    });
    useBatchCancelledListener(() => {
        set_failedOpen(true);
    });
    const handleRetry = (isConfirmed) => {
        if (isConfirmed) {
            handleUpload();
        }
        set_failedOpen(false);
    };
    const handleUpload = useCallback(() => {
        uploady.showFileUpload();
    }, [uploady]);
    return (
        <Dialog open={failedOpen} onClose={handleRetry}>
            <section>
                <p>Document size exceeds 100MB or format is not supported.</p>
                <p>Please try again.</p>
                <button onClick={() => handleRetry(false)}>Cancel</button>
                <button onClick={() => handleRetry(true)}>Retry</button>
            </section>
        </Dialog>
    );
};
export default MyUploadFail;
