import {IonButton, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonLabel, IonList, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonText, IonTitle, IonToolbar, RefresherEventDetail, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';

import styles from "./Categories.module.scss";
import {useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import {Fragment, useEffect, useRef, useState} from "react";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import {PageHeader} from "./PageHeader";
import {TokenItem} from "../components/TokenItem";
import {Modal} from "./Modal";
import CustomPage from "./CustomPage";
import {useStoreState} from "pullstate";
import {getPageData} from "../store/Selectors";
import {fetch_pg_4_2, Pg_4_2_Store} from "../store/Pg_4_Store";
import queryString from "query-string";
import {ContractBonusItem} from "../components/ContractBonusItem";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {chevronDownCircleOutline} from "ionicons/icons";
import {PostItem} from "../components/PostItem";
import {fetch_pg_0_3} from "../store/Pg_0_Store";
import {handleFetchCommonErrors} from "./Utils";
import {ActionLink} from "../components/ActionLink";
import styles_4_2 from "./Pg_4_2.module.scss";
import styles_TokenItem from "../components/TokenItem.module.scss";

const Pg_4_2 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_4_2";
    const pageId = "Pg_4_2";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    const pgData = useStoreState(Pg_4_2_Store, getPageData);
    const [amountLoaded, setAmountLoaded] = useState(20);
    const [selectedProduct, setSelectedProduct] = useState(false);

    const hasLoaded = pgData?.Root ?? false;
    const List_合約獎勵 = hasLoaded ? pgData.Root.List_合約獎勵 : undefined;
    const List_合約 = hasLoaded ? pgData.Root.List_合約 : undefined;
    const Item_合約 = hasLoaded ? pgData.Root.Item_合約 : undefined;

    const {contractId = null, isBookmark = null}: {
        contractId: string | null,
        isBookmark: string | null,
    } = queryString.parse(window.location.search) as any;

    useEffect(() => {
        (async () => {
            const {contractId = null, isBookmark = null}: {
                contractId: string | null,
                isBookmark: string | null,
            } = queryString.parse(window.location.search) as any;
            let errorObj = await fetch_pg_4_2({
                p1: contractId,
                p2: !!isBookmark,
            });
            handleFetchCommonErrors(router, errorObj);
        })();
    }, [window.location.toString()]);

    let reload = async (event: CustomEvent<RefresherEventDetail> | null) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        const {contractId = null, isBookmark = null}: {
            contractId: string | null,
            isBookmark: string | null,
        } = queryString.parse(window.location.search) as any;
        let errorObj = await fetch_pg_4_2({
            p1: contractId,
            p2: !!isBookmark,
        });
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    // //#region Hash on changes
    // const {contractId = null, userId = null}: {
    //     contractId: string | null,
    //     userId: string | null,
    // } = queryString.parse(window.location.search) as any;
    // const pageHash = useRef<string>(`${contractId ?? ""},${userId ?? ""}`);
    // if (pageHash.current !== `${contractId ?? ""},${userId ?? ""}`) {
    //     pageHash.current = `${contractId ?? ""},${userId ?? ""}`;
    //     reload(null);
    // }
    // //#endregion
    //

    //#endregion

    return (
        <IonPage id={pageId} key={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {hasLoaded &&
                    <Fragment>
                        {List_合約.length === 0 ?
                            <>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol className="ion-justify-content-center ion-text-center w100 ion-padding">
                                            領取獎勵暫無資料，請先前往新品商店購買，加入該社群後，才能看到該社群的獎勵！
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </> : <Fragment>

                                {List_合約獎勵.length === 0 ?
                                    <>
                                        <IonGrid>
                                            <IonRow>
                                                <IonCol className="ion-justify-content-center ion-text-center w100 ion-padding">
                                                    目前您所持有的個人收藏，並沒有任何獎勵提供，請過一段時間再回來看看！
                                                </IonCol>
                                            </IonRow>
                                        </IonGrid>
                                    </> : <>
                                        {contractId && Item_合約 &&
                                            <IonGrid className="padlr16px">
                                                <IonList>
                                                    <IonRow>
                                                        <ActionLink link={`/tabs/4_2`} noLink={true}>
                                                            現在位置: <IonChip>{Item_合約.Name}</IonChip>
                                                        </ActionLink>
                                                    </IonRow>
                                                </IonList>
                                            </IonGrid>
                                        }
                                        {isBookmark &&
                                            <IonGrid className="padlr16px">
                                                <IonList>
                                                    <IonRow>
                                                        <ActionLink link={`/tabs/4_2`} noLink={true}>
                                                            現在位置: <IonChip className={styles_4_2.bookmarkChip}>標註的</IonChip>
                                                        </ActionLink>
                                                    </IonRow>
                                                </IonList>
                                            </IonGrid>
                                        }
                                        <IonGrid className="padlr16px">
                                            <IonList>
                                                <IonRow>

                                                    <Fragment key={'ALL'}>
                                                        <ActionLink link={`/tabs/4_2`}>
                                                            <IonChip>ALL</IonChip>
                                                        </ActionLink>
                                                    </Fragment>

                                                    {List_合約.map(item => (
                                                        // <ActionLink link={`/tabs/1_2/${item.合約Id}`} className={styles.categoryContainer} key={item.合約Id}>
                                                        //     <div className={styles.categoryInner}>
                                                        //         {Ln_ContractPictureSetCoverImg({
                                                        //             圖片組: item.圖片組,
                                                        //             名稱: 'cover',
                                                        //         })}
                                                        //         <div><p>{capitalize(item.Name)}</p></div>
                                                        //     </div>
                                                        // </ActionLink>
                                                        <Fragment key={item.合約Id}>
                                                            <ActionLink link={`/tabs/4_2?contractId=${item.合約Id}`}>
                                                                <IonChip>{item.Name}</IonChip>
                                                            </ActionLink>
                                                        </Fragment>
                                                    ))}
                                                    <Fragment key={"isBookmark"}>
                                                        <ActionLink link={`/tabs/4_2?isBookmark=1`}>
                                                            <IonChip className={styles_4_2.bookmarkChip}>標註的</IonChip>
                                                        </ActionLink>
                                                    </Fragment>
                                                </IonRow>
                                            </IonList>
                                        </IonGrid>

                                        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                                            {/*<IonRow className={ styles.searchContainer }>*/}
                                            {/*    <IonCol size="12">*/}
                                            {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                                            {/*    </IonCol>*/}
                                            {/*</IonRow>*/}


                                            {/*click={() => handleShowModal(item)}*/}
                                            {List_合約獎勵.map((item, index) => {
                                                return <Fragment key={item.合約獎勵Id}>
                                                    <ContractBonusItem Item_合約獎勵={item}/>
                                                </Fragment>
                                            })}
                                        </IonGrid>
                                    </>}

                            </Fragment>}
                    </Fragment>}
            </CustomPage>
        </IonPage>
    );
};

export default Pg_4_2;
